import React from 'react'
import PropTypes from 'prop-types'
import { CartContext, CART } from '../contexts/Cart'
import CartIcon from '../ui/icons/Cart'

const Counter = ({handleClick}) => {
  const { cartState: {items, active}, cartUpdate } = React.useContext(CartContext)

  const _handleIconClick = () => {
    cartUpdate({ type: CART.ACTIVE, payload: !active })
    handleClick()
  }

  return (
    <i className="cart" onClick={() => _handleIconClick()}>
      { items.length > 0 && <span className="count">{items.length}</span>}
      <CartIcon />
    </i>
  )
}

Counter.propTypes = {
  handleClick: PropTypes.func
}

export default Counter
