import React from 'react'
import PropTypes from 'prop-types'

const InputText = ({ label, handler, normalize, validate, className, name, inputClass, errorText = null, ...props }) => {

  const onChangeHandler = (e) => {
    // let value = e.currentTarget.value
    if (normalize)
      e.currentTarget.value = normalize(e.currentTarget.value)

    if (validate)
      e.currentTarget.error = validate(e.currentTarget.value)

    handler(e)
  }

  return (
    <div className={`field-group ${className}`}>
      {label && <label className={className} htmlFor={name}>{label}</label> }
      { props.type === "textarea" && (
        <textarea
          name={name}
          {...props}
          onChange={onChangeHandler}
        />
      )}
      { props.type !== "textarea" && (
        <input
          name={name}
          className={inputClass}
          {...props}
          onChange={onChangeHandler}
        />
      )}
      { errorText && <span className="error-text">{errorText}</span>}
    </div>
  )
}

InputText.propTypes = {
  type: PropTypes.oneOf(['email', 'text', 'tel', 'textarea']).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  inputClass: PropTypes.string,
  errorText: PropTypes.string
}

export default InputText
