import React from 'react'
import PropTypes from 'prop-types'
import DateFieldControlled from "../common/forms/DateFieldControlled"
import momentPropTypes from 'react-moment-proptypes'

const DateField = ({
  date,
  onDateChange,
  focused,
  isDayBlocked,
  ...rest
  }) => {

  // Used to force the focus to stay truthy
  const _onFocusChange = () => {}

  return (
    <DateFieldControlled
      date={date}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={_onFocusChange}
      isDayBlocked={isDayBlocked}
      numberOfMonths={1}
      {...rest}
      />
  )
}

DateField.propTypes = {
  date: momentPropTypes.momentObj,
  onDateChange: PropTypes.func.isRequired,
  focused: PropTypes.bool.isRequired,
  isDayBlocked: PropTypes.func.isRequired
}

export default DateField
