import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { SEARCH, SearchContext } from '../contexts/SearchContext'
import {
  scrollToEcommerce
} from '../helpers/general'

import {
  setFullScreenElHeight,
  disableScroll,
  enableScroll
} from '../helpers/searchHelpers'

import { hotelRedirectUrl } from '../helpers/hotelHelper'

import SearchDateError from '../modals/SearchDateError'
import ButtonClose from '../common/buttons/ButtonClose'
import CalendarFullscreenArrival from './CalendarFullscreenArrival'
import CalendarFullscreenDeparture from './CalendarFullscreenDeparture'


const CtaBarMobile = ({setFullscreen, ctaText}) => {

  const { state, dispatch } = useContext(SearchContext)
  const _setArrival   = (payload) => dispatch({ type: SEARCH.ARRIVAL.SET, payload })
  const _setDeparture = (payload) => dispatch({ type: SEARCH.DEPARTURE.SET, payload })
  const _setNights    = (payload) => dispatch({ type: SEARCH.NIGHTS.SET, payload })
  const _setStep      = (payload) => dispatch({ type: SEARCH.CTA_BAR_STEP.SET, payload})
  const _setActive    = (payload) => dispatch({ type: SEARCH.CTA_BAR_ACTIVE.SET, payload: payload})
  const _setFullscreen = (payload) => dispatch({ type: SEARCH.CTA_BAR_FULLSCREEN.SET, payload: payload})

  const [datesSelected, setDatesSelected] = useState(true)

  const _onDismiss = () => setDatesSelected(true)

  const _fullscreenOpenActions = () => {
    setFullscreen(true)
    _setDynamicHeights()
  }

  const _fullscreenCloseActions = () => {
    enableScroll(".cta-bar")
    setFullscreen(false)
    _unsetDynamicHeights()
    _setFullscreen(false)
    _setStep(0)
  }

  const _setDynamicHeights = () => {
    setFullScreenElHeight(document.querySelector(".cta-bar"))
  }

  const _unsetDynamicHeights = () => {
    let dy = document.querySelector(".cta-bar")
    dy.style.height = null
  }

  const _renderStep = () => {
    if(state.ctaBarStep === 0) {
      return _renderStepOne()
    } else if(state.ctaBarStep === 1) {
      return _renderStepArrival()
    } else if(state.ctaBarStep === 2) {
      return _renderStepDeparture()
    }
  }

  const _renderCloseButton = () => <ButtonClose handleClick={_closeCtaBar} />
  const _closeCtaBar = () => _setActive(false)

  const _renderStepOne = () => (
    <React.Fragment>
      { _renderCloseButton() }
      <button
        className   = "btn cta inital"
        onClick     = {_handleButtonOneClick}
        aria-label  = {ctaText}
        >
        <span>{ctaText}</span>
      </button>
    </React.Fragment>
  )

  const _renderStepArrival = () => (
    <CalendarFullscreenArrival
      fullscreenOpen    = {state.ctaBarFullscreen}
      handleButtonClick = {_handleButtonArrivalClick}
      setArrival        = {_setArrival}
      setDeparture      = {_setDeparture}
      close             = {_handleCalendarCloseClick}
      />
  )

  const _renderStepDeparture = () => (
    <CalendarFullscreenDeparture
      fullscreenOpen    = {state.ctaBarFullscreen}
      handleButtonClick = {_handleButtonDepartureClick}
      setArrival        = {_setArrival}
      setDeparture      = {_setDeparture}
      setNights         = {_setNights}
      close             = {_handleCalendarCloseClick}
      back              = {_handleBackToArrival}
      />
  )

  const _handleCalendarCloseClick = () => {
    _setStep(0)
    enableScroll(".cta-bar")
    setFullscreen(false)
    _unsetDynamicHeights()
    _setFullscreen(false)
  }

  const _handleBackToArrival = () => {
    _setStep(1)
  }

  const _handleButtonOneClick = () => {
    _setStep(state.ctaBarStep + 1)
    _fullscreenOpenActions()
    disableScroll(".cta-bar")
  }

  const _handleButtonArrivalClick = () => {
    _fullscreenOpenActions()
    _setStep(state.ctaBarStep + 1)
  }

  const _handleButtonDepartureClick = (nights) => {
    if (!state.arrival || !state.departure) {
      setDatesSelected(false)
      return
    }

    _fullscreenCloseActions()

    window.location.href = hotelRedirectUrl(state.package.slug, nights, state.arrival)
  }

  return (
    <div className={`cta-bar-mobile${state.ctaBarStep === 0 ? ' initial':''}`}>
      { _renderStep() }
      {!datesSelected && <SearchDateError onDismiss={_onDismiss} />}
    </div>
  )
}

CtaBarMobile.propTypes = {
  setFullscreen: PropTypes.func.isRequired,
  hotelPayload: PropTypes.func.isRequired,
  ctaText: PropTypes.string.isRequired
}

export default CtaBarMobile
