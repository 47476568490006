import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { isMobileOnly } from 'react-device-detect'

import SeatListItem from './SeatListItem'
import SeatingCalendar from './Calendar'
import SeatingDetails from './SeatingDetails'
import SeatingFooter from './SeatingFooter'
import Loading from '../../vof/common/Loading'
import { scrollToEl } from '../ecommerce/helpers/general'
import { fetchTicketShowTimeAvailability } from '../api/ticketShowTimeAvailability'
import useDynamicFullHeight from '../hooks/useDynamicFullHeight'

import {
  ButtonXClose,
  ButtonChangeDate
} from '../ecommerce/products/Buttons'

import {
  CART,
  CartContext
} from '../ecommerce/contexts/Cart'

import {
  CheckoutContext,
  CHECKOUT
} from '../ecommerce/contexts/Checkout'

/*
  Seating Modal
  requires dom element with id "modal" to exist
*/

const renameKeys = (keysMap, arr) => (
  arr.map(obj => {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...{ [keysMap[key] || key]: obj[key] }
        }),
      {}
    )
  })
)

const Seating = (props) => {
  const {
    setSeatingActive,
    seatingProduct
  } = props

  const { cartState: { items, total }, cartUpdate } = useContext(CartContext)
  const _setCartActive = (active) => cartUpdate({type: CART.ACTIVE, payload: active})
  const _setCartItems  = (items)  => cartUpdate({type: CART.ITEMS, payload: items})
  const _setCartTotal  = (total)  => cartUpdate({type: CART.TOTAL, payload: total})

  const { checkoutState: { ticketAccessCode }, checkoutUpdate } = useContext(CheckoutContext)
  const _setCheckoutActive = (active) =>
    checkoutUpdate({type: CHECKOUT.ACTIVE, payload: active})

  const [selected, setSelected]            = useState()
  const [ticket, setTicket]                = useState(seatingProduct)
  const [calendarOpen, setCalendarOpen]    = useState(true)
  const [calendarDate, setCalendarDate]    = useState(null)
  const [loading, setLoading]              = useState(false)
  const [current, setCurrent]              = useState(null)
  const [viewDetails, setViewDetails]      = useState(false)
  const [seatCart, setSeatCart]            = useState(null)
  const [quantityTotal, setQuantityTotal]  = useState(0)
  const [priceTotal, setPriceTotal]        = useState(0)
  const [seatingData, setSeatingData]      = useState({})
  const [showTimesData, setShowTimesData]  = useState([])
  const [selectedShowTime, setSelectedShowTime] = useState(null)

  const [setResizerObserveEl] = useDynamicFullHeight(".seating-content")

  const _mapIncomingData = (data) => {
    let newData = renameKeys({
      category_id: "id",
      category_name: "name",
      category_description: "description",
      category_image: "image",
      category_quantity: "quantity",
      category_quantity_booked: "quantityBooked",
      category_quantity_remaining: "quantityRemaining",
      category_price: 'price',
      category_sorting_order: 'sortingOrder',
      category_retail_price: 'retailPrice',
      show_time_id: 'showTimeId',
      show_time_label: 'showTimeLabel'
    }, data)
    setSeatingData(newData)
    let mapped = uniqBy(newData, 'showTimeId').map(item => ({ [item.showTimeId]: item.showTimeLabel }))
    let newShowTimesData = Object.assign({}, ...mapped)
    setShowTimesData(newShowTimesData)
    setSelectedShowTime(Object.keys(newShowTimesData)[0])

    if(!isMobileOnly) {
      // set right column open, populate with first item
      setSelected(newData[0].id)
      setCurrent(newData[0])
      setViewDetails(true)
    }
  }

  const _handleCategoryClick = (category) => {
    if(!category) return

    setSelected(category.id)
    setCurrent(category)
    setViewDetails(true)
  }

  useEffect(
    () => {
      if(calendarDate) {
        _fetchSeatingShowtimes()
      }
    }, [calendarDate]
  )

  useEffect(() => {
    if(!calendarOpen) {
      // seating modal is open
      setResizerObserveEl(true)
    }
  }, [calendarOpen])

  const _fetchSeatingShowtimes = () => {
    setLoading(true)

    fetchTicketShowTimeAvailability(ticket.id, {
      id: ticket.id,
      date: calendarDate,
      ticket_access_code: ticketAccessCode,
    })
    .then(response => {
      if("data" in response) {
        _mapIncomingData(response.data)
      }
      setCalendarOpen(false)
      setLoading(false)
    }).catch((e) => {
      console.error(e) //eslint-disable-line
      setLoading(false)
    })
  }

  const closeModal = () => setSeatingActive(false)

  const _findExistingItemInCart = (categoryId, showTimeId) => {
    let tempSeatCart = seatCart ? {...seatCart} : {items: []}
    return tempSeatCart && tempSeatCart.items.find((x) => {
      return (x.category_id === categoryId) && (x.show_time_id === showTimeId)
    })
  }

  const _fetchExistingQuantity = (categoryId, showTimeId) => {
    let itemInCart = _findExistingItemInCart(categoryId, showTimeId)
    return itemInCart ? parseInt(itemInCart["quantity"]) : 0
  }

  // Expects category id:string, quantity:int, price:number
  const handleQuantitySelection = (categoryId, quantity, price) => {
    if(!categoryId) return

    let tempSeatCart = seatCart ? {...seatCart} : {items: []}

    if(quantity > 0) {
      // If exists, update quantity
      let existingItemInCart = _findExistingItemInCart(categoryId, selectedShowTime)
      if(existingItemInCart) {
        existingItemInCart["quantity"] = quantity
      } else {
        // Add new
        tempSeatCart.items.push({
          category_id: categoryId,
          show_time_id: selectedShowTime,
          show_time: showTimesData[selectedShowTime],
          name: seatingData.find(x => x.id === categoryId).name,
          quantity: quantity,
          price: price
        })
      }
    } else {
      // remove item from cart
      tempSeatCart.items = tempSeatCart.items.filter(x => x.category_id !== categoryId)
    }

    let totalPrice = 0, totalQuantity = 0
    tempSeatCart.items.forEach(item => {
      totalPrice += parseInt(item.quantity) * item.price
      totalQuantity += parseInt(item.quantity)
    })

    tempSeatCart.quantity = totalQuantity
    tempSeatCart.price = totalPrice
    tempSeatCart.date = moment(calendarDate).format("MM-DD-YYYY")

    setQuantityTotal( Math.max(0, totalQuantity) )
    setPriceTotal(totalPrice)
    setSeatCart(tempSeatCart)
  }

  const handleAddToCart = () => {
    _addItemsToCart()
    _setCartActive(true)
  }

  const _addItemsToCart = () => {
    let ticketTmp = {...ticket}
    ticketTmp.showtimes = {...seatCart}
    if(!("amount" in ticketTmp)) {
      ticketTmp.amount = 1
    }
    let cartItems = [...items]
    cartItems.push(ticketTmp)
    _setCartItems(cartItems)
    _resetState()
    closeModal()
  }

  const _resetState = () => {
    setSeatCart(null)
    setCurrent(null)
    setQuantityTotal(0)
    setPriceTotal(0)
    setShowTimesData([])
    setTicket(seatingProduct)
  }

  const handleBuyNow = () => {
    _addItemsToCart()
    _calcCartTotal()
    _setCartActive(false)
    // need timeout to allow time for adding items to cart
    setTimeout(() => {
      _setCheckoutActive(true)
      setTimeout(() => scrollToEl(document.querySelector(".checkout-page"), "start"), 200)
    }, 400)
  }

  const _calcCartTotal = () => {
    let totalAmount = total
    seatCart.items.forEach(item => {
      totalAmount += item.price
    })
    _setCartTotal(parseFloat(totalAmount).toFixed(2))
  }

  const handleChangeDate = () => {
    setCalendarOpen(true)
    _resetState()
  }

  const timeString12hr = (timeString) => {
    if(!timeString) return false

    return new Date('1970-01-01T' + timeString + 'Z')
    .toLocaleTimeString({},
      {timeZone:'UTC', hour12:true, hour:'numeric', minute:'numeric'}
    )
  }

  const _renderShowTimes = () => {
    let shows = Object.keys(showTimesData).
      sort((a, b) => showTimesData[a].localeCompare(showTimesData[b])).
      map((id) => {
      let classes = "btn btn-primary"
      if (id == selectedShowTime) {
        classes += " selected"
      }
      return (
        <button key={id} className={classes} onClick={() => setSelectedShowTime(id)}>
          {timeString12hr(showTimesData[id])}
        </button>
      )
    })
    return (
      <div className="show-times">
        {shows}
      </div>
    )
  }

  const modal = (
    <div className="modal fade show seating">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          { loading && <Loading /> }

          {(!isMobileOnly || !viewDetails) && (
            <ButtonXClose handler= { () => closeModal() } />
          )}

          {
            calendarOpen &&
            <SeatingCalendar
              setCalendarOpen={setCalendarOpen}
              blockOutDays={ticket.block_out_days}
              date={calendarDate}
              setDate={setCalendarDate}
              showDaysBreakdown={ticket.show_day_breakdown_with_categories }
            />
          }

          {
            !calendarOpen &&
            <section
              className={`seating-content ${seatCart && seatCart.items.length > 0 ? "footer-active" : ""}`}
            >
              <header className="main">
                <div className="change-date">
                  <ButtonChangeDate
                    handler={handleChangeDate}
                    />
                  <p className="current-date">
                    <small>Selected Date:</small>
                    <span>{ moment(calendarDate).format("dddd, MMMM Do YYYY") }</span>
                  </p>
                </div>
                <h3>Select Showtime and Seats</h3>
              </header>
              <div className="content">
                <div className="column list-view">
                  <div className="show-times-listing">
                    <h3>
                      <span>Select Showtime</span>
                    </h3>
                    {_renderShowTimes()}
                  </div>
                  <div className="seating-tickets">
                    <div className="scrollable">
                      {
                        sortBy(seatingData.filter((seat) => seat['showTimeId'] == selectedShowTime), ['sortingOrder']).map((category) => {
                          return <SeatListItem
                            key         = {category.id.toString() + '-' + category.showTimeId.toString()}
                            id          = {category.id}
                            name        = {category.name}
                            image       = {category.image}
                            description = {category.description}
                            retailPrice = {category.retailPrice}
                            price       = {category.price}
                            quantity    = {category.quantityRemaining}
                            quantitySelected = {_fetchExistingQuantity(category.id, selectedShowTime)}
                            selected    = {selected}
                            handler     = {() => _handleCategoryClick(category)}
                            setQuantitySelected = {handleQuantitySelection}
                            />
                        })
                      }
                    </div>
                  </div>
                </div>
                <div className={`column ${viewDetails ? 'open' : ''}`}>
                  {
                    current &&
                    <SeatingDetails
                      closeHandler={ () => setViewDetails(false) }
                      details={current}
                      />
                  }
                </div>
              </div>
              {
                seatCart && seatCart.items.length > 0 &&
                <SeatingFooter
                  quantityTotal={quantityTotal}
                  priceTotal={priceTotal}
                  handleAddToCart={ handleAddToCart }
                  handleBuyNow={ handleBuyNow }
                  />
              }
            </section>
          }
        </div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(
    modal,
    document.getElementById('modal')
  )
}

Seating.propTypes = {
  setCategory: PropTypes.func,
  seatingData: PropTypes.array,
  setSeatingActive: PropTypes.func,
  seatingProduct: PropTypes.object
}

export default Seating
