import PropTypes from "prop-types"

export const MProductItem = PropTypes.shape({
  id:           PropTypes.number.isRequired,
  title:        PropTypes.string.isRequired,
  description:  PropTypes.string.isRequired,
  image:        PropTypes.string,
  image_url:    PropTypes.string,
  price:        PropTypes.string.isRequired,
  unit:         PropTypes.string,
  retail_price: PropTypes.string,
  will_call_pickup: PropTypes.bool,
  will_call_box_office: PropTypes.string,
  will_call_box_office_address: PropTypes.string
})
