import React from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly } from 'react-device-detect'
import Button from '../ui/Button'
import { isInt } from '../helpers/seating'
import NumberFieldPlusMinus from '../ui/number-plus-minus/NumberFieldPlusMinus'

const SeatListItem = (props) => {
  const {
    id,
    image,
    name,
    description,
    retailPrice,
    price,
    quantity,
    quantitySelected,
    handler,
    selected,
    setQuantitySelected
  } = props

  const _handleOnChange = (value) => {
    setQuantitySelected(id, parseInt(value), price)
  }

  const _displayPrice = (price) => isInt(price) ? parseInt(price) : price

  const _handleViewDetails = (e) => {
    e.stopPropagation()
    handler(id)
  }

  return (
    <Button
      className={`seat-list-item button plain ${selected === id ? 'selected' : ''}`}
      clickHandler={isMobileOnly ? () => {} : () => handler(id)}
      >
      <header>
        {image && image.length && <figure><img src={image} alt={name} title={name} /></figure> }
        <div className="title-wrapper">
          <h4>{name}</h4>
          { description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} /> }
        </div>
      </header>
      <div className="meta">
        { isMobileOnly && (
          <div className="view-details-mobile">
            <span
              className="btn plain small"
              onClick={_handleViewDetails}
              >
                View Seat Map
                <i className="fas fa-chevron-right" />
            </span>
          </div>
        )}
        <div className="price">
          <span className="retail-price">${_displayPrice(retailPrice)}</span>
          <span>${_displayPrice(price)}</span>
          <NumberFieldPlusMinus
            minimum        = { 0 }
            maximum        = { quantity }
            setNumber      = { _handleOnChange }
            value          = { quantitySelected }
            fieldName      = "quantity"
            wrapperClasses = "seat-quantity"
            label          = { name + ' quantity' }
            />
        </div>
      </div>
    </Button>
  )
}

SeatListItem.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  retailPrice: PropTypes.string,
  quantity: PropTypes.number,
  quantitySelected: PropTypes.number,
  handler: PropTypes.func,
  selected: PropTypes.number,
  setQuantitySelected: PropTypes.func
}

export default SeatListItem
