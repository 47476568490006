import React, { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import lodashRemove from 'lodash/remove'
import PropTypes from 'prop-types'
import moment from 'moment'
import momentPropTypes from 'react-moment-proptypes'
import { isMobile } from 'react-device-detect'
import DateFieldControlled from "../../common/forms/DateFieldControlled"
import Loading from '../../vof/common/Loading'

const getNumberOfMonths = () => isMobile ? 1 : 2

const SeatingCalendar = (props) => {
  const { blockOutDays, date, setDate, showDaysBreakdown } = props
  const [isLoading, setIsLoading] = useState(true)

  useEffect(()=> setIsLoading(false), [])

  const isDayBlocked = day => {
    if (day.isBefore(moment().startOf('day')))
      return true

    if (("stop_sell_date" in blockOutDays) &&
      day.isAfter(moment(blockOutDays.stop_sell_date)))
      return true

    if (("days_of_week" in blockOutDays) &&
      (blockOutDays.days_of_week.includes(day.day())))
      return true

    if ("sold_out_periods" in blockOutDays) {
      let isSoldOut = blockOutDays['sold_out_periods'].find((dateRange) => {
        return day.isBetween(dateRange[0], dateRange[1], 'day', '[]')
      })
      if (isSoldOut)
        return true
    }

    let availableShowTimes = cloneDeep(showDaysBreakdown[day.day()])

    let hasPriorityShowTimes = Object.values(availableShowTimes).some(a => {
      return a.some(b => {
        if (b.active_from && b.active_to) {
          let startDate = new Date(`${b.active_from}T00:00:00`)
          let endDate = new Date(`${b.active_to}T23:59:59`)
          return day.isBetween(startDate, endDate)
        }
      })
    })

    Object.keys(availableShowTimes).forEach(time => {
      lodashRemove(availableShowTimes[time], (elem) => {
        if (hasPriorityShowTimes) {
          if (!elem.active_from || !elem.active_to) {
            return true
          }
          let startDate = new Date(`${elem.active_from}T00:00:00`)
          let endDate = new Date(`${elem.active_to}T23:59:59`)
          return !day.isBetween(startDate, endDate)
        } else {
          return elem.active_from && elem.active_to
        }
      })

      if (availableShowTimes[time].length == 0) {
        delete availableShowTimes[time]
      }
    })

    if ("specific_sold_out_periods" in blockOutDays) {
      blockOutDays['specific_sold_out_periods'].filter(a => !a[3]).find((dateRange) => {
        if (day.isBetween(dateRange[0], dateRange[1], 'day', '[]') &&
            availableShowTimes[dateRange[2]]) {

          availableShowTimes[dateRange[2]].forEach(elem => elem['blocked'] = true)
        }
      })

      blockOutDays['specific_sold_out_periods'].filter(a => a[3] > 0).find((dateRange) => {
        if (day.isBetween(dateRange[0], dateRange[1], 'day', '[]') &&
            availableShowTimes[dateRange[2]]) {

          availableShowTimes[dateRange[2]].forEach(elem => {
            if (elem['category_id'] == dateRange[3]) {
              elem['blocked']= true
            }
          })
        }
      })

      let allBlocked = false
      allBlocked = Object.values(availableShowTimes).every(ctgys => ctgys.every(ctg => ctg['blocked']))
      if (allBlocked) {
        return true
      }
    }

    return false
  }

  // Used to force the focus to stay truthy
  const _onFocusChange = () => {}

  const _onDateChange = ( date ) => {
    if (date < moment().hours(0)) return false
    if(date) {
      setDate(date)
    }
  }

  return (
    <aside className="seating-calendar">
      {isLoading && <Loading /> }

      <header>
        <h3>Select Date</h3>
        <p>Select a date to see available seats</p>
      </header>

      {
        !isLoading &&
        <DateFieldControlled
          date={date}
          onDateChange={_onDateChange}
          focused={true}
          onFocusChange={_onFocusChange}
          isDayBlocked={isDayBlocked}
          numberOfMonths={getNumberOfMonths()}
          firstDayOfWeek={0}
          initialVisibleMonth={() => moment()}
          hideKeyboardShortcutsPanel
          isOutsideRange={
            (day) => day.isBefore(
            moment()) && day.isAfter(blockOutDays.stopSellDate)
          }
          />
      }
    </aside>
  )

}

SeatingCalendar.propTypes = {
  blockOutDays: PropTypes.object,
  date: momentPropTypes.momentObj,
  setDate: PropTypes.func.isRequired
}

export default SeatingCalendar
