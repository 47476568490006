import React from 'react'
import { MTestimonialVideoType } from '../types'

const TestimonialsVideo = ({testimonial}) => {
  return (
    <div className="testimonial video">
      <div className="video-wrapper">
        <video
          preload="none"
          data-poster={testimonial.videoPosterUrl}
          controls
          className="lazy-load-resource"
          style={{width: '100%', height: '100%'}}
        >
          <source src={testimonial.videoUrl} type={testimonial.videoType} />
        </video>
      </div>
      <div className="text" dangerouslySetInnerHTML={{ __html: testimonial.text }}></div>
      <p className="person">{ testimonial.person }</p>
    </div>
  )
}

TestimonialsVideo.propTypes = {
  testimonial: MTestimonialVideoType
}

export default TestimonialsVideo
