import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../ui/Checkbox'


const WillCall = ({item, handler, selected}) => {
  const [checked, setChecked] = useState(selected)

  const _handler = (e) => {
    let checked = e.currentTarget.checked
    setChecked(checked)
    handler(checked, item)
  }

  const _outputBoxOfficeAddress = () => item.will_call_box_office &&
    (
      <address>
        <i className="fas fa-map-pin"></i>
          <a href={`http://maps.google.com/?q=${encodeURIComponent(item.will_call_box_office_address)}`}
            target="_black"
            rel="nofollow"
            dangerouslySetInnerHTML={{__html: item.will_call_box_office}}
          />
      </address>
    )

  return (
    <div className="will-call-item">
      <h4>{item.title}</h4>
      <Checkbox
        handler={_handler}
        label="I want to pick up my tickets at"
        name="will-call"
        checked={checked}
        />
      { _outputBoxOfficeAddress() }
    </div>
  )

}

WillCall.defaultProps = {
  selected: false
}

WillCall.propTypes = {
  item: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  selected: PropTypes.bool
}

export default WillCall
