var componentRequireContext = require.context("components/vof/pages/packages/show", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)


import { isMobileOnly, isMobile } from 'react-device-detect'
import forEach from 'lodash/forEach'
import { DragScroll } from '../../scripts/dragScroll.js'

document.addEventListener('DOMContentLoaded', () => {

  // Package View Page: Hero Image Switcher
  if(document.querySelector(".package-hero")) {
    let pImages = document.querySelectorAll(".package-hero-image-toggle")
    let pHero   = document.querySelector(".package-hero")
    forEach(pImages, image => {
      image.addEventListener("click", () => {
        let src = image.src // backup
        if (isMobile && !isMobileOnly) { //tablet
          if (window.innerWidth > 1024) {
            src = image.dataset.desktop
          } else {
            src = image.dataset.tablet
          }
        } else if (!isMobile) { //desktop
          src = image.dataset.desktop
        } else {
          if (window.devicePixelRatio >= 3) {
            src = image.dataset['mobile-3x']
          } else if (window.devicePixelRatio >= 2) {
            src = image.dataset['mobile-2x']
          } else {
            src = image.dataset.mobile
          }
        }
        pHero.style.backgroundImage = "url(" + src + ")"
      })
    })
    // set first background image
    let src
    if (isMobile && !isMobileOnly) { //tablet
      if (window.innerWidth > 1024) {
        src = pImages[0].dataset.desktop
      } else {
        src = pImages[0].dataset.tablet
      }
    } else if (!isMobile) { //desktop
      src = pImages[0].dataset.desktop
    } else {
      if (window.devicePixelRatio >= 3) {
        src = pImages[0].dataset['mobile-3x']
      } else if (window.devicePixelRatio >= 2) {
        src = pImages[0].dataset['mobile-2x']
      } else {
        src = pImages[0].dataset.mobile
      }
    }
    if(src) pHero.style.backgroundImage = "url(" + src + ")"
  }

  // Package View Page: Main Book Now Button Action
  if(document.querySelector(".main-book-now")) {
    let bookNowMain = document.querySelector(".main-book-now"),
        hotelEl = document.querySelector(".ecommerce-wrapper")

    bookNowMain.addEventListener("click", () => {
      hotelEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      })
    })
  }

  // Package page: scroll to Help/Booking Steps
  let scrollToPackagesHelp = document.querySelector("#scroll-to-help")
  if(scrollToPackagesHelp) {
    scrollToPackagesHelp.addEventListener("click", e => {
      e.preventDefault()
      let helpEl = document.querySelector(".booking-steps")
      helpEl.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      })
    })
  }

  // Packages View hero images vertical scroll
  let setDraggable = () => {
    let img_cont = document.querySelector(".package-images .image-container")
    if (img_cont && img_cont.clientWidth < 768) {
      DragScroll(img_cont)
    }
  }
  setDraggable()
  window.addEventListener("resize", setDraggable)

  // Image fallback
  let images = document.querySelectorAll("img.check")
  forEach(images, image => checkIfImageLoads(image))

  // Watch for class "scroll-to-hotel", scroll to hotels
  let scrollToHotel = document.querySelectorAll(".scroll-to-hotels")
  if(scrollToHotel) {
    let hotelEl = document.querySelector(".ecommerce-wrapper")
    if(hotelEl) {
      forEach(scrollToHotel, el => {
        el.addEventListener("click", () => {
          hotelEl.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          })
        })
      })
    }
  }
})

const checkIfImageLoads = image => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.addEventListener('load', resolve())
    img.addEventListener('error', () => {
      image.src = "/assets/vof/logos/vo-ocean.svg"
      image.classList.add("fallback")
      reject()
    })
    img.src = image.src
  })
}
