import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate()+2)

const initialState = {
  numRooms: 1,
  adults: 1,
  arrival: null,
  booking: null,
  bookingBarActive: false,
  bookingBarStep: "rooms",
  children: 0,
  childrenAges: '',
  ctaBarActive: true,
  ctaBarFullscreen: false,
  ctaBarStep: 0,
  currentHotels: [],
  currentPage: 0,
  departure: null,
  searchedNumRooms: null,
  searchedArrival: null,
  searchedDeparture: null,
  searchedNights: 1,
  destination: {},
  destinations: [],
  displayEmptyScreen: false,
  displaySearchMessage: true,
  fullscreen: false,
  fullscreenControlled: false,
  hotel: null,
  hotels: [],
  availabilityNotices: [],
  isLoading: false,
  isLoadmingMore: false,
  keywords: '',
  layout: isMobile ? 'list' : 'grid',
  meta: null,
  minNights: 1,
  nights: 1,
  numPerPage: 24,
  package: {},
  pages: 0,
  popUpOpen: false,
  rate: 0,
  room: null,
  searchBy: {
    type: null,
    id: null,
  },
  selectedHotel: null,
  serverErrors: null,
  searchBarActive: false,
  showExtraFields: true,
  step: 'dates',
  visibleHotels: [],
  user: null,
  lowestRate: null,
  searchResultNight: null,
  searchReloading: false,
  searchReloadingStartedAt: null,
  searchReloadingTimeout: null,
  searchReloadingEnqueued: false,
}

export const SEARCH = {
  ALL: 'SEARCH/ALL',
  STEP: {
    SET: 'SEARCH/STEP/SET'
  },
  ARRIVAL: {
    SET: 'SEARCH/ARRIVAL/SET',
  },
  DEPARTURE: {
    SET: 'SEARCH/DEPARTURE/SET',
  },
  SEARCHED_NUM_ROOMS: {
    SET: 'SEARCH/SEARCHED_NUM_ROOMS/SET',
  },
  SEARCHED_ARRIVAL: {
    SET: 'SEARCH/SEARCHED_ARRIVAL/SET',
  },
  SEARCHED_DEPARTURE: {
    SET: 'SEARCH/SEARCHED_DEPARTURE/SET',
  },
  SEARCHED_NIGHTS: {
    SET: 'SEARCH/SEARCHED_NIGHTS/SET',
  },
  NIGHTS: {
    SET: 'SEARCH/NIGHTS/SET',
  },
  MIN_NIGHTS: {
    SET: 'SEARCH/MIN_NIGHTS/SET',
  },
  DESTINATIONS: {
    SET: 'SEARCH/DESTINATIONS/SET'
  },
  KEYWORDS: {
    SET: 'SEARCH/KEYWORDS/SET'
  },
  DESTINATION: {
    SET: 'SEARCH/DESTINATION/SET'
  },
  SEARCH_BY: {
    SET: 'SEARCH/SEARCH_BY/SET',
  },
  ADULTS: {
    SET: 'SEARCH/ADULTS/SET',
  },
  NUM_ROOMS: {
    SET: 'SEARCH/NUM_ROOMS/SET',
  },
  CHILDREN: {
    SET: 'SEARCH/CHILDREN/SET',
  },
  CHILDREN_AGES: {
    SET: 'SEARCH/CHILDREN_AGES/SET',
  },
  HOTELS: {
    LOADING: 'SEARCH/HOTELS/LOADING',
    LOADING_MORE: 'SEARCH/HOTELS/LOADING_MORE',
    SET: 'SEARCH/HOTELS/SET',
  },
  AVAILABILITY_NOTICES: {
    LOADING: 'SEARCH/AVAILABILITY_NOTICES/LOADING',
    SET: 'SEARCH/AVAILABILITY_NOTICES/SET',
  },
  PAGES: 'SEARCH/PAGES',
  CURRENT_PAGE: 'SEARCH/CURRENT_PAGE',
  NUM_PER_PAGE: 'SEARCH/NUM_PER_PAGE',
  CURRENT_HOTELS: {
    SET: 'SEARCH/CURRENT_HOTELS/SET'
  },
  LOWEST_RATE: {
    SET: 'SEARCH/LOWEST_RATE/SET'
  },
  SEARCH_RESULT_NIGHT: {
    SET: 'SEARCH/SEARCH_RESULT_NIGHT/SET'
  },
  VISIBLE_HOTELS: {
    SET: 'SEARCH/VISIBLE_HOTELS/SET',
  },
  HOTEL: {
    SET: 'SEARCH/HOTEL/SET',
    UPDATE: 'SEARCH/HOTEL/UPDATE',
  },
  SELECTED_HOTEL: {
    SET: 'SEARCH/SELECTED_HOTEL/SET'
  },
  LAYOUT: {
    SET: 'SEARCH/LAYOUT/SET',
  },
  ROOM: {
    SET: 'SEARCH/ROOM/SET',
  },
  RATE: {
    SET: 'SEARCH/RATE/SET',
  },
  BOOKING: {
    SET: 'SEARCH/BOOKING/SET',
  },
  USER: {
    SET: 'SEARCH/USER/SET',
  },
  POP_UP_OPEN: {
    SET: 'SEARCH/POP_UP_OPEN/SET',
  },
  SERVER_ERRORS: {
    SET: 'SEARCH/SERVER_ERRORS/SET',
  },
  BOOKING_BAR_ACTIVE: {
    SET: 'SEARCH/BOOKING_BAR_ACTIVE/SET',
    STEP: 'SEARCH/BOOKING_BAR_ACTIVE/STEP'
  },
  PACKAGE: {
    SET: 'SEARCH/PACKAGE/SET'
  },
  DISPLAY_SEARCH_MESSAGE: {
    SET: 'SEARCH/DISPLAY_SEARCH_MESSAGE/SET'
  },
  DISPLAY_EMPTY_SCREEN: {
    SET: 'SEARCH/DISPLAY_EMPTY_SCREEN/SET'
  },
  FULLSCREEN: {
    SET: 'SEARCH/FULLSCREEN/SET',
    CONTROLLED: 'SEARCH/FULLSCREEN/CONTROLLED'
  },
  CTA_BAR_ACTIVE: {
    SET: 'SEARCH/CTA_BAR_ACTIVE/SET'
  },
  CTA_BAR_STEP: {
    SET: 'SEARCH/CTA_BAR_STEP/SET'
  },
  CTA_BAR_FULLSCREEN: {
    SET: 'SEARCH/CTA_BAR_FULLSCREEN/SET'
  },
  SEARCH_BAR_ACTIVE: {
    SET: 'SEARCH/SEARCH_BAR_ACTIVE/SET'
  },
  META: 'SEARCH/META',
  CACHE_SEARCH_TERMS: {
    SET: 'SEARCH/CACHE_SEARCH_TERMS/SET',
  },
  RATES_FOR_HOTEL: {
    SET: 'SEARCH/RATES_FOR_HOTEL/SET',
  },
  SEARCH_RELOADING: {
    SET: 'SEARCH/SEARCH_RELOADING/SET',
    CLEAR: 'SEARCH/SEARCH_RELOADING/CLEAR',
    ENQUEUE: 'SEARCH/SEARCH_RELOADING/ENQUEUE'
  },
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState
    case SEARCH.ALL:
      return { ...state, ...action.payload }
    case SEARCH.STEP.SET:
      return { ...state, step: action.payload }
    case SEARCH.ARRIVAL.SET:
      return { ...state, arrival: action.payload }
    case SEARCH.DEPARTURE.SET:
      return { ...state, departure: action.payload }
    case SEARCH.CACHE_SEARCH_TERMS.SET:
      return {
        ...state,
        searchedNumRooms: state.numRooms,
        searchedArrival: state.arrival.clone(),
        searchedNights: state.nights,
        searchedDeparture: state.departure.clone(),
      }
    case SEARCH.SEARCHED_NUM_ROOMS.SET:
      return { ...state, searchedNumRooms: action.payload }
    case SEARCH.SEARCHED_ARRIVAL.SET:
      return { ...state, searchedArrival: action.payload }
    case SEARCH.SEARCHED_DEPARTURE.SET:
      return { ...state, searchedDeparture: action.payload }
    case SEARCH.SEARCHED_NIGHTS.SET:
      return { ...state, searchedNights: action.payload }
    case SEARCH.NIGHTS.SET:
      return { ...state, nights: action.payload }
    case SEARCH.MIN_NIGHTS.SET:
      return { ...state, minNights: action.payload }
    case SEARCH.SEARCH_BY.SET:
      return { ...state, searchBy: action.payload }
    case SEARCH.CHILDREN.SET:
      return { ...state, children: action.payload }
    case SEARCH.CHILDREN_AGES.SET:
      return { ...state, childrenAges: action.payload }
    case SEARCH.NUM_ROOMS.SET:
      return { ...state, numRooms: action.payload }
    case SEARCH.ADULTS.SET:
      return { ...state, adults: action.payload }
    case SEARCH.HOTELS.LOADING:
      return { ...state, isLoading: action.payload }
    case SEARCH.HOTELS.LOADING_MORE:
      return { ...state, isLoadingMore: action.payload }
    case SEARCH.HOTELS.SET:
      return { ...state, hotels: action.payload }
    case SEARCH.AVAILABILITY_NOTICES.SET:
      return { ...state, availabilityNotices: action.payload }
    case SEARCH.PAGES:
      return { ...state, pages: action.payload }
    case SEARCH.CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SEARCH.NUM_PER_PAGE:
      return { ...state, numPerPage: action.payload }
    case SEARCH.CURRENT_HOTELS.SET:
      return { ...state, currentHotels: action.payload }
    case SEARCH.LOWEST_RATE.SET:
      return { ...state, lowestRate: action.payload }
    case SEARCH.SEARCH_RESULT_NIGHT.SET:
      return { ...state, searchResultNight: action.payload }
    case SEARCH.VISIBLE_HOTELS.SET:
      return { ...state, visibleHotels: action.payload }
    case SEARCH.HOTEL.LOADING:
      return { ...state, isLoading: action.payload }
    case SEARCH.HOTEL.SET:
      return { ...state, hotel: action.payload }
    case SEARCH.SELECTED_HOTEL.SET:
      return { ...state, selectedHotel: action.payload}
    case SEARCH.HOTEL.UPDATE:
      const newState = { ...state }
      const hotel = state.hotels.find(
        (hotel) => hotel.id == action.payload.hotel.id
      )
      if (hotel) {
        hotel[action.payload.updateField] =
          action.payload.hotel[action.payload.updateField]
      }
      return newState
    case SEARCH.LAYOUT.SET:
      return { ...state, layout: action.payload }
    case SEARCH.ROOM.SET:
      return { ...state, room: action.payload }
    case SEARCH.RATE.SET:
      return { ...state, rate: action.payload }
    case SEARCH.BOOKING.SET:
      return { ...state, booking: action.payload }
    case SEARCH.USER.SET:
      return { ...state, user: action.payload }
    case SEARCH.POP_UP_OPEN.SET:
      return { ...state, popUpOpen: action.payload }
    case SEARCH.SERVER_ERRORS.SET:
      return { ...state, serverErrors: action.payload }
    case SEARCH.DESTINATIONS.SET:
      return { ...state, destinations: action.payload }
    case SEARCH.KEYWORDS.SET:
      return { ...state, keywords: action.payload }
    case SEARCH.DESTINATION.SET:
      return { ...state, destination: action.payload }
    case SEARCH.BOOKING_BAR_ACTIVE.SET:
      return { ...state, bookingBarActive: action.payload }
    case SEARCH.BOOKING_BAR_ACTIVE.STEP:
      return { ...state, bookingBarStep: action.payload }
    case SEARCH.PACKAGE.SET:
      return { ...state, package: action.payload }
    case SEARCH.DISPLAY_SEARCH_MESSAGE.SET:
      return { ...state, displaySearchMessage: action.payload }
    case SEARCH.DISPLAY_EMPTY_SCREEN.SET:
      return { ...state, displayEmptyScreen: action.payload}
    case SEARCH.FULLSCREEN.SET:
      return { ...state, fullscreen: action.payload }
    case SEARCH.FULLSCREEN.CONTROLLED:
      return { ...state, fullscreenControlled: action.payload }
    case SEARCH.CTA_BAR_ACTIVE.SET:
      return { ...state, ctaBarActive: action.payload }
    case SEARCH.CTA_BAR_STEP.SET:
      return { ...state, ctaBarStep: action.payload}
    case SEARCH.CTA_BAR_FULLSCREEN.SET:
      return { ...state, ctaBarFullscreen: action.payload}
    case SEARCH.SEARCH_BAR_ACTIVE.SET:
      return { ...state, searchBarActive: action.payload }
    case SEARCH.META:
      return { ...state, meta: action.payload }
    case SEARCH.RATES_FOR_HOTEL.SET:
      return {
        ...state,
        hotel: {
          ...state.hotel,
          rates_fetched: true,
          room_rates: action.payload
        },
        hotels: state.hotels.map((hotel) => {
          if (hotel.id === state.hotel.id) {
            return { ...hotel, rates_fetched: true,  room_rates: action.payload }
          }
          else {
            return hotel
          }
        }),
        currentHotels: state.currentHotels.map((hotel) => {
          if (hotel.id === state.hotel.id) {
            return { ...hotel, rates_fetched: true,  room_rates: action.payload }
          }
          else {
            return hotel
          }
        })
      }
    case SEARCH.SEARCH_RELOADING.SET:
      return {
        ...state,
        searchReloading: true,
        searchReloadingTimeout: action.payload,
        searchReloadingStartedAt: new Date(),
        searchReloadingEnqueued: false,
      }
    case SEARCH.SEARCH_RELOADING.CLEAR:
      return {
        ...state,
        searchReloading: false,
        searchReloadingTimeout: null,
        searchReloadingStartedAt: null,
        searchReloadingEnqueued: false,
      }
    case SEARCH.SEARCH_RELOADING.ENQUEUE:
      return {
        ...state,
        searchReloading: false,
        searchReloadingEnqueued: true,
        searchReloadingTimeout: null,
      }
    case SEARCH.SEARCH_RELOADING.DEQUEUE:
      return {
        ...state,
        searchReloadingEnqueued: false,
      }
    default:
      return state
  }
}

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  let [state, dispatch] = useReducer(reducer, initialState)
  let value = { state, dispatch }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

SearchProvider.propTypes = {
  children: PropTypes.node,
}

export const SearchConsumer = SearchContext.Consumer
