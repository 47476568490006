import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../ui'
import ChevronRightAnimated from "../icons/ChevronRightAnimated"

const ContinueButton = ({
  clickHandler,
  ariaLabel,
  classes,
  iconClasses,
  disabled,
  label,
  nextStep = "Next Step",
  ...rest
}) => (
  <Button
    className={classes || "checkout-step btn btn-primary grad orange continue"}
    clickHandler={clickHandler}
    type="submit"
    disabled={disabled || false}
    aria-label={ariaLabel || label || "Continue"}
    {...rest}
    >
    <span className="content">
      {nextStep && <small className="next-step">{nextStep}</small>}
      <span className="button-text">
        {iconClasses && <i className={iconClasses}></i>}
        <span className="label">{label || "Continue"}</span>
      </span>
    </span>
    <span className="forward">
      <ChevronRightAnimated />
    </span>
  </Button>
)

ContinueButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  classes: PropTypes.string,
  iconClasses: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  nextStep: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}


export default ContinueButton
