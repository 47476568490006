import React from 'react'
import PropTypes from 'prop-types'
import ChevronRightAnimated from '../icons/ChevronRightAnimated'
import Button from '../../../common/ui/Button'

const ButtonContinue = ({
  className,
  clickHandler,
  ariaLabel,
  disabled,
  text,
  iconClasses,
  ...rest
}) => (
  <Button
    className={className || "btn btn-secondary orange"}
    clickHandler={clickHandler}
    aria-label={ariaLabel || 'Continue'}
    disabled={disabled}
    {...rest}
    >
    <span className="button-text">
      <i className={iconClasses || "far fa-calendar left"}></i>
      { text || "Continue" }
    </span>
    <span className="forward">
      <ChevronRightAnimated />
    </span>
  </Button>
)

ButtonContinue.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  iconClasses: PropTypes.string
}

export default ButtonContinue