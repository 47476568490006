import React from 'react'
import PropTypes from 'prop-types'
import { SearchProvider } from '../contexts/SearchContext'
import CtaBar from './CtaBar'

const CtaBarContainer = ({ packages, destination }) => {
  return (
    <SearchProvider>
      <CtaBar
        packages    = {packages}
        destination = {destination}
        />
    </SearchProvider>
  )
}

CtaBarContainer.propTypes = {
  packages: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired
}

export default CtaBarContainer
