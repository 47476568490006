import React, { useContext } from 'react'
import {
  Checkout,
  CheckoutContext
} from './'


const TempEcommercePage = ({ ...rest}) => {
  const { checkoutState } = useContext(CheckoutContext)

  return (
    <div className="ecommerce-wrapper">
      {!checkoutState.active && rest.children}
      {
        checkoutState.active && <div className="checkout-page">
          <Checkout useCart={false} forHotelBooking={true} mandatoryBillingAddress={true} {...rest} />
        </div>
      }
    </div>
  )
}

export default TempEcommercePage
