import dateFormat from 'dateformat'
import server from '../api/server'
import forOwn from 'lodash/forOwn'


const API_URL = `${window.location.protocol}//${window.location.host}`

// HotelBeds provides multiple image sizes
// small: small_size_image_url
// medium: medium_size_image_url
// large: hd_size_image_url
// original_size_image_url
// standard_size_image_url
export const getImageBySize = (image, size) => {
  if (!image) return false

  switch (size) {
    case 'small':
      if (image.hasOwnProperty('small_size_image_url')) {
        return image.small_size_image_url
      }
      break
    case 'medium':
      if (image.hasOwnProperty('medium_size_image_url')) {
        return image.medium_size_image_url
      }
      break
    case 'large':
      if (image.hasOwnProperty('hd_size_image_url')) {
        return image.hd_size_image_url
      }
      break
  }
}

export const getAddress = (address) => {
  let split = address.split(',')
  return split.length > 0 ? `${split[1]} ${split[0]}` : split
}

export const getMainImage = (images) => {
  let image = null
  if (images) {
    if (images.length == undefined) {
      // is an Object
      image = getImageBySize(images, 'large')
      if (!image) image = getImageBySize(images, 'medium')
    } else {
      if (images.length == 0) return 'https://placeimg.com/640/480/arch'
      image = getImageBySize(images[0], 'large')
      if (!image) image = getImageBySize(images[0], 'medium')
    }
  } else {
    return 'https://placeimg.com/640/480/arch'
  }

  return image
}

export const getRating = (code) => {
  if (['3EST', 'H3_5'].includes(code)) {
    return 3
  } else if (['H4_5', '4EST'].includes(code)) {
    return 4
  } else if (['HIST', '5EST'].includes(code)) {
    return 5
  }
  return 3
}

export const getRoomName = (name) => {
  let lowercase = name.toLowerCase()
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
}

export const getFeaturedAmenities = (amenities) => {
  const allowed = {
    'Late Check-out': 'fas fa-clock',
    'Airport Shuttle': 'fas fa-shuttle-van',
    'Valet parking': 'fas fa-parking',
    'Gym': 'fas fa-dumbbell',
    'Wi-fi': 'fas fa-wifi',
    'swimming pool': 'fas fa-swimmer',
    'cafe': 'fas fa-coffee',
    'escalator': '',
    'Jacuzzi': 'fas fa-hot-tub',
    'Fireplace': 'fas fa-fire',
    'Desk': 'fas fa-briefcase',
    'Large pets allowed (over 5 kg)': 'fas fa-paw',
    'Small pets allowed (under 5 kg)': 'fas fa-paw',
    'Microwave': 'fas fa-utensils',
    'Library': 'fas fa-book-open',
    'Balcony': 'fas fa-binoculars',
    'Safe': 'fas fa-lock',
    '24-hour security': 'fas fa-shield-alt',
    'Secure parking': 'fas fa-parking',
    'Connecting rooms': 'fas fa-link',
    'Disability-friendly rooms': 'fas fa-wheelchair',
  }

  try {
    let filtered = amenities.filter((f) => allowed.hasOwnProperty(f.description))
    if(filtered && filtered.length > 0) {
      let set = [...new Set(filtered.map(f => f.description))]
      return Object.assign(...Object.keys(allowed)
        .filter(key => set.includes(key)).map( key => ({[`${key}`]: allowed[key]})
      ))
    } else {
      // If no featured, return the first 8 items
      let slice = amenities.slice(0,8)
      if(slice.length > 0) {
        let set = [...new Set(slice.map(f => f.description).filter(f => f.length > 3) )]
        return set
      } else {
        return null
      }
    }
  } catch(e) {
    console.error(e) //eslint-disable-line
  }
}

export const scrubHotel = (hotel) => {
  try {
    let newState = { ...hotel }
    if (hotel.hotel_type !== "custom_hotel") {
      newState.address = getAddress(hotel.address)
    }
    newState.image = getMainImage(hotel.featured_images || hotel.images)
    newState.rating = getRating(hotel.category_code)
    return newState
  } catch(e) {
    console.error(e) //eslint-disable-line
    return hotel
  }
}

export const hotelRedirectUrl = (packageSlug, nights, arrival) => {
  let url = new URL(`${API_URL}/packages/view/${packageSlug}/hotels`)
  url.searchParams.append(`num_days`, nights)
  url.searchParams.append(`checkin_date`, arrival.format('YYYY-MM-DD'))
  return url
}

export const fetchAvailabilityNotices = (packageSlug, from, to) => {
  const url = new URL(`${API_URL}/availability_notices.json`)
  url.searchParams.append('package_slug', packageSlug)
  url.searchParams.append('from', from)
  url.searchParams.append('to', to)

  return fetch(url).then(async (response) => {
    let data = []
    if (response) {
      data = await response.json()
    }

    return {
      status: response.status,
      response: data
    }
  })
}

export const fetchHotels = (packageSlug, payload) => {
  const url = new URL(`${API_URL}/packages/view/${packageSlug}/hotels.json`)
  const { filters, sort, ...rest } = payload
  forOwn(rest, (value, key) => {
    url.searchParams.append(key, value)
  })
  if (filters) {
    forOwn(filters, (value, key) => {
      if (key == 'price') {
        url.searchParams.append(`filters[price][min]`, value['min'])
        url.searchParams.append(`filters[price][max]`, value['max'])
      } else {
        url.searchParams.append(`filters[${key}]`, value)
      }
    })
  }

  if (sort) {
    forOwn(sort, (value, key) => {
      url.searchParams.append(`sort[${key}]`, value)
    })
  }

  return fetch(url).then(async (response) => {
    let data = []
    if (response)
      data = await response.json()

    return {
      status: response.status,
      response: data
    }
  })
}

export const fetchRatesForHotel = (cacheId, hotel) => {
  let params = {
    cache_id: cacheId,
    hotel_id: hotel.id,
    search_id: hotel.room_rates.search_id,
  }
  return server.get(`/hotel_beds/hotels/availability.json`, { params: params })
}

export const fetchHotel = (hotel, payload) => {
  if (hotel.hotel_type == 'custom_hotel') {
    return server.get(`/custom_hotels/hotels/${hotel.id}`, { params: payload })
  } else {
    return server.get(`/hotel_beds/hotels/${hotel.id}`, { params: payload })
  }
}

export const scrubFeaturedHotel = (hotel) => {
  try {
    let newState    = { ...hotel }
    newState.image  = getMainImage(hotel.featured_images || hotel.images)
    newState.rating = getRating(hotel.category_code)
    return newState
  } catch(e) {
    return hotel
  }
}

export const fetchRateComments = (payload) => {
  let uri = `?checkin_date=${payload.checkin_date}&rate_comments_id=${
    payload.rate_comments_id
  }`
  const url = new URL(`${API_URL}/hotel_beds/rates_comments${uri}`)
  // Use below if we need to url encode
  // const url = new URL(`${API_URL}/hotel_beds/rates_comments?`)
  // Object.keys(payload).forEach(key => url.searchParams.append(key, payload[key]))

  return fetch(url).then((response) => (response.ok ? response.json() : {}))
}

export const serverErrorHelper = (error) => {
  if(error === "Error retrieving data from HotelBeds")
    return "Error retrieving hotel data"
  else
    return "Error retrieving data"
}

export const getDepartureDate = (arrivalDate, days, format = 'mm/dd/yyyy') => {
  let departureDate = new Date(arrivalDate.valueOf())
  departureDate.setDate(departureDate.getDate() + parseInt(days))
  return dateFormat(
    departureDate,
    format
  )
}
