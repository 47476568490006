import React from 'react'
import PropTypes from 'prop-types'

const ButtonClose = ({ handleClick, ...rest }) => (
  <button
    className="close"
    onClick={handleClick}
    aria-label='Close'
    type="button"
    role="button"
    {...rest}
    >
    X
  </button>
)

ButtonClose.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default ButtonClose