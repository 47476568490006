import React, { useState} from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../ui'

const PACKAGES_TEXT = `You may cancel this package up to 7 days prior to your arrival. 
If you have already received your perks, the package is no longer refundable.`

const TICKETS_TEXT = `All sales are final. No refunds offered.`

const Refund = ({forHotelBooking}) => {
  const [open, setOpen] = useState(false)
  const text = forHotelBooking ? PACKAGES_TEXT : TICKETS_TEXT
  const _handleClickAction = () => setOpen(!open)

  return (
    <div className="toggle-slide-wrapper">
      <Button
        className="text link refund"
        clickHandler={ _handleClickAction }
        >
        Refund Policy
      </Button>
      <aside className={`refund-details toggle-slide ${open ? "open" : ""}`}>
        {text}
      </aside>
    </div>
  )
}

Refund.propTypes = {
  forHotelBooking: PropTypes.bool.isRequired
}

export default Refund
