import React from 'react'
import PropTypes from 'prop-types'
import TestimonialsVideo from './TestimonialsVideo'
import TestimonialsText from './TestimonialsText'
import { MTestimonialVideoType, MTestimonialTextType } from '../types'

const Testimonials = ({testimonials, count = null, showOnlyText = false}) => {

  const _displayTestimonials = () => {
    if(count) {
      testimonials = testimonials.slice(0, count)
    }
    return testimonials.map((testimonial, i) => {
      if(testimonial["videoUrl"]) {
        return showOnlyText ? showOnlyText : <TestimonialsVideo testimonial={testimonial} key={`testimonial-video-${i}`} />
      } else {
        return <TestimonialsText testimonial={testimonial} key={`testimonial-text-${i}`} />
      }
    })
  }

  return (
    <div className="testimonials-container">
      { _displayTestimonials() }
    </div>
  )
}

Testimonials.propTypes = {
  testimonials: PropTypes.oneOfType([
    PropTypes.arrayOf(MTestimonialVideoType),
    PropTypes.arrayOf(MTestimonialTextType)
  ]),
  count: PropTypes.number,
  showOnlyText: PropTypes.bool
}

export default Testimonials
