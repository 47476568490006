import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SEARCH, SearchContext } from '../contexts/SearchContext'
import DateField from "../search/DateField"
import ButtonContinue from '../common/buttons/ButtonContinue'
import ButtonClose from '../common/buttons/ButtonClose'
import BackButton from '../../common/ecommerce/common/buttons/BackButton'

import {
  calcNightsMoment
} from '../helpers/searchHelpers'

const CalendarFullscreenDeparture = ({ fullscreenOpen, handleButtonClick, setArrival, setDeparture, setNights, close, back }) => {

  const { state, dispatch } = useContext(SearchContext)

  const _onChangeHandler = (date) => {
    if (date < moment().hours(0)) return
    setDeparture(date)
    let arrivalDate = state.arrival || _setArrivalDateFromDepartureDate(date)
    let nights = _actions(arrivalDate, date)

    handleButtonClick(nights)
  }

  const _actions = (arrival, departure) => {
    let nights = calcNightsMoment(arrival, departure)
    setNights(nights)
    dispatch({ type: SEARCH.SEARCH_BAR_ACTIVE.SET, payload: true})
    return nights
  }

  const _handleButtonClick = () => {
    let nights = _actions(state.arrival, state.departure)
    handleButtonClick(nights)
  }

  const _setArrivalDateFromDepartureDate = date => {
    let parsedArrivalDate = moment(date)
    parsedArrivalDate.subtract(parseInt(state.package.length_of_stay_in_nights), "days")
    setArrival(parsedArrivalDate)
    return parsedArrivalDate
  }

  const _renderButtons = () => (
    <div className="actions buttons">
      <ButtonContinue
        clickHandler={_handleButtonClick}
        ariaLabel="Continue to Hotels"
        disabled={false}
        iconClasses="fas fa-hotel left"
        text="Continue to Hotels"
        />
    </div>
  )

  // if day is < arrival + nights = blocked
  const _isDayBlocked     = day => day < moment(state.arrival).add(state.nights, "days")
  const _isDayHighlighted = day => day.isSame(state.arrival, "day")
  const title = state.arrival && state.departure &&
    <p className="total-nights">{ calcNightsMoment(state.arrival, state.departure) } nights total</p>

  const _renderView = () => (
    <React.Fragment>
      { title }
      <DateField
        date={state.departure}
        onDateChange={_onChangeHandler}
        focused={true}
        isDayBlocked={_isDayBlocked}
        isDayHighlighted={_isDayHighlighted}
      />
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <div className={`fullscreen departure ${fullscreenOpen ? 'open': ''}`}>
        <ButtonClose handleClick={close} />
        <BackButton
          aria-label  = "Back to Arrival Date"
          handler     = { back }
          />
        <h2>Select Your Departure Date</h2>
        { _renderView() }
      </div>
      { _renderButtons() }
    </React.Fragment>
  )
}

CalendarFullscreenDeparture.propTypes = {
  fullscreenOpen: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  setArrival: PropTypes.func.isRequired,
  setDeparture: PropTypes.func.isRequired,
  setNights: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired
}

export default CalendarFullscreenDeparture
