import React from "react"
import ReactDOM from "react-dom"
import Button from '../../common/ui/Button'

const SearchDateError = (props) => {
  let body = (
    <div className="modal fade show booking failure">
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content modal-error">
          <div className="modal-header">
            <i className="fas fa-exclamation-triangle" />
            <h4 className="modal-title">Enter in Dates</h4>
            <button
              className="close"
              onClick={() => props.onDismiss({})}
              data-dismiss="modal"
              aria-label="Close"
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Please select your arrival and departure dates</p>
          </div>
          <div className="modal-footer">
            <Button
              className="btn btn-secondary red closeSecondary"
              type="button"
              role="button"
              aria-label="OK"
              clickHandler={() => props.onDismiss({})}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(body, document.getElementById("modal"))
}

export default SearchDateError
