import React, { useState, useContext, useEffect} from 'react'
import {
  dynamicSort,
  numericSort
} from '../helpers/productHelper'
import SortIcon from '../ui/icons/Sort'
import { PRODUCTS, ProductContext } from '../contexts/Product'


const SortProducts = () => {

  const { state, dispatch } = useContext(ProductContext)
  const _setFiltered = (payload) => dispatch({ type: PRODUCTS.FILTERED, payload })

  const [alpha, setAlpha] = useState("")
  const [price, setPrice] = useState("")

  const _setAlphaDir = () => alpha == "" || alpha == "dsc" ? setAlpha("asc") : setAlpha("dsc")

  const _setPriceDir = () => price == "" || price == "dsc" ? setPrice("asc") : setPrice("dsc")

  const _sortAlpha = () => {
    if(alpha !== "") {
      let products = state.filtered
      let type = alpha == "dsc" ? "-title" : "title"
      products.sort(dynamicSort(type))
      _setFiltered(products)
    }
  }

  const _sortPrice = () => {
    if(price !== "") {
      let products = state.filtered
      let type = price == "dsc" ? "-price" : "price"
      products.sort(numericSort(type))
      _setFiltered(products)
    }
  }

  useEffect(_sortAlpha, [alpha])
  useEffect(_sortPrice, [price])

  return (
    <div className="products-sort-bar">
      <i
        className={`sort-icon sort-alpha ${alpha}`}
        onClick={ _setAlphaDir }
        >
        <span><span>A</span><span>Z</span></span>
        <SortIcon />
      </i>
      <i
        className={`sort-icon sort-price ${price}`}
        onClick={ _setPriceDir }
        >
        <span>$</span>
        <SortIcon />
      </i>
    </div>
  )
}

export default SortProducts
