import React from 'react'
import PropTypes from 'prop-types'
import { SearchProvider } from '../contexts/SearchContext'
import SearchContainer from './SearchContainer'
import {
  CheckoutProvider,
  CartProvider,
  TempEcommercePage
} from '../../common/ecommerce'


const SearchPage = ({ classes, packages, destination, featuredHotels }) => {
  return (
    <SearchProvider>
      <CartProvider>
        <CheckoutProvider>
          <TempEcommercePage>
            <SearchContainer
              classes         = {classes}
              packages        = {packages}
              destination     = {destination}
              featuredHotels  = {featuredHotels}
              />
          </TempEcommercePage>
        </CheckoutProvider>
      </CartProvider>
    </SearchProvider>
  )
}

SearchPage.propTypes = {
  packages: PropTypes.object.isRequired,
  classes: PropTypes.string.isRequired,
  destination: PropTypes.object.isRequired,
  featuredHotels: PropTypes.object.isRequired
}

export default SearchPage
