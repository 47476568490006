import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../ui'

const BackButton = ({ handler, ...props }) => (
  <Button
    className     = "checkout-step btn btn-secondary gray grow back"
    clickHandler  = { handler }
    aria-label    = { props["aria-label"] || "Back Button"} //eslint-disable-line
    { ...props }
    >
    <i className="left fas fa-chevron-left"></i>
    <span>Back</span>
  </Button>
)

BackButton.propTypes = {
  handler: PropTypes.func.isRequired,
}



export default BackButton
