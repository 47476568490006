export const scrollToEcommerce = (queryElem = '.ecommerce-wrapper') => {
  let scrollEl = document.querySelector(queryElem)
  if (scrollEl) scrollToEl(scrollEl, "start")
}

export const scrollToEl = (el, block = "end") => {
  if(el) {
    el.scrollIntoView({
      behavior: "smooth",
      block: block,
      inline: "nearest"
    })
  }
}

export const setFullScreenClasses = () => {
  let body = document.querySelector("body")
  let wrapperEl = document.querySelector(".ecommerce-wrapper")
  wrapperEl.classList.add("fullscreen")
  body.classList.add("fullscreen")
  setFullScreenHeights()
}

export const removeFullScreenClasses = () => {
  let body = document.querySelector("body")
  let wrapperEl = document.querySelector(".ecommerce-wrapper")
  wrapperEl.classList.remove("fullscreen")
  body.classList.remove("fullscreen")
  removeFullScreenHeights()
}

export const setFullScreenHeights = () => {
  let checkoutEl = document.querySelector(".checkout-page")
  checkoutEl.style.height = `${Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}px`
}

export const removeFullScreenHeights = () => {
  let checkoutEl = document.querySelector(".checkout-page")
  checkoutEl.style.height = null
}

export const dateNextWeek = () => {
  let currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 7)
  return currentDate
}

export const fieldInputHandler = (targetObj, dataObj, setFunc, errors, setErrors, nextValuesActions = null) => {
  const { value, name, error } = targetObj

  const nextValues = { ...dataObj }
  nextValues.fields[name] = value

  if (nextValuesActions) {
    nextValuesActions(nextValues, name, value)
  }

  const nextErrors = { ...errors }
  nextErrors[name] = error

  setFunc(nextValues)
  setErrors(nextErrors)
}

export const allValuesCheck = (keys, fields) =>
  keys.every((key) => fields[key].length > 0)

export const noErrorsCheck = (errors) =>
  Object.keys(errors).every((key) => errors[key] === undefined)

export const checkoutStepIcons = new Map()
checkoutStepIcons.set('cartSummary', 'fas fa-shopping-cart')
checkoutStepIcons.set('guestInformation', 'fas fa-user-alt')
checkoutStepIcons.set('shipping', 'fas fa-truck')
checkoutStepIcons.set('billing', 'far fa-credit-card')
checkoutStepIcons.set('confirm', 'fas fa-check-circle')
