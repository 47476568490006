import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ label, handler, checked, className, name, inputClass, ...props }) => {

  return (
    <label className={`field-group checkbox ${className}`} htmlFor={name}>
      <input
        type="checkbox"
        className={inputClass || 'checkbox'}
        onChange={handler}
        name={name}
        checked={checked}
        {...props} />
      <span>{label}</span>
    </label>
  )
}

Checkbox.defaultProps = {
  className: ''
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  normalize: PropTypes.func,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  inputClass: PropTypes.string
}

export default Checkbox
