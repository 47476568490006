import React from 'react'
import PropTypes from 'prop-types'

import { isMobileOnly } from 'react-device-detect'

import {
  ButtonXClose
} from '../ecommerce/products/Buttons'

const SeatingDetails = (props) => {
  const { closeHandler, details } = props

  return (
    <div className="seating-details scrollable">
      <header>
        { isMobileOnly && <ButtonXClose
          handler= { closeHandler }
          />
        }

        <h3>{details.name} Details</h3>
        {details.description &&
          <div className="description"
            dangerouslySetInnerHTML={{ __html: details.description }}
          />}
      </header>
      {details.image && <figure><img src={details.image} alt={details.name} /></figure> }
    </div>
  )
}

SeatingDetails.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired
}

export default SeatingDetails
