import React, { useContext, useState } from "react"
import PropTypes from 'prop-types'
import { MProductItem } from '../types/ProductItem'
import { scrollToEl } from '../helpers/general'
import { preventImageTheftProps } from '../../helpers/imageHelper'
import { CART, CartContext } from "../contexts/Cart"

import {
  ButtonAddToCart,
  ButtonInCart,
  ButtonSelectSeats,
  ButtonAddMoreSeats,
  ButtonSelectSeatsClose
} from './Buttons'

const ProductItem = ({
  children,
  product,
  order,
  seatingActive,
  setSeatingActive,
  setSeatingProduct,
  className
}) => {

  const { cartState: { items }, cartUpdate } = useContext(CartContext)
  const _setCartActive = (active) => cartUpdate({type: CART.ACTIVE, payload: active})
  const _setItems = (items) => cartUpdate({ type: CART.ITEMS, payload: items })
  const [amount, setAmount] = useState(1)
  const showsAvailable = product['seating_map_enabled']

  const _handleAddToCart = (product) => {
    if (!("amount" in product)) {
      let tmp = { ...product }
      tmp.amount = 1
      product = tmp
    }
    let cartItems = items
    product.amount = amount
    cartItems.push(product)
    _setItems(cartItems)
    _setCartActive(true)
  }

  const _handleOpenCart = () => {
    _setCartActive(true)
    _scrollToCart()
  }

  const _scrollToCart = () => {
    let el = document.querySelector(".product-cart")
    if(el) scrollToEl(el, "start")
  }

  const _handleSelectSeats = (product) => {
    setSeatingActive(!seatingActive)
    setSeatingProduct(product)
  }

  const _handleAmountChange = (e) => {
    let value = e.currentTarget.value
    setAmount(parseInt(value))
  }

  const _handleCloseSelectSeats = () => setSeatingActive(false)

  const _isItemInCart = () => items.length > 0 ?
    items.filter((item) => item.id == product.id).length > 0 :
    false

  const _displayActions = () => _isItemInCart() && showsAvailable ?
    <ButtonAddMoreSeats
      handler={() => _handleSelectSeats(product)}
      />
    : _isItemInCart() ?
    <ButtonInCart
      handler={_handleOpenCart}
      />
    : showsAvailable && seatingActive ?
    <ButtonSelectSeatsClose
      handler={_handleCloseSelectSeats}
      />
    : showsAvailable && !seatingActive && !_isItemInCart() ?
    <ButtonSelectSeats
      handler={() => _handleSelectSeats(product)}
      />
    :
    <ButtonAddToCart
      handler={() => _handleAddToCart(product)}
      />

  const _displayDescription = () => {
    return { __html: `${product.description}` }
  }

  const _renderUnit = () => product.unit && <span className="product-unit">{product.unit}</span>

  const _renderRetailPrice = (product) => {
    if (!product.retail_price)
      return

    return (
      <div className="product-retail-price">
        <h6>Public Price</h6>
        <span>${parseFloat(product.retail_price).toFixed(2)}</span>
      </div>
    )
  }

  const _renderPricing = (product) => {
    if (showsAvailable) {
      if (!product.lowest_price)
        return

      return (
        <div className="your-price">
          <h6>Starting From</h6>
          <span>${parseFloat(product.lowest_price).toFixed(2)} {_renderUnit()}</span>
        </div>
      )
    } else {
      return (
        <div className="your-price">
          <h6>Your Price</h6>
          <span>${parseFloat(product.price).toFixed(2)} {_renderUnit()}</span>
        </div>
      )
    }
  }

  const _renderProduct = () => {
    return (
      <article className={`product-item ${className}`} data-order={order}>
        <div className="content">
          <figure>
            <img src={`${product.image_url}`} alt={product.title} {...preventImageTheftProps} />
          </figure>
          <h4 className="product-title">{product.title}</h4>
          <div className="product-description" dangerouslySetInnerHTML={_displayDescription()}></div>
          { children }
        </div>
        <footer>
          {_renderRetailPrice(product)}

          <aside className="pricing">
            <div className="product-price">{_renderPricing(product)}</div>

            <div className="actions">
              {!_isItemInCart() && !showsAvailable && (
                <div className="item-quantity">
                  <input
                    className="item-amount"
                    type="number"
                    min="1"
                    step="1"
                    value={amount}
                    onChange={_handleAmountChange}
                  />
                </div>
              )}
              { _displayActions() }
            </div>
          </aside>
        </footer>
      </article>
    )
  }

  return product && _renderProduct()
}

ProductItem.defaultProps = {
  className: ''
}

ProductItem.propTypes = {
  children: PropTypes.node,
  product: MProductItem,
  seatingActive: PropTypes.bool,
  setSeatingActive: PropTypes.func,
  setSeatingProduct: PropTypes.func,
  className: PropTypes.string
}

export default ProductItem
