import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

export const checkoutInitialState = {
  active: false,
  items: [],
  step: "cartSummary", //"guestInformation",
  isLoading: false,
  steps: [
    "cartSummary",
    "guestInformation",
    "shipping",
    "billing",
    "confirm",
    "complete"
  ],
  isFullscreen: false,
  guestInformation: {
    valid: false,
    fields: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }
  },
  seminar: {
    valid: false,
    attending: false,
    fields: {
      age: false,
      income: false,
      terms: false
    }
  },
  shipping: {
    valid: false,
    fields: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    },
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    method: {
      service: '',
      delivery_date: '',
      price: 0
    }
  },
  willCall: {
    selected: false,
    items: []
  },
  billing: {
    valid: false,
    sameAsShipping: false,
    fields: {
      street: '',
      city: '',
      state: '',
      zip: '',
      cardName: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
      credit_card_last_four: ''
    }
  },
  payment: {
    valid: false,
    fields: {
      cardName: '',
      cardNumber: '',
      expiryDate: '',
      cvc: '',
    }
  },
  personal: {
    valid: false,
    fields: {
      firstName: '',
      lastName: '',
      phone: '',
      email: ''
    }
  },
  promoCodeInput: '',
  promoCode: undefined,
  ticketAccessCode: undefined,
  forHotelBooking: false,
  useCart: true,
  subTotal: 0,
  total: 0,
  renderMobileSteps: false,
  useFullscreen: true
}

export const CHECKOUT = {
  ALL: 'CHECKOUT/ALL',
  ACTIVE: 'CHECKOUT/ACTIVE',
  ITEMS: 'CHECKOUT/ITEMS',
  STEP: 'CHECKOUT/STEP',
  STEPS: 'CHECKOUT/STEPS',
  GUEST_INFORMATION: 'CHECKOUT/GUEST_INFORMATION',
  SEMINAR: 'CHECKOUT/SEMINAR',
  SHIPPING: 'CHECKOUT/SHIPPING',
  WILL_CALL: 'CHECKOUT/WILL_CALL',
  BILLING: 'CHECKOUT/BILLING',
  PAYMENT: 'CHECKOUT/PAYMENT',
  PERSONAL: 'CHECKOUT/PERSONAL',
  PROMO_CODE: 'CHECKOUT/PROMO_CODE',
  PROMO_CODE_INPUT: 'CHECKOUT/PROMO_CODE_INPUT',
  TICKET_ACCESS_CODE: 'CHECKOUT/TICKET_ACCESS_CODE',
  LOADING: 'CHECKOUT/LOADING',
  FULLSCREEN: 'CHECKOUT/FULLSCREEN',
  HOTEL_BOOKING: 'CHECKOUT/HOTEL_BOOKING',
  USE_CART: 'CHECKOUT/USE_CART',
  TOTAL: 'CHECKOUT/TOTAL',
  SUB_TOTAL: 'CHECKOUT/SUB_TOTAL',
  RENDER_MOBILE_STEPS: 'CHECKOUT/RENDER_MOBILE_STEPS',
  USE_FULLSCREEN: 'CHECKOUT/USE_FULLSCREEN'
}

let reducer = ( state, action ) => {
  switch(action.type) {
    case "reset":
      return {...checkoutInitialState}
    case CHECKOUT.ALL:
      return { ...state, ...action.payload }
    case CHECKOUT.ACTIVE:
      return { ...state, active: action.payload }
    case CHECKOUT.ITEMS:
      return { ...state, items: action.payload }
    case CHECKOUT.STEP:
      return { ...state, step: action.payload }
    case CHECKOUT.STEPS:
      return { ...state, steps: action.payload }
    case CHECKOUT.GUEST_INFORMATION:
      return { ...state, guestInformation: action.payload }
    case CHECKOUT.SEMINAR:
      return { ...state, seminar: action.payload }
    case CHECKOUT.SHIPPING:
      return { ...state, shipping: action.payload }
    case CHECKOUT.WILL_CALL:
      return { ...state, willCall: action.payload }
    case CHECKOUT.BILLING:
      return { ...state, billing: action.payload }
    case CHECKOUT.PAYMENT:
      return { ...state, payment: action.payload }
    case CHECKOUT.PERSONAL:
      return { ...state, personal: action.payload }
    case CHECKOUT.PROMO_CODE:
      return { ...state, promoCode: action.payload }
    case CHECKOUT.PROMO_CODE_INPUT:
      return { ...state, promoCodeInput: action.payload }
    case CHECKOUT.TICKET_ACCESS_CODE:
      return { ...state, ticketAccessCode: action.payload }
    case CHECKOUT.LOADING:
      return { ...state, isLoading: action.payload }
    case CHECKOUT.FULLSCREEN:
      return { ...state, isFullscreen: action.payload }
    case CHECKOUT.HOTEL_BOOKING:
      return { ...state, forHotelBooking: action.payload }
    case CHECKOUT.USE_CART:
      return { ...state, useCart: action.payload }
    case CHECKOUT.TOTAL:
      return { ...state, total: action.payload }
    case CHECKOUT.SUB_TOTAL:
      return { ...state, subTotal: action.payload }
    case CHECKOUT.RENDER_MOBILE_STEPS:
      return { ...state, renderMobileSteps: action.payload }
    case CHECKOUT.USE_FULLSCREEN:
      return { ...state, useFullscreen: action.payload }
    default:
      return state
  }
}

export const CheckoutContext = createContext()

export const CheckoutProvider = ({ children }) => {
  let [checkoutState, checkoutUpdate] = useReducer(reducer, checkoutInitialState)
  let value = { checkoutState, checkoutUpdate }

  return (
    <CheckoutContext.Provider value={ value }>
      { children }
    </CheckoutContext.Provider>
  )
}

CheckoutProvider.propTypes = {
  children: PropTypes.node
}

export const CheckoutConsumer = CheckoutContext.Consumer
