import React, { useState, useContext, useEffect } from 'react'
import { isMobileOnly } from "react-device-detect"
import ContinueButton from '../../common/buttons/ContinueButton'
import {
  CHECKOUT,
  CheckoutContext
} from '../../contexts/Checkout'

import ShippingAddressFields from './ShippingAddressFields'
import ShippingMethodItem from './ShippingMethodItem'
import WillCall from './WillCall'
import server from '../../../../vof/api/server'
import Loading from '../../../../vof/common/Loading'
import { dateNextWeek, scrollToEcommerce } from '../../helpers/general'
import BackButtonTickets from '../../common/buttons/BackButtonTickets'
import { fieldInputHandler } from '../../helpers/general'
import { validatePostCode } from '../../helpers/formHelper'

const DEFAULT_SHIPPING_METHOD = [{
  service: 'FedEx Express Saver',
  delivery_date: dateNextWeek(),
  price: 'Free'
}]

const defaultShippingText = <p>Fill in your shipping address above to see shipping methods available</p>

const Shipping = () => {
  const { checkoutState: {shipping, willCall}, checkoutUpdate } = useContext(CheckoutContext)
  const _setShipping = (values) => checkoutUpdate({ type: CHECKOUT.SHIPPING, payload: values})
  const _setStep = (step) => checkoutUpdate({ type: CHECKOUT.STEP, payload: step})
  const [valid, setValid] = useState(false)
  const [errors, setErrors] = useState({})
  const [shippingMethods, setShippingMethods] = useState([])
  const [shippingMethodsLoading, setShippingMethodsLoading] = useState(false)
  const [tempSetValidFalse, setTempSetValidFalse] = useState(true)
  const [willCallAvailable, setWillCallAvailable] = useState(false)
  const [canFetchShipping, setCanFetchShipping] = useState(false)

  let shippingRatesTimer = null

  useEffect(() => {
    const validPostcode = validatePostCode(shipping.address.zip)
    if (shipping.address.street && shipping.address.city && shipping.address.state && validPostcode) {
      setCanFetchShipping(true)
    } else {
      setCanFetchShipping(false)
    }
  }, [shipping])

  const _inputHandler = (e) => {
    let targetObj = (({ value, name, error }) => ({ value, name, error }))(e.currentTarget)
    fieldInputHandler(targetObj, shipping, _setShipping, errors, setErrors)
  }

  const _phoneHandler = (targetObj) => {
    fieldInputHandler(targetObj, shipping, _setShipping, errors, setErrors)
  }

  const _inputHandlerAddress = (e) => {
    const { value, name, error } = e.currentTarget

    const nextValues = { ...shipping }
    nextValues.address[name] = value

    const nextErrors = { ...errors }
    nextErrors[name] = error

    _setShipping(nextValues)
    setErrors(nextErrors)
  }

  const _validate = () => {
    let noErrors = Object.keys(errors).every((key) => errors[key] === undefined)
    let validationFields
    validationFields = {...shipping.fields}
    let hasValues = Object.keys(validationFields).every(
      (key) => shipping.fields[key].length > 0
    )

    setValid(noErrors && hasValues)
  }

  useEffect(_validate, [shipping, errors])

  const _handleContinueButton = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let tmp = { ...shipping }
    tmp.valid = true
    _setShipping(tmp)
    _setStep("cartSummary")
    scrollToEcommerce('.checkout-process.tickets')
  }

  return (
    <section className="shipping left-section">
      <div className="content">
        <h2>Who&apos;s picking up the tickets?</h2>
        <p className="small-note">This information will be sent directly to the venue and should match your photo ID.</p>

        <form
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            if(valid) _handleContinueButton(e)
          }}
          >
          <ShippingAddressFields
            handler={_inputHandler}
            phoneHandler={_phoneHandler}
            values={Object.assign({}, shipping.fields, shipping.address)}
            errors={errors}
            />
        </form>
      </div>

      <aside className="actions">
        <BackButtonTickets />
        <ContinueButton
          disabled={!valid || !tempSetValidFalse}
          clickHandler={ _handleContinueButton }
          iconClasses="left fas fa-check-circle"
          label="Order Review"
          type="submit"
          />
      </aside>
    </section>
  )
}

export default Shipping
