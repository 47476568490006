import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SearchContext } from '../contexts/SearchContext'
import DateField from "../search/DateField"
import ButtonContinue from '../common/buttons/ButtonContinue'
import ButtonClose from '../common/buttons/ButtonClose'

import {
  setMinDepartureDateMoment
} from '../helpers/searchHelpers'

import { isDateBlockedForSelection } from '../helpers/general'

const CalendarFullscreenArrival = ({ fullscreenOpen, handleButtonClick, setArrival, setDeparture, close }) => {

  const { state } = useContext(SearchContext)

  const _onChangeHandler = (date) => {
    if (date < moment().hours(0)) return
    setArrival(date)
    let departure = setMinDepartureDateMoment(date, parseInt(state.package.length_of_stay_in_nights))
    setDeparture(setMinDepartureDateMoment(date, parseInt(state.package.length_of_stay_in_nights)))
    handleButtonClick(date, departure)
  }

  const _renderButton = () => (
    <ButtonContinue
      clickHandler={handleButtonClick}
      ariaLabel="Select Departure Date"
      disabled={state.arrival == null || state.arrival == ''}
      text="Continue to Departure Date"
      />
  )

  const _renderView = <DateField
    date={state.arrival}
    onDateChange={_onChangeHandler}
    focused={true}
    isDayBlocked={isDateBlockedForSelection}
  />

  return (
    <div className={`fullscreen arrival ${fullscreenOpen ? 'open': ''}`}>
      <ButtonClose handleClick={close} />
      <h2>Select Your Arrival Date</h2>
      { _renderView }
      { _renderButton() }
    </div>
  )
}

CalendarFullscreenArrival.propTypes = {
  fullscreenOpen: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  setArrival: PropTypes.func.isRequired,
  setDeparture: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

export default CalendarFullscreenArrival
