import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'

const SelectField = ({
  className,
  isClearable,
  isSearchable,
  name,
  options,
  value,
  placeholder,
  filterOptions,
  handleChange,
  ...props
}) => {

  return (
    <Select
      className={className}
      classNamePrefix="select"
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      options={options}
      value={value}
      placeholder={placeholder}
      filterOption={filterOptions}
      onChange={handleChange}
      {...props}
    />
  )
}

SelectField.defaultProps = {
  isClearable: true,
  isSearchable: true,
}

SelectField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  filterOptions: PropTypes.func
}

export default SelectField
