import { useEffect, useState } from "react"

export default ({ root = null, rootMargin, threshold = 0, callback, controlled = true }) => {
  const [node, setNode] = useState(null)
  const [thresh, setThresh] = useState(threshold)

  let observer = null

  const updateThreshold = (value) => setThresh(value)

  useEffect(
    () => {
      observer =
        new IntersectionObserver(
          callback, {
          root,
          rootMargin,
          thresh
        })

      if (node) observer.observe(node)

      return () => {
        if(!controlled) {
          observer.disconnect()
        }
      }
    },
    [node]
  )

  return [node, setNode, updateThreshold]
}
