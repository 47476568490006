import history from 'history/browser'

export const pushBrowserState = (name, state, loc) => {
  // console.log("pushBrowserState ", name, state)
  history.push(loc || history.location, {
    data: {...state},
    stateTitle: name
  })
}

export const replaceBrowserState = (name, state) => {
  // console.log("replaceBrowserState ", name, state)
  history.push(history.location, {
    data: {...state},
    stateTitle: name
  })
}
// Mozilla firefox issue - pushState can only be 640Kb.
// Use sessionStorage to persist the hotels and add a reference
// to the sessionStorage in the pushState
// Furthermore: sessionStorage has finite quantity as well. Keep history to
// at max 3 different destination changes
export const pushHotelsToSessionStorage = (state, name) => {

  try {
    sessionStorage.setItem(
      `hotelsHistory${name}`,
      JSON.stringify({
        currentHotels: state.currentHotels,
        visibleHotels: state.visibleHotels,
        hotels: state.hotels
      })
    )
  } catch (domException) {
    if (
      ['QuotaExceededError', 'NS_ERROR_DOM_QUOTA_REACHED'].includes(
        domException.name
      )
    ) {
      // handle quota limit exceeded error
      console.error("unable to save hotels, trying again") //eslint-disable-line
      // try just hotels
      sessionStorage.setItem(
        `hotelsHistory${name}`,
        JSON.stringify({
          hotels: state.hotels
        })
      )
    }
  }
}
