import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select'
import { escapeRegexCharacters } from '../helpers/filtersHelper'

const _filterOptions = (candidate, input) => {
  const escapedValue = escapeRegexCharacters(input.trim())
  const regex = new RegExp('\\b' + escapedValue, 'i')
  if (input) {
    return regex.test(candidate.data.state) || regex.test(candidate.value)
  }
  return true
}

const DestinationField = ({
  className,
  isClearable,
  isSearchable,
  name,
  options,
  value,
  placeholder,
  filterOptions,
  handleChange
}) => {

  return (
    <Select
      className={`destinations ${className}`}
      classNamePrefix="select"
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      options={options}
      value={value}
      placeholder={placeholder}
      filterOption={filterOptions}
      onChange={handleChange}
    />
  )
}

DestinationField.defaultProps = {
  className: "",
  name: "destinations",
  isClearable: true,
  isSearchable: true,
  placeholder: "Destinations",
  filterOptions: _filterOptions
}

DestinationField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  filterOptions: PropTypes.func
}

export default DestinationField
