import React from "react"
import PropType from "prop-types"
import AliceCarousel from 'react-alice-carousel'
import { isMobileOnly } from 'react-device-detect'
import "react-alice-carousel/lib/alice-carousel.css"
import { preventImageTheftProps } from '../helpers/imageHelper'

/*
  General Image Carousel
*/

const responsive = {
  0: { items: 1 },
  765: { items: 2 },
  1024: { items: 3 }
}

const CarouselImages = ({ images, ...rest }) => {

  const _displayCarousel = () => {
    if (images && images.length > 1) {
      let imageStruc = images.map((image, idx) => (
        <figure className="image cropped" key={idx}>
          <img data-src={image} alt={image} className="lazy-load-resource" {...preventImageTheftProps} />
        </figure>
      ))
      return (
        <AliceCarousel
          responsive      = {responsive}
          dotsDisabled    = {!isMobileOnly}
          buttonsDisabled = {isMobileOnly}
          infinite        = { false }
          mouseDragEnabled
          {...rest}
          >
          {imageStruc}
        </AliceCarousel>
      )
    }
  }

  return <div className="vo-carousel-images-container">{ images && _displayCarousel()}</div>
}

CarouselImages.propTypes = {
  images: PropType.array.isRequired,
  showThumbs: PropType.bool,
  showIndicators: PropType.bool
}

export default CarouselImages
