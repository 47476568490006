import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isMobile } from 'react-device-detect'
import { SEARCH, SearchContext } from '../contexts/SearchContext'
import DateFieldRange from "../common/forms/DateFieldRange"
import {
  earliestStartDate,
  isDateBlockedForSelection
} from '../helpers/general'
import {
  calcNightsMoment
} from '../helpers/searchHelpers'

import { redirectToPackageHotels } from '../helpers/packageHotelsHelpers'

import SearchDateError from '../modals/SearchDateError'
import Loading from '../common/Loading'

import 'react-dates/lib/css/_datepicker.css'

const SearchDateFields = ({nights, submitHandler}) => {
  const { state, dispatch } = useContext(SearchContext)
  const _setArrival = (payload) =>
    dispatch({ type: SEARCH.ARRIVAL.SET, payload })
  const _setDeparture = (payload) =>
    dispatch({ type: SEARCH.DEPARTURE.SET, payload })
  const _setNights = (payload) =>
    dispatch({ type: SEARCH.NIGHTS.SET, payload })
  const [datesSelected, setDatesSelected] = useState(true)
  const [focusedInput, setFocusedInput] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const _onDismiss = () => setDatesSelected(true)

  useEffect(
    () => {
      setDisabled(!(state.arrival && state.departure))
    }, [state.arrival, state.departure]
  )

  const _loadPackageHotels = (arrival, departure) => {
    dispatch({ type: SEARCH.HOTELS.LOADING, payload: true})

    let nights = calcNightsMoment(arrival, departure)
    _setNights(nights)
    redirectToPackageHotels(state.package.slug, nights, arrival)
    dispatch({ type: SEARCH.SEARCH_BAR_ACTIVE.SET, payload: true})
    if(submitHandler) submitHandler()
  }


  const _handleButtonClick = () => {
    if (!state.arrival || !state.departure) {
      setDatesSelected(false)
      return
    }

    _loadPackageHotels(state.arrival, state.departure)
  }

  const _renderButton = () => (
    <button
      className = "btn cta orange"
      onClick ={_handleButtonClick}
      aria-label = "Book my trip"
      type = "button"
      disabled = {disabled}
      >
      <span className="vertical-text center">
        <span>Search for Hotels</span>
      </span>

      <i className="fas fa-search" />
    </button>
  )

  const _onDatesChange = ({ startDate, endDate }) => {
    if(startDate) {
      _setArrival(startDate)
      let minDeparture = moment(startDate).add(nights, 'days')
      _setDeparture(minDeparture)
      setFocusedInput(null)
    }

    if(endDate) {
      _setDeparture(endDate)
    }
  }

  const _onDateClose = ({startDate, endDate}) => {
    if (startDate && endDate) {
      _loadPackageHotels(startDate, endDate)
    }
  }


  const _onFocusChange = focusedInput =>
    setFocusedInput(focusedInput)

  const _getNumberOfMonths = () => isMobile ? 1 : 2
  const _closeCalendar = () => setFocusedInput(null)

  const _renderCalendarInfo = () => (
    <div className="calendar-info">
      <i className="far fa-times-circle close-calendar" onClick={_closeCalendar} />
      <p>Select { focusedInput == "startDate" ? 'an Arrival' : 'a Departure'} Date</p>
    </div>
  )

  const _renderContent = () => {
    return (
      <div className="disable-dbl-tap-zoom content">
        <i className="fas fa-calendar-alt" />
        <DateFieldRange
          startDate={state.arrival}
          endDate={state.departure}
          focusedInput={focusedInput}
          onDatesChange={_onDatesChange}
          onFocusChange={_onFocusChange}
          onClose= {_onDateClose}
          minimumNights={nights}
          isDayBlocked={isDateBlockedForSelection}
          numberOfMonths={_getNumberOfMonths()}
          keepOpenOnDateSelect={false}
          hideKeyboardShortcutsPanel
          readOnly
          withPortal
          initialVisibleMonth={() => earliestStartDate()}
          startDatePlaceholderText  = "Arrival"
          endDatePlaceholderText    = "Departure"
          calendarInfoPosition      = "top"
          renderCalendarInfo        = {_renderCalendarInfo}
          daySize                   = {42}
          displayFormat             = "ddd, MMM Do"
          />
      </div>
    )
  }

  return (
    <form
      className="disable-dbl-tap-zoom search-date-fields"
      autoComplete="off"
      onSubmit={(e) => { e.preventDefault(); _handleButtonClick()}}>
      { state.isLoading && <Loading /> }
      { _renderContent() }
      { _renderButton() }
      { !datesSelected && <SearchDateError onDismiss={_onDismiss} /> }
    </form>
  )
}

SearchDateFields.defaultProps = {
  openDirection: "down",
  submitHandler: null
}

SearchDateFields.propTypes = {
  nights: PropTypes.number.isRequired,
  destination: PropTypes.object,
  submitHandler: PropTypes.func,
  openDirection: PropTypes.string
}

export default SearchDateFields
