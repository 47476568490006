import { useEffect, useState } from 'react'
import { ResizeObserver as Polyfill } from '@juggle/resize-observer'

const ResizeObserver = window.ResizeObserver || Polyfill

export default (callback) => {
  const [observer] = useState(new ResizeObserver(callback))
  useEffect(() => {

    return () => {
      observer.disconnect()
    }
  }, [])
  return [observer]
}