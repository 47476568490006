import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import dateFormat from 'dateformat'
import queryString from 'query-string'
import moment from 'moment'
import { isMobileOnly } from 'react-device-detect'
import isEmpty from 'lodash/isEmpty'
import { SEARCH, SearchContext } from '../contexts/SearchContext'
import CtaBarMobile from './CtaBarMobile'
import CtaBarDesktop from './CtaBarDesktop'
import { scrubHotel, fetchHotels, fetchHotel } from '../../voc/helpers/hotelHelper'
import { scrollToHotels } from '../helpers/general'
import { setMinDepartureDateMoment } from '../helpers/searchHelpers'

const bookingBarRoot = document.querySelector("#booking-bar-root")

const CtaBar = ({packages, destination}) => {
  const { state, dispatch } = useContext(SearchContext)
  const _setHotel         = (hotel) => dispatch({ type: SEARCH.HOTEL.SET, payload: hotel })
  const _setHotels        = (hotels) => dispatch({ type: SEARCH.HOTELS.SET, payload: hotels })
  const _setCurrentHotels = (hotels) => dispatch({ type: SEARCH.CURRENT_HOTELS.SET, payload: hotels })
  const _setLoading       = (payload) => dispatch({ type: SEARCH.HOTELS.LOADING, payload: payload })
  const _setServerErrors  = (payload) => dispatch({ type: SEARCH.SERVER_ERRORS.SET, payload })
  const _setFullscreen    = (payload) => dispatch({ type: SEARCH.CTA_BAR_FULLSCREEN.SET, payload})
  const _setSearchBy      = (payload) => dispatch({ type: SEARCH.SEARCH_BY.SET, payload })
  const _setPackage       = (payload) => dispatch({ type: SEARCH.PACKAGE.SET, payload: payload })
  const _setNights        = (payload) => dispatch({ type: SEARCH.NIGHTS.SET, payload })
  const _setDestination   = (payload) => dispatch({ type: SEARCH.DESTINATION.SET, payload })
  const _setArrival       = (payload) => dispatch({ type: SEARCH.ARRIVAL.SET, payload })
  const _setDeparture     = (payload) => dispatch({ type: SEARCH.DEPARTURE.SET, payload })
  const _setMinNights     = (payload) => dispatch({ type: SEARCH.MIN_NIGHTS.SET, payload })

  useEffect(() => {
    window.addEventListener('resize', _fullScreenHeightRecalc)
    window.addEventListener('pageshow', _stopLoading )
    return () => {
      // unsubscribe event
      div.removeEventListener("scroll", handleOnScroll);
    };
    return () => {
      window.removeEventListener('resize', _fullScreenHeightRecalc)
      window.removeEventListener('pageshow', _stopLoading)
    }
  }, [])

  useEffect(() => {
    // Only set packages/nights if not already set
    // handles component reload
    if(packages && isEmpty(state.package)) {
      _setPackage(packages)
      if(state.nights < packages.length_of_stay_in_nights) {
        _setNights(packages.length_of_stay_in_nights)
        _setMinNights(packages.length_of_stay_in_nights)
      }
    }
    _checkSearchQuery()
    _setSearchBy(({ id: destination['destinationable-id'], type: destination['destinationable-type'] }))
    _setDestination(destination)
  }, [])

  const _checkSearchQuery = () => {
    if(window.location.search) {
      const parts = queryString.parse(window.location.search)
      let nights = packages.length_of_stay_in_nights

      if("hotels" in parts) {
        scrollToHotels()
        // Hide cta bar
        dispatch({ type: SEARCH.BOOKING_BAR_ACTIVE.SET, payload: false })
      }

      if("nights" in parts) {
        nights = Math.max(parseInt(parts.nights), packages.length_of_stay_in_nights)

        // If nights is greater than passed param,
        // the user has searched, changed the dates
        // and exited checkout, so componenet is reloading
        // ignore setting nights from param
        if(state.nights >= nights) return

        _setNights(nights)
      }

      if("arrival" in parts && state.arrival == null) {
        _setArrival(moment(parts.arrival))
        _setDeparture(setMinDepartureDateMoment(parts.arrival, nights))
      }
    }
  }

  function _fullScreenHeightRecalc() {
    let el = document.querySelector(".booking-bar.cta-bar")
    if(el && el.classList.contains("fullscreen")) {
      el.style.height = `${Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}px`
    }
  }

  const _stopLoading = (e) => {
    if (e.persisted) {
      _setLoading(false)
    }
  }

  // Get first hotel amenities
  const _getHotelAmenities = (hotel) => {
    try {
      fetchHotel(hotel, { include: 'facilities' }).then((response) => {
        dispatch({
          type: SEARCH.HOTEL.UPDATE,
          payload: {
            hotel: response.data.hotels,
            updateField: 'facilities',
          },
        })
        let amenities = response.data.hotels.facilities
        if(amenities) {
          let firstHotel = {...hotel}
          firstHotel.facilities = amenities
          _setHotel(firstHotel)
        }
      })
    } catch(e) {
      console.error(e) //eslint-disable-line
    }
  }

  const _hotelPayload = (nights = 1, arrival = new Date()) => {
    const payload = {
      num_days: nights,
      checkin_date: dateFormat(new Date(arrival), 'yyyy-mm-dd'),
      today: new Date(),
      resource_type: destination['destinationable-type'],
      resource_id: destination['destinationable-id'],
      num_adults: 1,
      include: 'featured_images,tags',
      package_name: state.package.title,
      product: 'vof'
    }
    __getHotels(payload)
  }

  const __getHotels = async (payload) => {
    dispatch({ type: SEARCH.STEP.SET, payload: "hotels"})
    _setLoading(true)
    dispatch({ type: SEARCH.DISPLAY_SEARCH_MESSAGE.SET, payload: false})
    try {
      const data = await fetchHotels(payload)

      if("hotels" in data && data.hotels.length) {
        const scrubbed = data.hotels
          .filter((d) => d.address)
          .map(scrubHotel)

        _setServerErrors(null)

        let firstSlice = scrubbed.slice(0, state.numPerPage + 1)
        _setCurrentHotels(firstSlice)
        _setHotels(scrubbed)
        _setHotel(data.hotels[0])
        if(!isMobileOnly) _getHotelAmenities(data.hotels[0])
        _setLoading(false)
      } else {
        _setServerErrors(data)
        _setLoading(false)
      }
      dispatch({ type: SEARCH.META, payload: data.meta })
    } catch(e) {
      console.error(e) //eslint-disable-line
      _setServerErrors(e)
      _setLoading(false)
    } finally {
      _setLoading(false)
    }
  }

  const _renderMobile = () => {
    if(isMobileOnly || window.innerWidth < 480)
      return <CtaBarMobile
               setFullscreen={_setFullscreen}
               hotelPayload={_hotelPayload}
               destination={destination}
               ctaText={packages['mobile-cta-text']}
             />
  }

  const _renderDesktop = () => {
    if(!isMobileOnly && window.innerWidth > 480)
      return <CtaBarDesktop packages={packages} hotelPayload={_hotelPayload} destination={destination} />
  }

  const _renderContent = () => (
    <React.Fragment>
      { _renderMobile() }
      { _renderDesktop() }
    </React.Fragment>
  )

  const renderCtaBar = () => {
    let classes = "booking-bar cta-bar"
    classes += state.ctaBarActive ? " active" : " inactive"
    if(state.ctaBarFullscreen) classes += " fullscreen"
    return (
      <div
        id="cta-booking-bar"
        className={classes}
        >
        { state.ctaBarActive && _renderContent() }
      </div>
    )
  }

  return (
    ReactDOM.createPortal(
      renderCtaBar(),
      bookingBarRoot,
    )
  )
}

CtaBar.propTypes = {
  destination: PropTypes.object,
  packages: PropTypes.object.isRequired
}

export default CtaBar
