import moment from 'moment'

export const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export const filterProducts = (filters, products) => {
  let filteredProducts = products

  if (filters.search !== '') {
    const escapedValue = escapeRegexCharacters(filters.search)
    const regex = new RegExp("\\b" + escapedValue, "i")
    var keys = ["title", "description", "price"]

    filteredProducts = filteredProducts.filter((product) => {
      return keys.some((a) => {
        return regex.test(product[a])
      })
    })
  }

  if (filters.destination !== '') {
    const destination = filters.destination['value']
    filteredProducts = filteredProducts.filter((product) => {
      return (product.destination_name == destination) || !product.destination_name
    })
  }

  if (filters.dates.start && filters.dates.end) {
    filteredProducts = filteredProducts.filter((product) => {
      return checkStopDate(product, filters.dates) && checkBlockOutDates(product, filters.dates)
    })
  }

  if(filters.groups && filters.groups.length > 0) {
    filteredProducts = filteredProducts.filter((product) => {
      return filters.groups.some(g => {
        return product.ticket_group_name === g.value
      })
    })
  }

  return filteredProducts
}

const checkStopDate = (product, dates) => !product.stop_sell_date ||
  moment(product.stop_sell_date).isSameOrAfter(dates.start, 'day')


const checkBlockOutDates = (product, dates) => {
  if (product.block_out_days) {
    let numOfDays = dates.end.diff(dates.start, 'days')
    if ( "sold_out_periods" in product.block_out_days) {
      for (var d of product.block_out_days.sold_out_periods) {
        if (
          dates.start.isSameOrAfter(d[0], 'day') &&
          dates.end.isSameOrBefore(d[1], 'day')
        ) {
          return false
        }
      }
    }
    if (
        "days_of_week" in product.block_out_days &&
        numOfDays < 8
    ) {
      // less than a week, days of week matter
      var now = dates.start.clone().startOf('day'),
          same = [],
          subset

      while (now.isSameOrBefore(dates.end)) {
        same.push(now.day())
        now.add(1, 'days')
      }
      subset = same.every(v => product.block_out_days.days_of_week.includes(v))
      if (subset) return false
    }
  }
  return true
}
