import React from 'react'
import { Button } from '../../ui'
import { CHECKOUT, CheckoutContext } from '../../contexts/Checkout'

const BackButtonTickets = () => {
  const { checkoutUpdate } = React.useContext(CheckoutContext)

  const _handleBackButton = () => {
    checkoutUpdate({type: CHECKOUT.ACTIVE, payload: false})
  }

  return (
    <Button
      className="checkout-step btn btn-secondary gray grow back"
      clickHandler={ _handleBackButton }
      >
      <i className="left fas fa-chevron-left"></i>
      <span>Back</span>
    </Button>
  )
}

export default BackButtonTickets
