import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ className, children, clickHandler, type, role, ...props }) => (
  <button
    className={`button ${className}`}
    onClick={clickHandler}
    type={type}
    role={role || "button"}
    {...props}>
    {children}
  </button>
)

Button.defaultProps = {
  type: "button",
  role: "button"
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  clickHandler: PropTypes.func.isRequired,
  type: PropTypes.string,
  role: PropTypes.string
}

export default Button
