import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import {
  ProductList,
  FilterProducts,
  DestinationFilter,
  GroupFilter,
  SortProducts,
  SearchProducts,
  Checkout,
  Cart,
  CheckoutContext,
  ProductContext
} from './'

import { filterProducts } from '../helpers/filtersHelper'

import { MProductItem } from './types/ProductItem'
import { PRODUCTS } from './contexts/Product'
import moment from 'moment'

const EcommercePage = ({products, ...rest}) => {
  const { checkoutState }   = useContext(CheckoutContext)
  const { state, dispatch } = useContext(ProductContext)
  const [reloadGroups, setReloadGroups] = useState(false)
  const _setDates = (dates) => dispatch({type: PRODUCTS.FILTERS.DATES, payload: dates})
  const _setDestination = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.DESTINATION, payload })
  const _setGroups = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.GROUPS, payload })
  const _setSearch = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.SEARCH, payload })


  const _setFiltered = (payload) =>
    dispatch({ type: PRODUCTS.FILTERED, payload })

  const _setProducts = () => {
    dispatch({type: PRODUCTS.FILTERED, payload: products})
    dispatch({type: PRODUCTS.ALL, payload: products})
  }

  const _checkSearchQuery = () => {
    if(window.location.search) {
      let parts = queryString.parse(window.location.search)
      //let datesObj = {}

      if ("search" in parts) {
        _setSearch(parts.search)
      }

      if ("groups" in parts) {
        _setGroups(_formatGroups(parts.groups))
        setReloadGroups(true)
      }

      if ("destination" in parts) {
        _setDestination({
          label: parts.destination,
          value: parts.destination
        })
      }

      //if ("startDate" in parts) {
        //let date = _testDateEarlierThanToday(parts.startDate)
                  //? moment()
                  //: moment(parts.startDate)
        //datesObj.start = date
      //}

      //if ("endDate" in parts) {
        //let date = _testDateEarlierThanToday(parts.startDate)
                  //? moment().add(30, "days")
                  //: moment(parts.endDate)
        //datesObj.end = date
      //}

      //if(Object.keys(datesObj).length > 0)
        //_setDates(Object.assign({}, state.filters.dates, datesObj))
    }
  }

  const _formatGroups = (groups) => {
    if(typeof groups === 'string') {
      return [{
        value: groups.slice(-1) === "s" ? groups.slice(0, -1) : groups,
        label: groups
      }]
    } else {
      let newArray = []
      for(var item of groups) {
        newArray.push({
          value: item.slice(-1) === "s" ? item.slice(0, -1) : item,
          label: item
        })
      }
      return newArray
    }
  }

  const _testDateEarlierThanToday = (date) => moment(date).format('YYYY-MM-DD') < moment().format("YYYY-MM-DD")

  useEffect(_setProducts,[])
  useEffect(_checkSearchQuery,[])
  useEffect(() => {
    _setFiltered(filterProducts(state.filters, products))
  }, [state.filters])

  return (
    <React.Fragment>
      {
        !checkoutState.active && <div className="product-page">
          <div className="products-filter-sort-bar">
            <div className="filters">
              <FilterProducts products={products} />
              <DestinationFilter products={products} />
              <GroupFilter reloadDefaultOptions={reloadGroups} />
              { false && <SearchProducts /> }
            </div>
            <div className="sort">
              <label>Sort</label>
              <SortProducts />
            </div>
            <Cart className="products" />
          </div>
          <ProductList products={state.filtered} {...rest} />
        </div>
      }
      {
        checkoutState.active && <div className="checkout-page">
          <Checkout products={products} useCart forHotelBooking={false} mandatoryBillingAddress={false} {...rest} />
        </div>
      }
    </React.Fragment>
  )
}

EcommercePage.propTypes = {
  products: PropTypes.arrayOf(MProductItem)
}

export default EcommercePage
