import React, { useContext, Suspense, lazy } from 'react'
import PropTypes from 'prop-types'

import Loading from '../common/Loading'
import CtaBar from '../searchCta/CtaBar'
const SearchResults = lazy(() => import('./SearchResults'))
const SearchBar = lazy(() => import('./SearchBar'))
const BookingBar = lazy(() => import('../hotels/BookingBar'))

import { SearchContext } from '../contexts/SearchContext'

const SearchContainer = ({ classes, packages, destination }) => {
  const { state } = useContext(SearchContext)

  return (

    <div className={classes}>
      <CtaBar packages={packages} destination={destination} />
      { state.step == "hotels" && (
        <Suspense fallback={<Loading />}>
          <SearchBar />
        </Suspense>
      )}
      <Suspense fallback={<Loading />}>
        <SearchResults />
      </Suspense>
      { state.step == "hotels" && (
        <Suspense fallback={<Loading />}>
          <BookingBar />
        </Suspense>
      )}
    </div>
  )
}

SearchContainer.propTypes = {
  classes: PropTypes.string.isRequired,
  packages: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  featuredHotels: PropTypes.object.isRequired
}

export default SearchContainer