import React from 'react'
import PropTypes from 'prop-types'
import ProductItem from './Item'
import Seating from '../../../common/seating'
import useSeating from '../../hooks/useSeating'

const ProductList = ({products, ...rest}) => {

  const {
    seatingActive,
    setSeatingActive,
    seatingProduct,
    setSeatingProduct
  } = useSeating()

  return (
    <section className="product-list" {...rest}>
      {
        products && products.length > 0 && products.map((product, i) => {
          return <ProductItem
            product           = {product}
            key               = {i}
            seatingActive     = {seatingActive}
            setSeatingActive  = {setSeatingActive}
            setSeatingProduct = {setSeatingProduct}
            order             = {i}
            className={products.length === 1 ? 'single-item' : products.length <= 2 ? 'half' : ''}
            />
        })
      }
      {
        seatingActive &&
        <Seating
          setSeatingActive={setSeatingActive}
          seatingProduct={seatingProduct}
          setSeatingProduct={setSeatingProduct}
          />
      }
      {
        (!products || products.length === 0) && <div className="products-no-results">
          <h3>
            <i className="fas fa-ticket-alt" />
            <span>Sorry, we couldn&apos;t find any tickets for this search.</span></h3>
        </div>
      }
    </section>
  )
}

ProductList.propTypes = {
  products: PropTypes.array,
  seatingProduct: PropTypes.object,
  setSeatingProduct: PropTypes.func
}

export default ProductList
