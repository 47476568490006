import React from "react"
import PropTypes from 'prop-types'

const CartIcon = ({
  style = {},
  width = "100%",
  className = "cart",
  viewBox = "0 0 100 100",
  fill = "#ffffff"
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
  >
    <g fill={fill}>
      <path d="M88.444,25.457c-2.126-0.605-4.337,0.634-4.938,2.76L76.5,52.974H34.077l-7.046-29.748   c-0.399-1.688-1.842-2.927-3.572-3.065l-10.494-0.842c-2.2-0.184-4.131,1.465-4.308,3.668c-0.177,2.201,1.466,4.13,3.667,4.307   l7.594,0.609l7.104,29.993c0.428,1.804,2.039,3.078,3.893,3.078h48.611c1.79,0,3.361-1.188,3.849-2.911l7.83-27.668   C91.806,28.27,90.57,26.059,88.444,25.457z"></path><path d="M33.418,65.251c-4.258,0-7.723,3.465-7.723,7.723s3.465,7.722,7.723,7.722c4.259,0,7.723-3.464,7.723-7.722   S37.677,65.251,33.418,65.251z M33.141,72.974c0-0.153,0.125-0.278,0.277-0.278s0.277,0.125,0.277,0.278   C33.695,73.28,33.141,73.28,33.141,72.974z"></path><path d="M76.297,65.251c-4.257,0-7.721,3.465-7.721,7.723s3.464,7.722,7.721,7.722c4.259,0,7.724-3.464,7.724-7.722   S80.556,65.251,76.297,65.251z M76.297,73.251c-0.152,0-0.276-0.124-0.276-0.277s0.124-0.278,0.276-0.278   c0.154,0,0.279,0.125,0.279,0.278S76.451,73.251,76.297,73.251z"></path>
    </g>
  </svg>
)

CartIcon.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string
}

export default CartIcon
