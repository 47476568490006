import React from 'react'
import PropTypes from 'prop-types'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import { pluralize } from '../../helpers/stringHelper'


const NumberFieldPlusMinus = ({
  minimum,
  maximum,
  setNumber,
  value,
  label,
  fieldClasses,
  wrapperClasses,
  step,
  location,
  direction,
  plusArrowClasses,
  minusArrowClasses,
  displayLabel,
  onLabelClick
}) => {

  const LeftArrow = () => <ArrowLeft
    minimum   = { minimum }
    value     = { value }
    setNumber = { setNumber }
    step      = { step }
    className = { minusArrowClasses }
    />

  const RightArrow = () => <ArrowRight
    maximum   = { maximum }
    value     = { value }
    setNumber = { setNumber }
    step      = { step }
    className = { plusArrowClasses }
    />

  return (
    <div
      className = {`set-number-arrows-wrapper ${wrapperClasses} ${location} ${direction}`}
      >
      {
        (location == "top" || location == "left-side") && (
          <div className="arrow-group">
            { LeftArrow() }{ RightArrow() }
          </div>
        )
      }
      { location == "outside" && LeftArrow() }
      <span
        className  = { `input ${fieldClasses}` }
        tabIndex   = "0"
        aria-label = { label }
        title      = { label }
        onClick    = { onLabelClick }
        >
        { value }{displayLabel && ` ${pluralize(label, value)}`}
      </span>
      {
        (location == "right-side" || location == "bottom") && (
          <div className="arrow-group">
            { LeftArrow() } { RightArrow() }
          </div>
        )
      }
      { location == "outside" && RightArrow() }
    </div>
  )
}

NumberFieldPlusMinus.propTypes = {
  minimum: PropTypes.number,
  maximum: PropTypes.number.isRequired,
  setNumber: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  fieldClasses: PropTypes.string,
  step: PropTypes.number,
  wrapperClasses: PropTypes.string,
  location: PropTypes.oneOf(["outside", "left-side", "right-side", "top", "bottom"]),
  direction: PropTypes.oneOf(["horizontal", "vertical"]),
  plusArrowClasses: PropTypes.string,
  minusArrowClasses: PropTypes.string,
  displayLabel: PropTypes.bool,
  onLabelClick: PropTypes.func
}

NumberFieldPlusMinus.defaultProps = {
  step: 1,
  wrapperClasses: '',
  fieldClasses: '',
  location: "outside",
  direction: "horizontal",
  plusArrowClasses: "fas fa-plus-circle",
  minusArrowClasses: "fas fa-minus-circle",
  minimum: 1,
  displayLabel: false,
  onLabelClick: () => {}
}

export default NumberFieldPlusMinus
