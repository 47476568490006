import React from 'react'
import { MTestimonialTextType } from '../types'

const TestimonialsText = ({testimonial}) => {
  return (
    <div className="testimonial text">
      <blockquote className="text" dangerouslySetInnerHTML={{ __html: testimonial.text }}></blockquote>
      <p className="person">{ testimonial.person }</p>
    </div>
  )
}

TestimonialsText.propTypes = {
  testimonial: MTestimonialTextType
}

export default TestimonialsText
