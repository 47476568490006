import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import queryString from 'query-string'
import { isMobile } from 'react-device-detect'
import DateFieldRange from "../../common/forms/DateFieldRange"
import {
  earliestStartDate,
  isDateBlockedForSelection
} from '../../helpers/general'
import {
  calcNightsMoment
} from '../../helpers/searchHelpers'
import { redirectToPackageHotels } from '../../helpers/packageHotelsHelpers'
import { setMinDepartureDateMoment } from '../../helpers/searchHelpers'


import SearchDateError from '../../modals/SearchDateError'

import 'react-dates/lib/css/_datepicker.css'

const DatesForm = ({packages, submitHandler}) => {
  const [datesSelected, setDatesSelected] = useState(true)
  const [focusedInput, setFocusedInput] = useState(null)
  const [arrival, setArrival] = useState(null)
  const [departure, setDeparture] = useState(null)
  const [nights, setNights] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const _onDismiss = () => setDatesSelected(true)

  useEffect(
    () => {
      setDisabled(!(arrival && departure))
    }, [arrival, departure]
  )

  useEffect(() => {
    if (window.location.search) {
      const parts = queryString.parse(window.location.search)

      if ("arrival" in parts) {
        setArrival(moment(parts.arrival))
        if ("nights" in parts) {
          setDeparture(setMinDepartureDateMoment(parts.arrival, parts.nights))
        }
      }
    }
  }, [])

  const _loadPackageHotels = (arrival, departure) => {
    let nights = calcNightsMoment(arrival, departure)
    setNights(nights)
    redirectToPackageHotels(packages.slug, nights, arrival)
    if(submitHandler) submitHandler()

  }

  const _handleButtonClick = () => {
    if (!arrival || !departure) {
      setDatesSelected(false)
      return
    }

    let nights = calcNightsMoment(arrival, departure)
    setNights(nights)
    _loadPackageHotels(nights, arrival)
    if(submitHandler) submitHandler()
  }

  const _onDateClose = ({startDate, endDate}) => {
    if (startDate && endDate) {
      _loadPackageHotels(startDate, endDate)
    }
  }


  const _renderButton = () => (
    <button
      className="btn cta orange"
      onClick={_handleButtonClick}
      aria-label="Search Hotels"
      disabled    = {disabled}
      type="button"
      >
      <i className="fas fa-search" />
      <label>Search Hotels</label>
    </button>
  )

  const _onDatesChange = ({ startDate, endDate }) => {
    if(startDate) {
      setArrival(startDate)
      let minDeparture = moment(startDate).add(packages.length_of_stay_in_nights, 'days')
      setDeparture(minDeparture)
      setFocusedInput(null)
    }

    if(endDate) {
      setDeparture(endDate)
    }
  }

  const _onFocusChange = focusedInput => setFocusedInput(focusedInput)
  const _getNumberOfMonths = () => isMobile ? 1 : 2
  const _closeCalendar = () => setFocusedInput(null)
  const _renderCalendarInfo = () => (
    <div className="calendar-info">
      <i className="far fa-times-circle close-calendar" onClick={_closeCalendar} />
      <p>Select { focusedInput == "startDate" ? 'arrival' : 'departure'} date</p>
    </div>
  )

  const _renderContent = () => {
    return (
      <div className="disable-dbl-tap-zoom content">
        <DateFieldRange
          startDate={arrival}
          endDate={departure}
          focusedInput={focusedInput}
          onDatesChange={_onDatesChange}
          onClose= {_onDateClose}
          onFocusChange={_onFocusChange}
          minimumNights={packages.length_of_stay_in_nights}
          isDayBlocked={isDateBlockedForSelection}
          numberOfMonths={_getNumberOfMonths()}
          keepOpenOnDateSelect={false}
          initialVisibleMonth={() => earliestStartDate()}
          startDatePlaceholderText  = "Arrival"
          endDatePlaceholderText    = "Departure"
          calendarInfoPosition      = "top"
          renderCalendarInfo        = {_renderCalendarInfo}
          displayFormat             = "ddd, MMM Do"
          hideKeyboardShortcutsPanel
          readOnly
          withPortal
          />
      </div>
    )
  }

  return (
    <form
      className="disable-dbl-tap-zoom search-date-fields"
      autoComplete="off"
      onSubmit={(e) => { e.preventDefault(); _handleButtonClick()}}>
      { _renderContent() }
      { _renderButton() }
      {!datesSelected && <SearchDateError onDismiss={_onDismiss} />}
    </form>
  )
}

DatesForm.defaultProps = {
  openDirection: "down",
  submitHandler: null
}

DatesForm.propTypes = {
  packages: PropTypes.object.isRequired,
  destination: PropTypes.object,
  submitHandler: PropTypes.func,
  openDirection: PropTypes.string
}

export default DatesForm
