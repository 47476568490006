import React from "react"

const ChevronRightAnimated = () => (
  <i className="chevron-right-animation">
    <i className="fas fa-chevron-right"></i>
    <i className="fas fa-chevron-right"></i>
    <i className="fas fa-chevron-right"></i>
  </i>
)

export default ChevronRightAnimated
