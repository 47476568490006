import React from 'react'
import PropTypes from 'prop-types'

const ArrowLeft = ({
  minimum,
  setNumber,
  value,
  step,
  className,
  ...props
}) => {

  const _handleMinus = (e) => {
    e.stopPropagation()
    if ( ( value <= minimum ) || ( (value - step) <= minimum ) ) {
      setNumber(parseInt(minimum))
      return
    }
    setNumber(value - step)
  }

  return (
    <i
      className   = {`minus ${className}${value == minimum ? ' disabled' : ''}`}
      onClick     = {_handleMinus}
      aria-label  = "Decrease quantity"
      title       = "Decrease quantity"
      role        = "button"
      >
      {props.children ? props.children : null}
    </i>
  )
}

ArrowLeft.defaultProps = {
  className: "fas fa-minus-circle"
}

ArrowLeft.propTypes = {
  minimum: PropTypes.number.isRequired,
  setNumber: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
}

export default ArrowLeft