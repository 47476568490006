import React from 'react'
import PropTypes from 'prop-types'
import { InputText } from '../../ui'
import {
  outputFieldErrorClass
} from '../../helpers/formHelper'

import AddressAutocomplete from '../AddressAutocomplete'


const BillingAddressFields = ({ handler, errors, values, handleAddressUpdate }) => {
  return (
    <div className="fieldset shipping-fields fields">

      <div className="field-group street">
        <label htmlFor="street">Street</label>
        <AddressAutocomplete
          name="street"
          value={values.street}
          handleUpdate={handleAddressUpdate} />
      </div>

      <InputText
        className={`city ${outputFieldErrorClass(errors.city)}`}
        type="text"
        name="city"
        label="City"
        value={values.city}
        handler={handler}
        required={true}
        autoComplete = "address-level2"
        />

      <InputText
        className={`state ${outputFieldErrorClass(errors.state)}`}
        type="text"
        name="state"
        label="State"
        value={values.state}
        handler={handler}
        required={true}
        autoComplete = "address-level1"
        />

      <InputText
        className={`zip ${outputFieldErrorClass(errors.zip)}`}
        type="text"
        name="zip"
        label="Postal Code"
        maxLength="6"
        value={values.zip}
        handler={handler}
        required={true}
        autoComplete = "postal-code"
        />
    </div>
  )
}

BillingAddressFields.propTypes = {
  handler: PropTypes.func.isRequired,
  handleAddressUpdate: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  values: PropTypes.shape({
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
}

export default BillingAddressFields
