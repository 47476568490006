import React from 'react'
import PropTypes from 'prop-types'

const ArrowRight = ({
  maximum,
  setNumber,
  value,
  step,
  className,
  ...props
}) => {

  const _handlePlus = (e) => {
    e.stopPropagation()
    if ((value >= maximum) || ((value + step) >= maximum) ) {
      setNumber(parseInt(maximum))
      return
    }
    setNumber(value + step)
  }

  return (
    <i
      className   = {`plus ${className}${value == maximum ? ' disabled' : ''}`}
      onClick     = {_handlePlus}
      aria-label  = "Increase quantity"
      title       = "Increase quantity"
      role        = "button"
      >
      {props.children ? props.children : null}
    </i>
  )
}

ArrowRight.defaultProps = {
  className: "fas fa-plus-circle"
}

ArrowRight.propTypes = {
  maximum: PropTypes.number.isRequired,
  setNumber: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
}

export default ArrowRight