/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export const dynamicSort = (property) => {
  var sortOrder = 1

  if(property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a,b) {
    if(sortOrder == -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  }
}

// Numberic Sort for an array of objects by some specific key
export const numericSort = (property) => {
  var sortOrder = 1

  if(property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a,b) {
    if(sortOrder == -1) {
      return parseFloat(b[property]) - parseFloat(a[property])
    } else {
      return parseFloat(a[property]) - parseFloat(b[property])
    }
  }
}
