
export const timeString12hr = (timeString) => {
  if(!timeString) return false

  return new Date('1970-01-01T' + timeString + 'Z')
    .toLocaleTimeString({},
      {timeZone:'UTC', hour12:true, hour:'numeric', minute:'numeric'}
    )
}

export const isInt = (n) => n % 1 === 0