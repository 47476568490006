import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../ui/Button'

export const ButtonInCart = ({handler}) => {
  return (
    <Button
      className="primary btn btn-primary grad blue already-in-cart"
      clickHandler={handler}
      >
        In Cart
      </Button>
  )
}

ButtonInCart.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonAddToCart = ({handler}) => {
  return (
    <Button
      className="primary btn btn-primary grad add-to-cart"
      clickHandler={handler}
      >
        Add
      </Button>
  )
}

ButtonAddToCart.propTypes = {
  handler: PropTypes.func.isRequired
}


ButtonInCart.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonAddToCartSeating = ({handler}) => {
  return (
    <Button
      className="primary btn btn-primary grad add-to-cart-seating"
      clickHandler={handler}
      >
        Add to Cart
      </Button>
  )
}

ButtonAddToCartSeating.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonBuyNow = ({handler}) => (
  <Button
    className="primary btn btn-primary grad blue buy-now"
    clickHandler={handler}
    >
      Buy Now
    </Button>
)

ButtonBuyNow.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonSelectSeats = ({handler}) => (
  <Button
    className="primary btn btn-primary grad select-seats"
    clickHandler={handler}
    >
      Select seats
    </Button>
)

ButtonSelectSeats.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonAddMoreSeats = ({handler}) => (
  <Button
    className="primary btn btn-primary grad blue add-more-seats"
    clickHandler={handler}
    >
      Add More seats
    </Button>
)

ButtonAddMoreSeats.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonSelectSeatsClose = ({handler}) => {
  return (
    <Button
      className="primary btn btn-primary grad close-select-seats"
      clickHandler={ handler }
      >
        Close
      </Button>
  )
}

ButtonSelectSeatsClose.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonChangeDate = ({handler}) => {
  return (
    <Button
      className="btn plain small change-date-button"
      clickHandler={ handler }
      >
        <i className="fas fa-calendar-alt"></i>
        <span>Change Date</span>
      </Button>
  )
}

ButtonChangeDate.propTypes = {
  handler: PropTypes.func.isRequired
}

export const ButtonXClose = ({handler}) => {
  return (
    <Button
      className='close'
      data-dismiss="modal"
      aria-label="Close"
      clickHandler= { handler }>
      <span>
        &times;
      </span>
    </Button>
  )
}

ButtonXClose.propTypes = {
  handler: PropTypes.func.isRequired
}
