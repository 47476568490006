import { validCardNumber } from './creditCardHelper'
import Payment from 'payment'

export const cardType = (cardNumber) => {
  return Payment.fns.cardType(cardNumber) || false
}

export const validateName = (value) => {
  return value === null ||
    value.toString().replace(/\t|\s|\n|\r/g, '').length < 1
    ? 'Required'
    : undefined
}

export const validatePhone = (value) => {
  if (!value) return undefined

  let phone = undefined
  let phoneDigits = value.match(/\d+/g)
  if (phoneDigits) {
    phone = phoneDigits.join('')
  } else {
    return undefined
  }
  if (phone.length !== 10) return 'Phone number should be 10 digits'
}

export const validateEmail = (value) => {
  if (!value) return undefined

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
    return 'Invalid email'
}

export const validateCardNumber = (value) => {
  if (!value) return undefined

  let n = value.match(/\d+/g).join('')
  return !validCardNumber(n) ? 'Card Number appears to be invalid' : undefined
}

export const validatePostCode = (value) => {
  if (!value) return undefined
  return value.length < 3 ? "Must be 3 digits" : undefined
}

export const validateAmexPostCode = (value) => {
  if (!value) return undefined
  return value.length < 4 ? "Must be 4 digits" : undefined
}

export const validateCardExpiration = (value) => {
  if (!value) return undefined
  if (value.length < 5) {
    return "Use MM/YY format"
  } else if (value.length == 5) {
    let [month, year] = value.split("/")
    let today = new Date()
    let century = today.getFullYear()
    century = `${century}`.slice(0,2)
    return new Date(century + year, month) < today ? "Expired" : undefined
  } else if (value.length == 7) {
    let [month, year] = value.split("/")
    let today = new Date()
    return new Date(year, month) < today ? "Expired" : undefined
  } else {
    return "Use MM/YY format"
  }
}


export const validateCvcCode = (value) => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

export const normalizePhone = (value) => {
  if (!value) return value

  const n = value.replace(/[^\d]/g, '')

  if (n.length <= 3) return n
  else if (n.length <= 6) return `(${n.slice(0, 3)}) ${n.slice(3)}`

  return `(${n.slice(0, 3)}) ${n.slice(3, 6)}-${n.slice(6, 10)}`
}

export const normalizeName = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const normalizeCardExpiration = (value) => {
  if (value == null) return value

  let n = value.replace(/[^\d]/g, '')

  if (n.length <= 2) return n

  return `${n.slice(0, 2)}/${n.slice(2, 6)}`
}

export const normalizeCardCode = (value) => {
  if (value == null) return value

  let n = value.replace(/[^\d]/g, '')

  return n.slice(0, 3)
}

export const normalizeAmexCardCode = (value) => {
  if (value == null) return value

  let n = value.replace(/[^\d]/g, '')

  return n.slice(0, 4)

}

export const normalizeCardNumber = (value) => {
  if (!value) return value
  return Payment.fns.formatCardNumber(value)
}

export const setAllObjValues = (obj, val) => Object.keys(obj).forEach(k => obj[k] = val)

export const outputFieldErrorClass = (c) => c ? 'error' : ''
