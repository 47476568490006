import { useEffect, useRef, useState } from "react"

// if onEveryClick is true, returns an object not boolean
// an object changes the truthly statement which allows
// an useEffect to be called everytime the set func
// is called, not just when value has changed
// setting value to true 3 times, useEffect is called each
export default (props) => {
  const [onEveryClick] = useState(props && "onEveryClick" in props)
  const [outsideClick, setOutsideClick] = useState(onEveryClick ? {click: false} : false)
  const outsideRef = props && "outsideRef" in props ? props.outsideRef : useRef(null)

  function handleClickOutside(event) {
    if (outsideRef && outsideRef.current && !outsideRef.current.contains(event.target)) {
      setOutsideClick(onEveryClick ? {click: true} : true)
    } else if(outsideRef && outsideRef.current && outsideRef.current.contains(event.target)) {
      setOutsideClick(onEveryClick ? {click: false} : false)
    }
  }

  function handleKeydown(event) {
    if (event.key === "Escape") {
      setOutsideClick(onEveryClick ? {click: true} : true)
    }
  }

  useEffect(() => {
    // Bind the event listeners
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("keydown", handleKeydown)
    return () => {
      // Unbind the event listeners on clean up
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleKeydown)
    }
  },[])

  return {outsideClick, outsideRef, setOutsideClick}
}
