import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CHECKOUT,
  CheckoutContext
} from '../../contexts/Checkout'

import {
  CartContext
} from '../../contexts/Cart'

import WillCallDisclaimer from './WillCallDisclaimer'
import WillCallItem from './WillCallItem'


const WillCall = ({setAvailable}) => {
  const { checkoutState: {willCall}, checkoutUpdate } = useContext(CheckoutContext)
  const { cartState } = useContext(CartContext)
  const _setWillCall = (values) => checkoutUpdate({ type: CHECKOUT.WILL_CALL, payload: values})
  const [selected] = useState(false)

  useEffect(()=> {
    // check if all items are will-call
    if(willCall.items.length == cartState.items.length && !willCall.selected) {
      let data = {...willCall}
      data.selected = true
      _setWillCall(data)
    } else if(willCall.items.length !== cartState.items.length && willCall.selected) {
      let data = {...willCall}
      data.selected = false
      _setWillCall(data)
    }

  }, [willCall])

  // Display if at least one product is will-call
  const _checksBeforeDisplaying = () => {
    let truthy = cartState.items.some(item => item.will_call_pickup)
    setAvailable(truthy)
    if(!truthy && willCall.selected) {
      let data = {...willCall}
      data.selected = false
      _setWillCall(data)
    }

    return truthy
  }

  const _handler = (checked, item) =>
    checked ? _handleAdd(item) : _handleRemoveItem(item)

  const _handleAdd = (item) => {
    let data = {...willCall}
    item = restructureData(item)
    data.items.push(item)
    _setWillCall(data)
  }

  const restructureData = (item) => {
    return {
      id: item.id,
      title: item.title,
      box_office: item.will_call_box_office,
      box_office_address: item.will_call_box_office_address
    }
  }

  const _handleRemoveItem = (item) => {
    let data = {...willCall}
    data.items = data.items.filter(i => i.id !== item.id)
    if(data.items.length <= 0)
      data.selected = false
    _setWillCall(data)
  }

  const renderWillCallItems = () => {
    return cartState.items.map((item, i) => {
      if(item.will_call_pickup) {
        // check if Item in willCall, for re-render after back button
        let selected = willCall.items.some(i => i.id == item.id)
        return <WillCallItem key={i} item={item} handler={_handler} selected={selected} />
      }
    })
  }

  const _renderWillCallOptions = () => (
    <section className="will-call">
      <h3>Will Call Pickup Options</h3>
      <p><small>Check the box below if you want to pick up your tickets at the Will Call Pickup address provided below, otherwise the tickets will be shipped</small></p>
      { renderWillCallItems() }
      <WillCallDisclaimer selected={selected} />
    </section>
  )

  return (
    <React.Fragment>
      { _checksBeforeDisplaying() && _renderWillCallOptions() }
    </React.Fragment>
  )

}

WillCall.propTypes = {
  setAvailable: PropTypes.func.isRequired
}

export default WillCall
