import React from "react"
import PropTypes from "prop-types"

import 'react-dates/initialize'
import momentPropTypes from 'react-moment-proptypes'
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController'

// Produces an open calendar with no input field
const DateFieldControlled = ({
  date,
  onDateChange,
  focused,
  onFocusChange,
  ...props
}) => {

  return (
    <DayPickerSingleDateController
      date={date}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={onFocusChange}
      noBorder
      transitionDuration={0}
      {...props}
      />
  )
}

DateFieldControlled.defaultProps = {
  date: null,
  focused: true
}

DateFieldControlled.propTypes = {
  date: momentPropTypes.momentObj,
  onDateChange: PropTypes.func.isRequired,
  focused: PropTypes.bool,
  onFocusChange: PropTypes.func.isRequired
}

export default DateFieldControlled
