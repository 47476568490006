import React from "react"
import PropTypes from 'prop-types'

const SortIcon = ({
  style = {},
  width = "100%",
  className = "sort",
  viewBox = "0 0 100 100",
  fill = "#ffffff"
}) => (
  <svg
    width={width}
    style={style}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
  >
    <g fill={fill}>
      <path d="M93.5,26.375c0,2.761-2.238,5-5,5h-77c-2.762,0-5-2.239-5-5v-4.667c0-2.761,2.238-5,5-5h77c2.762,0,5,2.239,5,5V26.375z"></path>
      <path d="M63.5,52.125c0,2.762-2.238,5-5,5h-47c-2.762,0-5-2.238-5-5v-4.666c0-2.762,2.238-5,5-5h47c2.762,0,5,2.238,5,5V52.125z"></path>
      <path d="M33.5,78.292c0,2.761-2.238,5-5,5h-17c-2.762,0-5-2.239-5-5v-4.667c0-2.761,2.238-5,5-5h17c2.762,0,5,2.239,5,5V78.292z"></path>
    </g>
  </svg>
)

SortIcon.propTypes = {
  style: PropTypes.object,
  fill: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string
}

export default SortIcon
