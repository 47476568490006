import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../ui'

const DISCLAIMER_TEXT = <ul>
  <li>To pick up your tickets, please make sure you have your photo ID.</li>
  <li>You may not be able to pick up your tickets until the day of event.</li>
  <li>We recommending picking up your tickets at least 30 minutes before the event.</li>
</ul>


const WillCallDisclaimer = ({selected}) => {
  const [open, setOpen] = useState(selected)
  const _handleClickAction = () => setOpen(!open)

  useEffect(() => {
    setOpen(selected)
  }, [selected] )

  return (
    <div className="toggle-slide-wrapper">
      <Button
        className="text link refund disclaimer"
        clickHandler={ _handleClickAction }
        >
        <i className="fas fa-asterisk"></i> Disclaimer
      </Button>
      <aside className={`refund-details toggle-slide ${open ? "open" : ""}`}>
        {DISCLAIMER_TEXT}
      </aside>
    </div>
  )
}


WillCallDisclaimer.defaultProps = {
  selected: false
}

WillCallDisclaimer.propTypes = {
  selected: PropTypes.bool
}

export default WillCallDisclaimer
