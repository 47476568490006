import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isMobileOnly, isMobile } from 'react-device-detect'
import CartItem from './Item'
import Counter from './Counter'
import Button from '../ui/Button'
import useIntersect from '../../../vof/hooks/useIntersect'
import useOutsideClick from '../../hooks/useOutsideClick'
import { scrollToEl } from '../helpers/general'

import {
  CartContext,
  CART
} from '../contexts/Cart'

import {
  CheckoutContext,
  CHECKOUT
} from '../contexts/Checkout'

const CART_MOBILE_TOP = 24
const CART_MOBILE_TOP_STATIONARY = 80
const CART_TOP = 100

const Cart = ({className = '', ...props}) => {
  const { cartState: {items, active, total}, cartUpdate } = useContext(CartContext)
  const _setActive = (active) => cartUpdate({type: CART.ACTIVE, payload: active})
  const _setTotal  = (total)  => cartUpdate({type: CART.TOTAL, payload: total})

  const { checkoutUpdate } = useContext(CheckoutContext)
  const _setCheckoutActive = (active) =>
    checkoutUpdate({type: CHECKOUT.ACTIVE, payload: active})

  const [scrolling, setScrolling]   = useState(false)
  const [empty, setEmpty]           = useState(items.length <= 0)
  const cartRef                     = useRef()
  const [manualOpen, setManualOpen] = useState(false)
  const [timeOutNum, setTimeoutNum] = useState(null)
  const [isCartIntersecting, setIsCartIntersecting]   = useState(false)
  const [originalProdPageTop, setOriginalProdPageTop] = useState(0)

  const _calcThreshold = () => (isMobileOnly && window.innerWidth < 365)
                                ? [0.2, 0.25, 0.5]
                                : isMobile && window.innerHeight < 460
                                ? [0.3, 0.5]
                                : isMobileOnly
                                ? [0.4, 0.5]
                                : [0.5]

  let threshold = _calcThreshold()

  // close cart if outside click
  const {outsideClick} = useOutsideClick({outsideRef: cartRef, onEveryClick: true})

  useEffect(() => {
    if(outsideClick.click && active) _setActive(false)

    // inside click: clear timeout
    if(!outsideClick.click) clearCloseTimeout()

  },[outsideClick])

  const setCloseTimeout = () => setTimeoutNum(setTimeout(() => _handleClose(), 4000))

  const clearCloseTimeout = () => {
    if(timeOutNum) {
      clearTimeout(timeOutNum)
      setTimeoutNum(null)
    }
  }

  useEffect(() => {
    if(active && !manualOpen) {
      setCloseTimeout()
    }
    if(!active) {
      setManualOpen(false)
      if(timeOutNum) clearCloseTimeout()
    }
  },[active])

  useEffect(() => {
    !empty
    ? turnOnFloatingCart()
    : turnOffFloatingCart()
  },[empty])

  useEffect(() => {
    setEmpty(items.length <= 0)
  },[items.length])

  const handleManualClick = () => setManualOpen(true)


  // Scrolling methods
  // If cart is intersecting with products, set to scrolling

  const _handleIntersect = (entries) => {
    entries.forEach(entry => {
      const { isIntersecting, boundingClientRect } = entry

      // only observe for top, not bottom
      if(boundingClientRect.y > 0) {
        setIsCartIntersecting(isIntersecting)
      }
    })
  }

  // eslint-disable-next-line
  const [observerNode, setObserverNode, updateThreshold] = useIntersect({
    callback: _handleIntersect,
    controlled: false,
    threshold: threshold
  })

  const _setCartTopFromResize = () => {
    let prodPage = document.querySelector(".product-page")
    let cartEl   = document.querySelector(".product-cart")
    // only set top if not mobile from resize?
    if(prodPage && !cartEl.classList.contains("empty")) {
      if(isMobileOnly && scrolling) {
        // ignore
      } else {
        let box = prodPage.getBoundingClientRect()
        if(isMobileOnly) {
          setCartTopRightPx(CART_MOBILE_TOP, box.left)
        } else {
          if(box.y > 0) {
            setCartTopRightPx(box.y, box.left)
          }
        }
      }
    }
  }

  const setCartTopRightPx = (top, right) => {
    setCartTopPx(top)
    cartRef.current.style.right = right + 'px'
  }
  const setCartTopPx = (top) => {
    if(top < 0)
      top = isMobileOnly ? CART_MOBILE_TOP : CART_TOP;
    cartRef.current.style.top = top + 'px'
  }

  const _setCartTop = () => {
    let prodPage = document.querySelector(".product-page")
    if(prodPage) {
      if(isMobileOnly) {
        setCartTopPx(CART_MOBILE_TOP)
      } else {
        let box = prodPage.getBoundingClientRect()
        if(box.y > 0 && isMobileOnly && originalProdPageTop > 0) {
          setCartTopPx(originalProdPageTop)
        } else if(box.y < 0 ) {
          setCartTopPx(CART_TOP)
        } else if(box.y > 0 && box.y > originalProdPageTop) {
          if(isMobileOnly) {
            let height = cartRef.current.getBoundingClientRect().height
            setCartTopPx(Math.abs(box.y - height))
          } else {
            setCartTopPx(box.y)
          }
        } else if(box.y > 0 && box.y < originalProdPageTop) {
          setCartTopPx(originalProdPageTop)
        }
      }
    }
  }

  var mql = null

  const turnOnFloatingCart = () => {
    let prodPage = document.querySelector(".product-page")
    if(prodPage) {
      let box = prodPage.getBoundingClientRect()
      setCartTopRightPx(box.y, box.left)
      cartRef.current.style.opacity = 1
      let original = isMobileOnly ?
        Math.abs(box.y - cartRef.current.getBoundingClientRect().height)
        : Math.abs(box.y)
      setOriginalProdPageTop(original)
      setObserverNode(prodPage)
    }

    window.addEventListener("resize", _setCartTopFromResize)
    window.addEventListener("scroll", _watchForScreenTop)

    if("orientation" in screen) {
      screen.orientation.addEventListener("change", _handleScreenOrientationChange, false)
    } else {
      mql = window.matchMedia("(orientation: portrait)")
      // Add a media query change listener
      mql.addListener(_handleMatchMedia)
    }

    return () => {
      window.removeEventListener("resize", _setCartTopFromResize)
      window.removeEventListener("scroll", _watchForScreenTop)
      if("orientation" in screen) {
        screen.orientation.removeEventListener("change", _handleScreenOrientationChange, false)
      } else {
        if(mql) mql.removeEventListener(_handleMatchMedia)
      }
    }
  }

  function _watchForScreenTop() {
    if(window.scrollY === 0) {
      if(isMobileOnly) {
        setCartTopPx(CART_MOBILE_TOP_STATIONARY)
      } else {
        setCartTopPx(CART_TOP)
      }
    }
  }

  const turnOffFloatingCart = () => {
    setScrolling(false)
    cartRef.current.style.top = ""
    cartRef.current.style.right = 0
    window.removeEventListener("resize", _setCartTopFromResize)
    if("orientation" in screen) {
      screen.orientation.removeEventListener("change", _handleScreenOrientationChange, false)
    } else {
      if(mql) mql.removeEventListener(_handleMatchMedia)
    }
  }

  function _handleMatchMedia(m) {
    console.log("in eventListener for matchQuery, ", m) //eslint-disable-line
    _handleScreenOrientationChange()
    //(m.matches) ? //Changed to portrait : //Changed to landscape
  }

  const _handleScreenOrientationChange = () => {
    // recalc threshold, reassign observer
    setScrolling(false)
    setObserverNode(null)
    setIsCartIntersecting(false)
    let threshold = _calcThreshold()
    let prodPage = document.querySelector(".product-page")
    updateThreshold(threshold)
    setObserverNode(prodPage)
  }

  useEffect(() => {
    if(cartRef.current && !empty) {
      if(isCartIntersecting) {
        setScrolling(true)
        cartRef.current.style.top = null
      } else {
        setScrolling(false)
        _setCartTop()
      }
    }
  }, [isCartIntersecting])

  // close cart while user scrolls
  useEffect(() => {
    if(scrolling && active) setCloseTimeout()
  },[scrolling])

  const _renderItems = () => {
    return items.map((product, i) => {
      if(!("amount" in product)) {
        let tmp = {...product}
        tmp.amount = 1
        product = tmp
      }
      return <CartItem
              product={product}
              key={i}
              productIndex={i}
              calcCartTotal={_calcTotal}
              />
    })
  }

  const _handleCheckout = () => {
    _setCheckoutActive(true)
    scrollToEl(document.querySelector(".checkout-page"), "start")
    _setActive(false)
  }

  const _handleClose = () => _setActive(false)

  const _calcTotal = () => {
    let totalAmount = 0
    items.forEach(item => {
      totalAmount += "showtimes" in item
        ? item.showtimes.price
        : item.amount * item.price
    })
    _setTotal(totalAmount.toFixed(2))
  }

  useEffect(_calcTotal, [active, items.length, items])

  const _setActiveClass = () => active ? 'open' : ''

  return (
    <aside
      className={`product-cart ${_setActiveClass()} ${className}${scrolling ?' scrolling':""}${empty?" empty":''}`}
      ref={cartRef}
      {...props}>

      <Counter
        handleClick={handleManualClick}
        />

      <div className={`cart-items ${_setActiveClass()}`}>
        { items && _renderItems() }
        { items.length > 0 && <dl className="cart-total">
          <dt>${total}</dt>
          <dd>Total</dd>
        </dl>}
        { items.length === 0 &&
          <p className="no-items">No items currently in cart</p>}

        <nav className="actions">
          <Button
            className="btn btn-secondary"
            clickHandler={_handleClose}
            >
            <span className="effect"></span>
            Close
          </Button>
          { items && items.length > 0 &&
            <Button
              className="btn btn-primary grad"
              clickHandler={_handleCheckout}
              >
              Checkout
            </Button>
          }
        </nav>
      </div>
    </aside>
  )
}

Cart.propTypes = {
  className: PropTypes.string
}

export default Cart
