import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  products: [],
  filtered: [],
  active: null,
  cart: {
    items: null,
    open: false
  },
  filters: {
    search: '',
    destination: '',
    groups: null,
    dates: {
      start: null,
      end: null
    }
  }
}

export const PRODUCTS = {
  ALL: 'PRODUCTS/ALL',
  FILTERED: 'PRODUCTS/FILTERED',
  ACTIVE: 'PRODUCTS/ACTIVE',
  CART: {
    ITEMS: 'PRODUCTS/CART/ITEMS',
    OPEN: 'PRODUCTS/CART/OPEN'
  },
  FILTERS: {
    SEARCH: 'PRODUCTS/FILTERS/SEARCH',
    DESTINATION: 'PRODUCTS/FILTERS/DESTINATION',
    GROUPS: 'PRODUCTS/FILTERS/GROUPS',
    DATES: 'PRODUCTS/FILTERS/DATES'
  }
}

let reducer = ( state, action ) => {
  switch(action.type) {
    case "reset":
      return initialState
    case PRODUCTS.ALL:
      return { ...state, products: action.payload }
    case PRODUCTS.FILTERED:
      return { ...state, filtered: action.payload }
    case PRODUCTS.ACTIVE:
      return { ...state, active: action.payload }
    case PRODUCTS.CART.ITEMS:
      return { ...state, items: action.payload }
    case PRODUCTS.CART.OPEN:
      return { ...state, open: action.payload }
    case PRODUCTS.FILTERS.SEARCH:
      return { ...state, filters: { ...state['filters'], search: action.payload } }
    case PRODUCTS.FILTERS.DESTINATION:
      return { ...state, filters: { ...state['filters'], destination: action.payload } }
    case PRODUCTS.FILTERS.GROUPS:
      return { ...state, filters: { ...state['filters'], groups: action.payload } }
    case PRODUCTS.FILTERS.DATES:
      return { ...state, filters: { ...state['filters'], dates: action.payload } }
    default:
      return state
  }
}

export const ProductContext = createContext()

export const ProductProvider = ({ children }) => {
  let [state, dispatch] = useReducer(reducer, initialState)
  let value = { state, dispatch }

  return (
    <ProductContext.Provider value={ value }>
      { children }
    </ProductContext.Provider>
  )
}

ProductProvider.propTypes = {
  children: PropTypes.node
}

export const ProductConsumer = ProductContext.Consumer
