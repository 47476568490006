import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { SearchProvider } from '../../contexts/SearchContext'
const HotelsCarousel = lazy(() => import('./HotelsCarousel'))
import Loading from '../../common/Loading'

const HotelsWrapper = ({ featuredHotels, ...rest }) => {
  return (
    <SearchProvider>
      <Suspense fallback={<Loading />}>
        <HotelsCarousel
          featuredHotels = {featuredHotels}
          {...rest}
          />
      </Suspense>
    </SearchProvider>
  )
}

HotelsWrapper.propTypes = {
  featuredHotels: PropTypes.object.isRequired
}

export default HotelsWrapper
