import React, { useContext, useEffect, useRef, lazy, Suspense } from "react"
import PropTypes from "prop-types"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

import Loading from '../../../vof/common/Loading'
const GuestInformation    = lazy(() => import("./guestInformation/GuestInformation"))
const Shipping            = lazy(() => import("./shipping/Shipping"))
const Billing             = lazy(() => import("./billing/Billing"))
const OrderThankYou       = lazy(() => import("./OrderThankYou"))
const CheckoutSummary     = lazy(() => import("./summary/CheckoutSummary"))
const CheckoutSummaryCart = lazy(() => import("./summary/CheckoutSummaryCart"))
const CheckoutProgressSteps = lazy(() => import("../common/CheckoutProgressSteps"))

import { CHECKOUT, CheckoutContext } from "../contexts/Checkout"
import { CartContext } from "../contexts/Cart"
import { SearchContext, SEARCH } from '../../../vof/contexts/SearchContext'

import {
  removeFullScreenClasses,
  setFullScreenClasses,
  setFullScreenHeights,
  scrollToEl,
  checkoutStepIcons
} from "../helpers/general"
import { scrollToEcommerce } from "../helpers/general"

import useBrowserHistory from '../../hooks/useBrowserHistory'

const Checkout = ({
  useCart = false,
  forHotelBooking = true,
  mandatoryBillingAddress = false,
}) => {
  const {
    checkoutState,
    checkoutUpdate,
  } = useContext(CheckoutContext)

  let searchUpdate = null

  if(forHotelBooking) {
    const { dispatch } = useContext(SearchContext)
    searchUpdate = dispatch
  }

  const checkoutPageRef = useRef(null)
  let cartDispatch = undefined

  const _setNewState = (payload) => checkoutUpdate({ type: CHECKOUT.ALL, payload })

  if (useCart) {
    const {
      cartState,
      cartUpdate
    } = useContext(CartContext)
    cartDispatch = cartUpdate

    useEffect(() => {
      checkoutUpdate({ type: CHECKOUT.ITEMS, payload: cartState.items })
      checkoutUpdate({ type: CHECKOUT.USE_CART, payload: useCart })
      checkoutUpdate({ type: CHECKOUT.HOTEL_BOOKING, payload: forHotelBooking })
      checkoutUpdate({ type: CHECKOUT.STEP, payload: "shipping" })
    }, [])
  }

  useEffect(() => {
    checkoutUpdate({ type: CHECKOUT.HOTEL_BOOKING, payload: forHotelBooking })
    if(checkoutState.useFullscreen) {
      if(!checkoutState.isFullscreen)
        scrollToEl(document.querySelector(".checkout-page"), "start")

      if (checkoutState.isFullscreen) {
        setFullScreenClasses()
        window.addEventListener("resize", _resizeActions)
      } else {
        if(checkoutPageRef)
          scrollToEl(checkoutPageRef.current, "start")
      }
      return () => {
        clearAllBodyScrollLocks()
        window.removeEventListener("resize", _resizeActions)
      }
    }
  }, [])

  // All browser actions for checkout
  const onPopState = (historyState) =>
    historyState &&
    "stateTitle" in historyState &&
    _handleBrowserActions(historyState)

  const [setUnlisten, setStartBlocking] = useBrowserHistory(checkoutState, onPopState)

  useEffect(
    () => {
      setTimeout(() => scrollToEcommerce(), 100)
      if(checkoutState.step == "complete") {
        clearHistory()
        window.addEventListener("popstate", _refreshPage)

        return () => {
          window.removeEventListener("popstate", _refreshPage)
        }
      }
    }, [checkoutState.step]
  )

  const clearHistory = () => {
    setStartBlocking(true)
    setUnlisten(true)
  }

  const _handleBrowserActions = (historyState) => {
    // console.log("_handleBrowserActions() checkout ")
    if (checkoutState.step != "complete") {
      if (
        historyState.stateTitle == "Checkout Billing Step Valid" ||
        historyState.stateTitle == 'Checkout Cart Summary Step' ||
        historyState.stateTitle == 'Checkout Guest Information Step Valid'
        )
      {
        let newState = {}
        newState = { ...newState, ...historyState.data }
        // console.log(historyState.stateTitle, newState)
        _setNewState(newState)
      } else if (
        historyState.stateTitle == 'Hotel Checkout Initial Summary' ||
        historyState.stateTitle == 'Hotel Booking Bar Guest')
      {
        // close checkout, open hotels
        // console.log(historyState.stateTitle, newState)
        let newState = {}
        newState.active = true
        newState = { ...newState, ...historyState.data }
        searchUpdate({ type: SEARCH.ALL, payload: newState})
        checkoutUpdate({ type: CHECKOUT.ACTIVE, payload: false })
      }
    }
  }

  function _resizeActions() {
    setFullScreenHeights()
  }

  function _refreshPage() {
    window.location.reload(true)
  }

  const _renderStep = () => {
    if (checkoutState.step == "guestInformation")
      return <Suspense fallback={<Loading />}><GuestInformation /></Suspense>

    if (checkoutState.step == "shipping")
      return <Suspense fallback={<Loading />}><Shipping /></Suspense>

    if (checkoutState.step == "cartSummary")
      return <Suspense fallback={<Loading />}><CheckoutSummaryCart /></Suspense>

    if (checkoutState.step == "billing")
      return <Suspense fallback={<Loading />}><Billing mandatoryBillingAddress={mandatoryBillingAddress} /></Suspense>

    if (checkoutState.step === "confirm")
      return <Suspense fallback={<Loading />}><CheckoutSummary /></Suspense>

    if (checkoutState.step == "complete")
      return <Suspense fallback={<Loading />}><OrderThankYou /></Suspense>
  }

  const _renderFullscreenButton = () => {
    let iconClass = checkoutState.isFullscreen ? "fas fa-times" : "fas fa-expand"
    let fullClass = checkoutState.isFullscreen ? "fullscreen compress" : "fullscreen expand"
    return (
      <button className={`${fullClass}`} onClick={_toggleFullscreen}>
        <i className={`${iconClass}`} />
      </button>
    )
  }

  const _toggleFullscreen = () => {
    checkoutUpdate({ type: CHECKOUT.FULLSCREEN, payload: !checkoutState.isFullscreen })

    let locking = document.querySelector(".checkout-page")
    if (checkoutState.isFullscreen) {
      removeFullScreenClasses()
      enableBodyScroll(locking)
      if(checkoutState.step == "complete") {
        _resetStateAndExit()
      }
    } else {
      setFullScreenClasses()
      disableBodyScroll(locking)
    }
  }

  const _resetStateAndExit = () => {
    if(useCart) {
      cartDispatch({ type: "reset" })
    }
    checkoutUpdate({ type: "reset" })
  }

  const _renderCheckoutSteps = () => {
    let steps = [], active = checkoutState.step
    let filtered = forHotelBooking ? ["complete", "shipping"] : ["complete"]

    steps = checkoutState.steps.filter(x => !filtered.includes(x)).map(
      (step) => ({
        name: step,
        iconClass: checkoutStepIcons.get(step)
      }
    ))

    return <Suspense fallback={<Loading />}>
      <CheckoutProgressSteps
        steps   = { steps }
        active  = { active }
      />
    </Suspense>
  }

  return (
    <section
      className={`checkout-process ${forHotelBooking ? "hotels" : "tickets"}`}
      ref={checkoutPageRef}
      >
      { forHotelBooking && checkoutState.useFullscreen && _renderFullscreenButton() }
      { forHotelBooking && checkoutState.step != "complete" && _renderCheckoutSteps() }
      { _renderStep() }
    </section>
  )
}

Checkout.propTypes = {
  useCart: PropTypes.bool,
  forHotelBooking: PropTypes.bool,
  mandatoryBillingAddress: PropTypes.bool,
}

export default Checkout
