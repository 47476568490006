import React, {
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense
} from 'react'
import PropTypes from 'prop-types'
import BackButton from '../../common/buttons/BackButton'
import ContinueButton from '../../common/buttons/ContinueButton'
import {
  CHECKOUT,
  CheckoutContext
} from '../../contexts/Checkout'

import BillingAddressFields from './BillingAddressFields'
import PaymentFields from './PaymentFields'
import Loading from "../../../../vof/common/Loading"
const SubmitButtonHotels = lazy(() => import('../summary/SubmitButtonHotels'))
const SubmitButtonTickets = lazy(() => import('../summary/SubmitButtonTickets'))
const BookingError = lazy(() => import('../../../../vof/modals/BookingError'))
import CcIcons from '../../common/CcIcons'
import Refund from './Refund'
import {
  fieldInputHandler,
  allValuesCheck,
  noErrorsCheck,
  scrollToEcommerce
} from '../../helpers/general'
import { pushBrowserState } from '../../../helpers/historyHelper'

const Billing = ({mandatoryBillingAddress = false, displayRefund = false}) => {
  const {
    checkoutState: {
      billing, shipping, step, promoCode, forHotelBooking, isLoading,
    },
    checkoutUpdate } = useContext(CheckoutContext)
  const _setBilling = (values) => checkoutUpdate({ type: CHECKOUT.BILLING, payload: values})
  const _setStep    = (step) => checkoutUpdate({ type: CHECKOUT.STEP, payload: step})
  const [valid, setValid]   = useState(false)
  const [errors, setErrors] = useState({})
  const [sameAsShipping, setSameAsShipping] = useState(!mandatoryBillingAddress)
  const [submissionErrors, setSubmissionErrors] = useState({})

  const _inputHandler = (e) => {
    let targetObj = (({ value, name, error }) => ({ value, name, error }))(e.currentTarget)
    fieldInputHandler(targetObj, billing, _setBilling, errors, setErrors, nextValueActions)
  }

  const nextValueActions = (nextValues, name, value) => {
    if (name == "cardNumber") {
      nextValues.fields.credit_card_last_four = value.slice(-4)
    }
  }

  const _validate = () => {
    let noErrors = noErrorsCheck(errors)
    let validationFields = {...billing.fields}
    let validationKeys = []
    if (promoCode && promoCode['applied_discount_amount'] && (promoCode['applied_discount_amount'] == 0)) {
      validationKeys = ['street', 'city', 'state', 'zip']
    } else {
      validationKeys = Object.keys(validationFields)
    }

    let hasValues = allValuesCheck(validationKeys, validationFields)

    setValid(noErrors && hasValues)
  }

  useEffect(_validate, [billing, errors])

  const _handleAddressUpdate = (address) => {
    const ship = { ...billing }
    if(typeof address === "string") {
      ship.fields.street = address
    } else {
      Object.keys(address).map(key => {
        ship.fields[key] = address[key]
      })
    }
    _setBilling(ship)
  }

  const _handleShippingToggle = () => {
    setSameAsShipping(!sameAsShipping)

    const nextValues = { ...billing }
    nextValues.sameAsShipping = !sameAsShipping
    _setBilling(nextValues)
  }

  const _handleBackButton = () => {
    if (forHotelBooking) {
      _setStep("guestInformation")
      scrollToEcommerce()
      setTimeout(() => scrollToEcommerce(), 600)

    } else {
      _setStep("cartSummary")
      scrollToEcommerce('.checkout-process.tickets')
    }
  }

  const _handleContinueButton = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let tmp = { ...billing }
    tmp.valid = true
    _setBilling(tmp)
    if (forHotelBooking) {
      scrollToEcommerce()
    } else {
      scrollToEcommerce('.checkout-process.tickets')
    }

    pushBrowserState(
      "Checkout Billing Step Valid",
      {
        billing: tmp,
        shipping: shipping,
        step: "billing",
        promoCode: promoCode,
        forHotelBooking: forHotelBooking
      }
    )

    _setStep("confirm")
    if (forHotelBooking) {
      setTimeout(() => scrollToEcommerce(), 600)

    } else {
      setTimeout(() => scrollToEcommerce('.checkout-process.tickets'), 600)
    }
  }

  const _renderPaymentInformation = () => {
    if (promoCode && promoCode['applied_discount_amount'] && (promoCode['applied_discount_amount'] == 0))
      return null

    return (
      <React.Fragment>
        <h2>Payment Details</h2>
        <p className="small-note">Your payment will be processed using our secure servers and will not leave our site.</p>

        <PaymentFields
          handler={ _inputHandler }
          values={ billing.fields }
          errors={ errors }
          />

        <CcIcons displayCardsOnly />
      { displayRefund && <Refund forHotelBooking={forHotelBooking} /> }
      </React.Fragment>
    )
  }

  return (
    <section className={`billing left-section ${step}`}>
      { isLoading && <Loading /> }
      { submissionErrors.submission &&
        <Suspense fallback={<Loading />}>
          <BookingError
             errors={submissionErrors}
            onDismiss={setSubmissionErrors} />
        </Suspense>
      }

      <form
        className="content"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if(valid) _handleContinueButton(e)
        }}>
        <h2>Billing Address</h2>
        <p className="small-note">Please enter your billing address exactly as it appears on your credit card statement.</p>

        <BillingAddressFields
          handler={ _inputHandler }
          handleAddressUpdate={ _handleAddressUpdate }
          values={billing.fields}
          errors={errors}
        />

        {_renderPaymentInformation()}
      </form>

      {step !== "confirm" && (
        <aside className="actions">
          <BackButton handler={_handleBackButton } />
          <Suspense fallback={<Loading />}>
            {
              forHotelBooking &&
              <SubmitButtonHotels
                valid={valid}
                setSubmissionErrors={setSubmissionErrors}
                classes="checkout-step no-mobile-border btn btn-primary grad blue continue"
              />
            }
            {
              !forHotelBooking &&
              <SubmitButtonTickets
                valid={valid}
                setSubmissionErrors={setSubmissionErrors}
                classes="checkout-step no-mobile-border btn btn-primary grad blue continue"
              />
            }
          </Suspense>
        </aside>
      )}
    </section>
  )

}

Billing.propTypes = {
  displayRefund: PropTypes.bool,
  mandatoryBillingAddress: PropTypes.bool
}

export default Billing
