import React from 'react'
import PropTypes from 'prop-types'
import '../modals/SearchDateError'

import SearchDateFields from '../search/SearchDateFields'

const CtaBarDesktop = ({packages, destination}) => {

  return (
    <div className="cta-bar-desktop animated slideInUp container">
      {
        <SearchDateFields
          nights={packages.length_of_stay_in_nights}
          destination={destination}
          />
      }
    </div>
  )
}

CtaBarDesktop.propTypes = {
  packages: PropTypes.object.isRequired,
  destination: PropTypes.object
}

export default CtaBarDesktop
