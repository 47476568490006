export const DragScroll = (el, options = {}) => {
  var x, y, top, left, down
  var $scrollArea = el
  var body = document.querySelector("body")

  $scrollArea.setAttribute("onselectstart", "return false;")   // Disable text selection in IE8

  $scrollArea.onmousedown = ((e) => {
    doMousedown(e, $scrollArea)
  })

  $scrollArea.onmouseleave = (() => {
    down = false
  })

  body.onmousemove = ((e) => {
    if (down) {
      var newX = e.pageX
      // var newY = e.pageY
      $scrollArea.scrollLeft(left - newX + x)
    }
  })

  body.onmouseup = (() => { down = false })

  let doMousedown = (e, el) => {
    e.preventDefault()
    down = true
    x = e.pageX
    y = e.pageY
    left = el.scrollLeft
  }
}
