import dateFormat from 'dateformat'
import server from '../api/server'
import { getRecheckHotelRates } from '../api/hotel'
import 'whatwg-fetch'
import 'url-polyfill'
import forOwn from 'lodash/forOwn'
import moment from 'moment'

import { SEARCH } from '../search/SearchContext'

import {
  isBookingAgent,
  isBookingAgentAndisBookingAnUpgrade,
  isBookingAgentAndisBookingAfresh,
  isTourOnlyUser
} from './userHelper'

import { trimString } from '../../common/helpers/stringHelper'

const API_URL = `${window.location.protocol}//${window.location.host}`

// HotelBeds provides multiple image sizes
// small: small_size_image_url
// medium: medium_size_image_url
// large: hd_size_image_url
// original_size_image_url
// standard_size_image_url
export const getImageBySize = (image, size) => {
  if (!image) return false

  switch (size) {
    case 'small':
      if (image.hasOwnProperty('small_size_image_url')) {
        return image.small_size_image_url
      }
      break
    case 'medium':
      if (image.hasOwnProperty('medium_size_image_url')) {
        return image.medium_size_image_url
      }
      break
    case 'large':
      if (image.hasOwnProperty('hd_size_image_url')) {
        return image.hd_size_image_url
      }
      break
  }
}

export const getAddress = (address) => {
  let split = address.split(',')
  if (split.length === 1) {
    return trimString(split[0])
  } else if (split.length === 2) {
    return `${trimString(split[1])} ${trimString(split[0])}`
  } else {
    return split.map(i => trimString(i)).join(', ')
  }
}

import NotFoundImage from '../../../../assets/images/voc/graphics/hotel-default-image.svg'

export const getMainImage = (images) => {
  let image = null
  if (images) {
    if (images.length == undefined) {
      // is an Object
      image = getImageBySize(images, 'large')
      if (!image) image = getImageBySize(images, 'medium')
    } else {
      if (images.length == 0) return NotFoundImage
      image = getImageBySize(images[0], 'large')
      if (!image) image = getImageBySize(images[0], 'medium')
    }
  } else {
    return NotFoundImage
  }
  return image
}

export const getRating = (code) => {
  if (['3EST', 'H3_5'].includes(code)) {
    return 3
  } else if (['H4_5', '4EST'].includes(code)) {
    return 4
  } else if (['HIST', '5EST'].includes(code)) {
    return 5
  }
  return 3
}

export const getRoomName = (name) => {
  let lowercase = name.toLowerCase()
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
}

export const getFeaturedAmenities = (amenities) => {
  const allowed = [
    'Late Check-out',
    'Airport Shuttle',
    'Valet parking',
    'Gym',
    'Wi-fi',
    'swimming pool',
    'cafe',
    'escalator',
    'Jacuzzi',
    'Fireplace',
    'Desk',
    'Large pets allowed (over 5 kg)',
    'Small pets allowed (under 5 kg)',
    'Microwave',
    'Library',
    'Balcony',
    'Safe',
    '24-hour security',
    'Secure parking',
    'Connecting rooms',
    'Disability-friendly rooms',
  ]

  let filtered = amenities.filter((f) => allowed.includes(f.description))

  return [...new Set(filtered.map((f) => f.description))]
}

export const scrubHotel = (hotel) => {
  let newState = { ...hotel }
  if (hotel.hotel_type !== "custom_hotel") {
    newState.address = getAddress(hotel.address)
  }
  newState.image = getMainImage(hotel.featured_images || hotel.images)
  newState.rating = getRating(hotel.category_code)
  return newState
}

export const fetchHotels = (payload) => {
  const url = new URL(`${API_URL}/hotel_beds/hotels/availability_by_location`)
  const { filters, sort, roomsData, childrenAges, ...rest } = payload
  forOwn(rest, (value, key) => {
    url.searchParams.append(key, value)
  })

  if (roomsData) {
    roomsData.forEach((room) => {
      url.searchParams.append(`rooms_data[]`, `${room.adults},${room.kids}`)
    })
  }

  if (childrenAges) {
    Object.keys(childrenAges).forEach(k => {
      url.searchParams.append(`children_ages[${k}]`, childrenAges[k].join(','))
    })
  }

  if (filters) {
    forOwn(filters, (value, key) => {
      if (key == 'price') {
        url.searchParams.append(`filters[price][min]`, value['min'])
        url.searchParams.append(`filters[price][max]`, value['max'])
      } else {
        url.searchParams.append(`filters[${key}]`, value)
      }
    })
  }

  if (sort) {
    forOwn(sort, (value, key) => {
      url.searchParams.append(`sort[${key}]`, value)
    })
  }

  return fetch(url).then(async (response) => {
    let data = []
    if (response)
      data = await response.json()

    return {
      status: response.status,
      response: data
    }
  })
}

export const recheckHotelRates = (resource, state, dispatch, hotel) => {
  let isRecheckableHotel = hotel.hotel_type === 'hotel'
  let hasRecheckRates = hotel.room_rates.rooms.filter((room) => room.rates[0].rateType == 'RECHECK').length > 0

  if (!isRecheckableHotel || !hasRecheckRates) {
    return
  }

  let payload = preparePayloadForGetHotels(resource, state, false, 1)
  payload['recheck_hotel_code'] = hotel.code

  if (payload['roomsData']) {
    payload['rooms_data'] = payload['roomsData'].map(room => `${room.adults},${room.kids}`)
  }

  if (payload['childrenAges'] && Object.keys(payload['childrenAges'].length > 0)) {
    let children_ages = {}
    Object.keys(payload['childrenAges']).forEach(k => {
      children_ages[k] = payload['childrenAges'][k].join(',')
    })
    payload['children_ages'] = children_ages
  }

  getRecheckHotelRates(payload).then(response => {
    dispatch({
      type: SEARCH.HOTEL.UPDATE,
      payload: {
        hotel: response.data.hotels,
        updateField: 'room_rates',
      },
    })
  }).finally(() => {
  })
}

export const fetchHotel = (hotel, payload) => {
  if (hotel.hotel_type == 'custom_hotel') {
    return server.get(`/custom_hotels/hotels/${hotel.id}`, { params: payload })
  } else {
    return server.get(`/hotel_beds/hotels/${hotel.id}`, { params: payload })
  }
}

export const fetchRatesForHotel = (cacheId, hotel) => {
  let params = {
    cache_id: cacheId,
    hotel_id: hotel.id,
    search_id: hotel.room_rates.search_id,
  }
  return server.get(`/hotel_beds/hotels/availability.json`, { params: params })
}

export const fetchBooking = (payload) => {
  const url = new URL(`${API_URL}/bookings/${payload.id}`)
  Object.keys(payload).forEach((key) =>
    url.searchParams.append(key, payload[key])
  )

  return fetch(url).then((response) => (response.ok ? response.json() : {}))
}

export const fetchRateComments = (payload) => {
  let uri = `?checkin_date=${payload.checkin_date}&rate_comments_id=${
    payload.rate_comments_id
  }`
  const url = new URL(`${API_URL}/hotel_beds/rates_comments${uri}`)
  // Use below if we need to url encode
  // const url = new URL(`${API_URL}/hotel_beds/rates_comments?`)
  // Object.keys(payload).forEach(key => url.searchParams.append(key, payload[key]))

  return fetch(url).then((response) => (response.ok ? response.json() : {}))
}

export const postBooking = (
  { values, booking, dates },
  isPrePay,
  isTourOperator,
  useExistingCard,
  saveCard,
  addSurchargeToCreditLine,
  chargePaymentToCreditLine,
  upgradeType,
  tourOperatorNotes,
  searchState
) => {

  let body = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    phone: values.phone,
    hotel_code: booking.hotel.code,
    hotel_type: booking.hotel.hotel_type,
    surcharge: booking.room.rates[booking.rate].surcharge,
    package_price: booking.room.rates[booking.rate].net,
    pre_markup_price: booking.room.rates[booking.rate].original_net,
    cancellation_policies: booking.room.rates[booking.rate].cancellationPolicies,
    hotel_supplier: booking.room.rates[booking.rate]['hotel_supplier'],
    room_type: `${booking.room.name}(${booking.room.rates[booking.rate].boardCode})`,
    arrival: dates.arrival.format('YYYY-MM-DD'),
    departure: moment(dates.arrival).add(dates.nights, "days").format('YYYY-MM-DD'),
    action: booking.action,
    add_surcharge_to_credit_line: addSurchargeToCreditLine,
    charge_payment_to_credit_line: chargePaymentToCreditLine,
    tour_operator_notes: tourOperatorNotes,
    amount_of_adults: searchState.searchedAdults,
    amount_of_children: searchState.searchedChildren,
    children_ages: searchState.searchedChildrenAges,
    num_rooms: searchState.searchedRooms,
    room_occupancy_details: searchState.roomsData
  }

  if (isTourOperator || isBookingAgent(searchState.user) || isTourOnlyUser(searchState.user)) {
    if (isBookingAgentAndisBookingAnUpgrade(searchState)) {
      body = {
        ...body,
        zoho_lead_id: searchState.leadId,
        upgrade_type: upgradeType
      }
    }

    if (
      isPrePay ||
      (body.surcharge && !addSurchargeToCreditLine && (isTourOperator || isBookingAgentAndisBookingAnUpgrade(searchState))) ||
      (!chargePaymentToCreditLine && isBookingAgentAndisBookingAfresh(searchState))
    ) {
      let paymentDetails = {
        credit_card_name: values.cardName,
        credit_card_number: values.cardNumber,
        expiration_date: values.expiryDate,
        cvv: values.cvc,
        billing_street: values.billingStreet,
        billing_city: values.billingCity,
        billing_state: values.billingState,
        billing_zip_code: values.billingZipCode,
        use_existing_card: false,
      }
      body = { ...body, ...paymentDetails }
    }

    if (booking.action === 'book_room_and_tour') {
      let tourDetails = {
        billing_street: values.billingStreet,
        billing_city: values.billingCity,
        billing_state: values.billingState,
        billing_zip_code: values.billingZipCode,
        spouse_first_name: values.spouseFirstName,
        spouse_last_name: values.spouseLastName,
        qualifying_question_id: searchState.selectedQualifyingQuestion,
      }

      body = { ...body, ...tourDetails }
    }
  } else {
    if (isPrePay) {
      if (useExistingCard) {
        body = { ...body, cvv: values.cvc, use_existing_card: true }
      } else {
        let paymentDetails = {
          credit_card_name: values.cardName,
          credit_card_number: values.cardNumber,
          expiration_date: values.expiryDate,
          cvv: values.cvc,
          billing_street: values.billingStreet,
          billing_city: values.billingCity,
          billing_state: values.billingState,
          billing_zip_code: values.billingZipCode,
          use_existing_card: false,
          save_card: saveCard,
        }
        body = { ...body, ...paymentDetails }
      }
    }
  }

  return server.post('/bookings', { booking: body })
}

export const serverErrorHelper = (error) => {
  if(error === "Error retrieving data from HotelBeds")
    return "Error retrieving hotel data"
  else
    return "Error retrieving data"
}

export const getDepartureDate = (arrivalDate, days, format = 'mm/dd/yyyy') => {
  let departureDate = new Date(arrivalDate.valueOf())
  departureDate.setDate(departureDate.getDate() + parseInt(days))
  return dateFormat(
    departureDate,
    format
  )
}

export const isAllInclusiveRoom = (room) => (
  ['AI', 'AS', 'FB', 'FH', 'PB', 'MB', 'CE', 'TL'].includes(room.rates[0].boardCode)
)

export const ALL_INCLUSIVE_VERBIAGE = {
  'AI': 'All Inclusive',
  'AS': 'All Inclusive Premium',
  'FB': 'Full Board',
  'FH': '1 Half Board + 1 Full Board',
  'PB': 'Full Board Beverages Included',
  'MB': 'Half Board with Beverages Includd',
  'CE': 'Dinner Included',
  'TL': 'All Inclusive Soft',
}

export const getAllInclusiveVerbiageForRoom = (room) => (
  ALL_INCLUSIVE_VERBIAGE[room.rates[0].boardCode]
)

export const getDepartureDateMoment = (arrivalDate, days) => {
  let departureDate = moment(arrivalDate).add(days, "days")
  return departureDate.format('YYYY/MM/DD')
}

export const preparePayloadForGetHotels = (resource, state, withFilters = false, pageNum = 1) => {
  const payload = {
    resource_type: resource.type,
    resource_id: resource.id,
    checkin_date: state.arrival.format("YYYY-MM-DD"),
    today: new Date(),
    num_days: state.nights,
    num_adults: state.adults,
    num_children: state.children,
    rooms: state.rooms,
    include: "featured_images,tags",
    page: pageNum
  }

  if (Object.keys(state.childrenAges).length > 0) {
    payload['childrenAges'] = state.childrenAges
  }

  if (isBookingAgent(state.user) && state.leadId) {
    payload['zoho_lead_id'] = state.leadId
  }
  if (state.rooms > 1) {
    payload['roomsData'] = state.roomsData
  }

  if (withFilters) {
    let filterData = state.filtersForSearch ? state.filtersForSearch : state.filters

    let filters = {
      hotel_name: filterData.hotelName,
      star_rating: filterData.starRating,
    }
    if (filterData.priceRange && (
      filterData.priceRange.min != state.defaultFilterValues.priceRange.min ||
      filterData.priceRange.max != state.defaultFilterValues.priceRange.max
    )) {
      filters['price'] = {
        min: filterData.priceRange.min,
        max: filterData.priceRange.max
      }
    }
    if (!filterData.nonRefundable) {
      filters['refundable_rate'] = true
    }
    payload['filters'] = filters

    if (state.sort) {
      let sort = undefined
      if (state.sort == "price-low-high") {
        sort = { by: 'price', order: 'asc' }
      } else if (state.sort == 'price-high-low') {
        sort = { by: 'price', order: 'desc' }
      } else if (state.sort == 'a-z') {
        sort = { by: 'name', order: 'asc' }
      } else if (state.sort == 'z-a') {
        sort = { by: 'name', order: 'desc' }
      }

      payload['sort'] = sort
    }
  }

  return payload
}

export const fetchAvailabilityNotices = (resourceType, resourceId, from, to) => {
  const url = new URL(`${API_URL}/availability_notices.json`)
  url.searchParams.append('resource_type', resourceType)
  url.searchParams.append('resource_id', resourceId)
  url.searchParams.append('from', from)
  url.searchParams.append('to', to)

  return fetch(url).then(async (response) => {
    let data = []
    if (response) {
      data = await response.json()
    }

    return {
      status: response.status,
      response: data
    }
  })
}


const getHotels = async (dispatch, payload, displaySearchMessage, deactivateLoading = false) => {
  const _setLoading = (payload) =>
    dispatch({ type: SEARCH.HOTELS.LOADING, payload: payload })
  const _setServerErrors = (payload) =>
    dispatch({ type: SEARCH.SERVER_ERRORS.SET, payload })
  const _setCurrentHotels = (payload) =>
    dispatch({ type: SEARCH.CURRENT_HOTELS.SET, payload })
  const _setVisibleHotels = (payload) =>
    dispatch({ type: SEARCH.VISIBLE_HOTELS.SET, payload })
  const _setHotels = (payload) => dispatch({ type: SEARCH.HOTELS.SET, payload })
  const _setAvailabilityNotices = (availabilityNotices) => dispatch({ type: SEARCH.AVAILABILITY_NOTICES.SET, payload: availabilityNotices })


  if (!payload.resource_type || !payload.resource_id) return

  _setLoading(true)
  _setAvailabilityNotices([])

  dispatch({ type: SEARCH.CACHE_SEARCH_TERMS.SET })

  if (displaySearchMessage)
    dispatch({ type: SEARCH.DISPLAY_SEARCH_MESSAGE.SET, payload: false })

  const response = await fetchHotels(payload)
  if (response.status == 202) {
    setTimeout(() => getHotels(dispatch, payload, displaySearchMessage, deactivateLoading), 750)
    return false
  }

  const availabilityResponse = await fetchAvailabilityNotices(
    payload.resource_type,
    payload.resource_id,
    moment(payload.checkin_date).format("YYYY-MM-DD"),
    moment(payload.checkin_date).add(payload.num_days, 'days').format("YYYY-MM-DD")
  )

  _setAvailabilityNotices(availabilityResponse.response.availability_notices)

  const data = response.response

  dispatch({ type: SEARCH.FILTERS_FOR_SEARCH.SET, payload: undefined })
  if (data) {

    // Hotels array exists and not empty
    if ("hotels" in data) {
      if (data.hotels.length) {
        const scrubbed = data.hotels
          .filter(
            (d) =>
            d.address &&
            d.city &&
            d.postal_code &&
            !isNaN(d.latitude) &&
            !isNaN(d.longitude),
          )
          .map(scrubHotel)

        _setServerErrors(null)

        dispatch({ type: SEARCH.HOTEL.SET, payload: null })
        dispatch({ type: SEARCH.BOOKING.SET, payload: null })
        _setCurrentHotels(scrubbed)
        _setVisibleHotels(scrubbed)
        _setHotels(scrubbed)

        if (deactivateLoading) _setLoading(false)

      } else {
        // No hotels
        _setLoading(false)
        _setAvailabilityNotices([])
        _setHotels([])
      }

      dispatch({ type: SEARCH.META, payload: data.meta })

    } else {
      // errors
      _setServerErrors(data)
      _setLoading(false)
    }
  } else {
    // no response
    _setServerErrors(["Server timeout"])
    _setLoading(false)
  }

  dispatch({ type: SEARCH.INITIATE_PAGE_CHANGE, payload: false })
}

export { getHotels }
