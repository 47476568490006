import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { Button } from '../../ui'
import {
  CHECKOUT,
  CheckoutContext
} from '../../contexts/Checkout'


const ShippingMethodItem = ({method, selectable}) => {
  const { checkoutState: {shipping}, checkoutUpdate } = useContext(CheckoutContext)
  const isSelected = method.service == shipping.method.service

  const _handleSelect = () => {
    let tmp = {...shipping}
    tmp.method = method
    checkoutUpdate({type: CHECKOUT.SHIPPING, payload: tmp})
  }

  return (
    <div className="shipping-method-item">
      <h4>{method.service}</h4>
      <p className="price">
        Price: {method.price}<br/>
        Delivery Date: {dateFormat(method.delivery_date, "dddd, mmmm dS, yyyy")}
        </p>
      {
        selectable && <Button
          clickHandler={_handleSelect}
          className={`btn grad small ${isSelected ? 'orange' : 'blue'}`}
          >
          { isSelected ? "Selected" : "Select" }
        </Button>
      }
    </div>
  )

}

ShippingMethodItem.propTypes = {
  method: PropTypes.object.isRequired,
  selectable: PropTypes.bool.isRequired
}


export default ShippingMethodItem
