import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'

import SelectField from "../../../common/forms/Select"
import { escapeRegexCharacters } from '../../../common/helpers/filtersHelper'

import { PRODUCTS, ProductContext } from "../contexts/Product"

const GROUPS = [
  {
    label: 'Shows',
    value: 'Show'
  },
  {
    label: 'Attractions',
    value: 'Attraction'
  },
  {
    label: 'Food',
    value: 'Food'
  }
]

const filterOptions = (candidate, input) => {
  const escapedValue = escapeRegexCharacters(input.trim())
  const regex = new RegExp('\\b' + escapedValue, 'i')
  if (input) {
    return regex.test(candidate.value)
  }
  return true
}

/*
  Group Filter
  param: reloadDefaultOptions
  use if need to reload default options
*/

const GroupFilter = ({reloadDefaultOptions}) => {
  const { state, dispatch } = useContext(ProductContext)
  const [options] = useState(GROUPS)
  const [render, setRender] = useState(true)
  const [defaults, setDefaults] = useState(state.filters.groups)

  const _setGroups = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.GROUPS, payload })

  const _handleSelectChange = (selected, action) => {
    let value = action.action == "clear" ? null : selected
    _setGroups(value)
  }

  useEffect(()=> {
    // Forcing reload to update default options
    if(reloadDefaultOptions === true) {
      setDefaults(state.filters.groups)
      setRender(false)
      setTimeout(() => setRender(true), 100)
    }
  }, [reloadDefaultOptions])


  return (
    render && (
      <SelectField
        className     = "multi-select select-field groups"
        placeholder   = "Categories"
        options       = {options}
        defaultValue  = {defaults}
        handleChange  = {_handleSelectChange}
        filterOptions = {filterOptions}
        isMulti
      />
    )
  )
}

GroupFilter.propTypes = {
  reloadDefaultOptions: PropTypes.bool
}

export default GroupFilter
