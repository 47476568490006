import React from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByAddress
} from 'react-places-autocomplete'

const AddressAutocomplete = ({placeholder, name, handleUpdate, value = null}) => {
  const [address, setAddress] = React.useState(value || "")

  React.useEffect(() => {
    if(value != null)
      setAddress(value)
  },[value])

  const _handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        let a = _parseAddress(results)
        setAddress(a.street)
        handleUpdate(a)
      })
  }

  const _parseAddress = results => {
    if(results) {
      let address = {
        street: '',
        city: '',
        state: '',
        zip: ''
      }

      results[0].address_components.forEach(item => {
        item.types.map(t => {
          if(t == "postal_code") {
            address.zip = item.short_name
          }
          if(t == "administrative_area_level_1") {
            address.state = item.short_name
          }
          if(t == "locality") {
            address.city = item.short_name
          }
        })
      })

      let fullAddress = results[0].formatted_address.split(",")
      for(var s in fullAddress) {
        if(fullAddress[s].trim() === address.city) {
          break;
        } else {
          address.street += fullAddress[s]
        }
      }
      return address
    }
  }

  const _handleChange = address => {
    setAddress(address)
    handleUpdate(address)
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={_handleChange}
      onSelect={_handleSelect}
      shouldFetchSuggestions={address.length > 4}
      >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="address-autocomplete">
          <input
            {...getInputProps({
              placeholder: `${placeholder ? placeholder : 'Search...'}`,
              name: `${name ? name : ""}`,
              autoComplete: "off"
            })}
            />
          <div className="interactive rows results">
            {loading && <div className="loading">Loading...</div>}
            {suggestions.map((suggestion, index) => (
              <div key={"suggestion-" + index} {...getSuggestionItemProps(suggestion, { className: 'result' })}>
                {suggestion.description}
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

AddressAutocomplete.propTypes = {
  handleUpdate: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
}

export default AddressAutocomplete
