import React from 'react'
import PropTypes from 'prop-types'

import {
  ButtonBuyNow,
  ButtonAddToCartSeating
} from '../ecommerce/products/Buttons'

const SeatingFooter = (props) => {
  const {
    quantityTotal,
    priceTotal,
    handleAddToCart,
    handleBuyNow
  } = props

  return (
    <footer className="action-bar">
      <p className="price">
        <em>{quantityTotal} Seats Selected</em>
        <span>${parseFloat(priceTotal).toFixed(2)}</span>
      </p>
      <div className="buttons">
        <ButtonAddToCartSeating
          handler={handleAddToCart}
          />
        <ButtonBuyNow
          handler={handleBuyNow}
          />
      </div>
    </footer>
  )
}

SeatingFooter.propTypes = {
  quantityTotal: PropTypes.number,
  priceTotal: PropTypes.number,
  handleAddToCart: PropTypes.func.isRequired,
  handleBuyNow: PropTypes.func.isRequired
}

export default SeatingFooter