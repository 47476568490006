import React from "react"
import PropTypes from "prop-types"
import 'react-dates/initialize'
import momentPropTypes from 'react-moment-proptypes'
import DateRangePicker from 'react-dates/lib/components/DateRangePicker'
import { isMobile } from 'react-device-detect'
import 'react-dates/lib/css/_datepicker.css'

const DateFieldRange = ({
  className,
  startDate,
  startDateId,
  endDate,
  endDateId,
  onDatesChange,
  focusedInput,
  onFocusChange,
  minimumNights,
  startDatePlaceholderText,
  endDatePlaceholderText,
  numberOfMonths,
  keepOpenOnDateSelect,
  hideKeyboardShortcutsPanel,
  readOnly,
  ...props
}) => {

  const _getNumberOfMonths = () => isMobile ? 1 : 2

  const numberMonths = numberOfMonths || _getNumberOfMonths()

  return (
    <DateRangePicker
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId={startDateId} // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId={endDateId} // PropTypes.string.isRequired,
      onDatesChange={onDatesChange} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={onFocusChange} // PropTypes.func.isRequired,
      minimumNights={minimumNights}
      startDatePlaceholderText={startDatePlaceholderText}
      endDatePlaceholderText={endDatePlaceholderText}
      keepOpenOnDateSelect={keepOpenOnDateSelect}
      numberOfMonths={numberMonths}
      hideKeyboardShortcutsPanel={hideKeyboardShortcutsPanel}
      readOnly={readOnly}
      transitionDuration={0}
      noBorder
      {...props}
      />
  )
}

DateFieldRange.defaultProps = {
  startDate: null,
  startDateId: 'arrival',
  endDate: null,
  endDateId: 'departure',
  focusedInput: 'startDate',
  minimumNights: 1,
  startDatePlaceholderText: "Select Date",
  endDatePlaceholderText: "Select Date",
  keepOpenOnDateSelect: false,
  hideKeyboardShortcutsPanel: true,
  readOnly: true
}

DateFieldRange.propTypes = {
  className: PropTypes.string,
  startDate: momentPropTypes.momentObj,
  startDateId: PropTypes.string,
  endDate: momentPropTypes.momentObj,
  endDateId: PropTypes.string,
  onDatesChange: PropTypes.func.isRequired,
  focusedInput: PropTypes.string,
  onFocusChange: PropTypes.func,
  minimumNights: PropTypes.number,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  keepOpenOnDateSelect: PropTypes.bool
}

export default DateFieldRange
