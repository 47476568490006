import React, { useState, useContext, useEffect } from 'react'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'

import DestinationField from "../../../common/forms/DestinationField"

import { PRODUCTS, ProductContext } from "../contexts/Product"

const DestinationFilter = () => {
  const { state, dispatch } = useContext(ProductContext)
  const [options, setOptions] = useState([])

  const _setDestination = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.DESTINATION, payload })

  const _handleDestinationChange = (selected, action) => {
    let value = action.action == "clear" ? '' : selected
    _setDestination(value)
  }

  useEffect(() => {
    let destinations = uniq(compact(state.products.map((product) => product['destination_name'])))
    setOptions(destinations.sort().map((dest) => {
      return { value: dest, label: dest }
    }))
  }, [state.products])

  return (
    <DestinationField
      className="select-field"
      options={options}
      value={state.filters.destination}
      handleChange={_handleDestinationChange}
    />
  )
}

export default DestinationFilter
