import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { CART, CartContext } from '../contexts/Cart'
import { CHECKOUT, CheckoutContext } from '../contexts/Checkout'
import { MProductItem } from '../types/ProductItem'
import { updateChildValue } from '../helpers/cartHelper'
import Button from '../ui/Button'
import { timeString12hr } from '../../helpers/seating'

const CartItem = ({product, productIndex, calcCartTotal}) => {
  const { cartState: {items}, cartUpdate }  = useContext(CartContext)
  const { checkoutState, checkoutUpdate }   = useContext(CheckoutContext)
  const _setItems = items   => cartUpdate({type: CART.ITEMS, payload: items})
  const _setActive = active => cartUpdate({type: CART.ACTIVE, payload: active})

  const _handleAmountChange = (e, id) => {
    let value = e.currentTarget.value,
        products = [...items]
    product.amount = value
    products = updateChildValue(products, id, "amount", value)
    _setItems(products)
  }


  const _handleRemoveItemByIndex = (index) => {

    let products = [...items]
    products.splice(index, 1)
    _setItems(products)

    if(products.length === 0) {
      _setActive(false)
      if(checkoutState.active) {
        checkoutUpdate({type: CHECKOUT.ACTIVE, payload: false})
      }
    }
  }

  const _handleRemoveShowtime = (productIndex, categoryIndex) => {
    let tmpItems = [...items]
    let current_product = tmpItems[productIndex]
    current_product.showtimes.items.splice(categoryIndex, 1)

    if(current_product.showtimes.items.length <= 0) {
      // if no more shows, remove base ticket
      _handleRemoveItemByIndex(productIndex)
    } else {
      current_product.showtimes = _calcShowtimePriceQuantity(current_product.showtimes)
      tmpItems[productIndex] = current_product
      _setItems(tmpItems)
      calcCartTotal() // trigger cart total recalculation
    }
  }

  const _calcShowtimePriceQuantity = (showtimes) => {
    let totalPrice = 0, totalQuantity = 0
    showtimes.items.forEach(item => {
      totalPrice += parseInt(item.quantity) * item.price
      totalQuantity += parseInt(item.quantity)
    })

    showtimes.quantity = totalQuantity
    showtimes.price = totalPrice
    return showtimes
  }

  const _calcPrice = () => "showtimes" in product
    ? parseFloat(product.showtimes.price).toFixed(2)
    : (parseFloat(product.price).toFixed(2) * parseFloat(product.amount)).toFixed(2)

  let image = "image" in product ? product.image : product.image_url

  const _seatTotalPrice = (seat) => (parseFloat(seat.price).toFixed(2) * parseFloat(seat.quantity)).toFixed(2)

  return (
    <Fragment>
      <div className="product-cart-item">
        <figure>
          <img src={image} alt={product.title} />
        </figure>
        <h4 className="item-title">{product.title}</h4>
        <p className="item-price">${_calcPrice()}</p>
        {
          !product.showtimes &&
          <input
            className="item-amount"
            type="number"
            min="1"
            step="1"
            value={product.amount}
            onChange={(e) => _handleAmountChange(e, product.id)}
            />
        }
        <Button
          className="remove-cart-item"
          clickHandler={() => _handleRemoveItemByIndex(productIndex)}
          >
          X
        </Button>
      </div>

      {
        "showtimes" in product &&
        <aside className="showtimes">
          <h6 className="date seats">
            {product.showtimes.quantity} Seats for {product.showtimes.date}
          </h6>
          {
            product.showtimes.items.length &&
              <div className={`categories ${product.showtimes.items.length == 1 ? 'single' : ''}`}>
              {product.showtimes.items.map((seat, i) => (
                <p key={i}>
                  <span className="name">{seat.name}</span>
                  <span className="show-time">@ {timeString12hr(seat.show_time)}</span>
                  <span className="quantity">{seat.quantity}x&nbsp;</span>
                  <span className="price">${seat.price} =</span>
                  <span className="total-price">${_seatTotalPrice(seat)}</span>

                  {
                    product.showtimes.items.length > 1 &&
                    <Button
                      className="remove-cart-item"
                      clickHandler={() => _handleRemoveShowtime(productIndex, i)}
                      >
                      X
                    </Button>
                  }
                </p>
              ))}
            </div>
          }

        </aside>
      }
    </Fragment>
  )
}

CartItem.propTypes = {
  product: MProductItem,
  productIndex: PropTypes.number.isRequired,
  calcCartTotal: PropTypes.func.isRequired
}

export default CartItem
