import React, { useState, useContext } from 'react'
import Autosuggest from 'react-autosuggest'
import { isMobileOnly } from 'react-device-detect'

import { PRODUCTS, ProductContext } from "../contexts/Product"

// Implement this if we want a dropdown of results
const getSuggestionValue = (suggestion) => {
  return `${suggestion.title}`
}

const FilterProducts = () => {
  const { state, dispatch } = useContext(ProductContext)
  const _setSearch = (payload) =>
    dispatch({ type: PRODUCTS.FILTERS.SEARCH, payload })

  const [suggestions, setSuggestions] = useState([])
  // const [value, setValue] = useState("")

  const onChange = (event, { newValue }) => {
    _setSearch(newValue)
  }

  const inputProps = {
    placeholder: isMobileOnly ? "Search Tickets by name, details..." : "Search Tickets",
    value: state.filters.search,
    onChange: (e, newValue) => onChange(e, newValue),
  }

  const clearSuggestions = () => setSuggestions([])

  const fetchSuggestion = () => {}

  // Implement this if we want a dropdown of results
  const renderSuggestion = suggestion => {
    return `${suggestion.title}`
  }

  return (
    <div className="products-filter-bar">
      <label className="fuzzy">
        {state.filtered && state.filtered.length > 0 && (
          <i className="count">
            <span>{state.filtered.length}</span>
          </i>
        )}

        <Autosuggest
          className="products-filter autosuggest"
          suggestions={suggestions}
          onSuggestionsFetchRequested={fetchSuggestion}
          onSuggestionsClearRequested={clearSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </label>
    </div>
  )
}

export default FilterProducts
