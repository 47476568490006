const API_URL = `${window.location.protocol}//${window.location.host}`

export const fetchCurrentUser = () => {
  const url = new URL(`${API_URL}/users`)
  return fetch(url).then((response) => (response.ok ? response.json() : {}))
}

export const isPrePayUser = (user) => {
  return user && user.payment_type === 'pre_pay'
}

export const isCreditLimitUser = (user) => {
  return user && user.payment_type === 'credit_limit'
}

export const isUser = (user) => {
  return user && user.role == 'user'
}

export const isTravelAgent = (user) => {
  return user && user.role == 'travel_agent'
}

export const isTourOperator = (user) => {
  return user && user.role == 'tour_operator'
}

export const isBookingAgent = (user) => {
  return user && user.role == 'booking_agent'
}

export const isNonBookingUser = (user) => (
  user && user.role == 'non_booking'
)

export const isTourOnlyUser = (user) => (
  user && user.role == 'tour_only'
)

export const isSearchingAIRates = (state) => (
  state.searchBy.country && state.searchBy.country !== 'US' &&
    state.children > 0
)

export const shouldShowAdvanced = (state) => (
  isBookingAgent(state.user) ||
    isSearchingAIRates(state)
)

export const canViewNonRefundableRates = (user) => {
  return user && user['view_non_refundable_rates?']
}

export const showSurcharge = (searchState) => {
  return isNonBookingUser(searchState.user) || isBookingAgentAndisBookingAnUpgrade(searchState)
}

export const isBookingAgentAndisBookingAnUpgrade = (searchState) => {
  return isBookingAgent(searchState.user) && searchState.zohoLead
}

export const isBookingAgentAndisBookingAfresh = (searchState) => {
  return isBookingAgent(searchState.user) && !searchState.zohoLead
}

export const shouldRenderBookNow = (searchState) => (
  isUser(searchState.user) ||
    isTravelAgent(searchState.user) ||
    isTourOperator(searchState.user) ||
    isBookingAgentAndisBookingAfresh(searchState)
)

export const shouldRenderBookRoomsAndTour = (searchState) => (
  searchState.qualifyingQuestions.length > 0 && (
    isTourOperator(searchState.user) ||
      isBookingAgent(searchState.user) ||
      isTourOnlyUser(searchState.user)
  )
)

export const shouldFetchQualifyingQuestions = (user) => (
 isTourOperator(user) || isBookingAgent(user) || isTourOnlyUser(user)
)

export const canChargeUpgradeToCreditLimit = (searchState) => {
  return (
    isCreditLimitUser(searchState.user) &&
    (isTourOperator(searchState.user) || isBookingAgentAndisBookingAnUpgrade(searchState)) &&
    (searchState.user.enable_charge_upgrade_to_credit_limit_for_tour_operators)
  )
}

export const creditLimitUserHasNoSurcharge = (state, room) => {
  return ((isTourOperator(state.user) || isBookingAgentAndisBookingAnUpgrade(state)) && isCreditLimitUser(state.user) && !room.rates[0].surcharge)
}

export const isNotPriceApplicableUser = (user) => {
  return isNonBookingUser(user) || isTourOnlyUser(user)
}

export const userTypeSupportsSurcharge = (searchState) => {
  return ((isTourOperator(searchState.user) || isBookingAgent(searchState.user)) && isCreditLimitUser(searchState.user)) || isBookingAgentAndisBookingAnUpgrade(searchState) || isNonBookingUser(searchState.user)
}

// Tour Operator - Display if Surcharge is present
// Booking Agent -
//    1. If Lead exists - Display if surcharge present
//    2. If No Lead - Always Display Payment Fields
export const shouldDisplayPaymentsForTourOperatorOrBookingAgent = (searchState) => {
  let displayForTourOperator = isTourOperator(searchState.user) &&
    searchState.booking.room.rates[0].surcharge
  let displayForBookingAgent = isBookingAgent(searchState.user) &&
    searchState.booking.room.rates[0].surcharge

  return isCreditLimitUser(searchState.user) && (
    displayForTourOperator || displayForBookingAgent ||
      isBookingAgentAndisBookingAfresh(searchState)
  )
}

export const shouldDisplayAddressFields = (searchState, useExistingCard) => {
  const bookingRoomAndTourWithoutSurcharge = (searchState.booking.action == 'book_room_and_tour') &&
    creditLimitUserHasNoSurcharge(searchState, searchState.booking.room)

  if (bookingRoomAndTourWithoutSurcharge)
    return true

  const displayPayments = (isPrePayUser(searchState.user) || shouldDisplayPaymentsForTourOperatorOrBookingAgent(searchState))
  if (displayPayments) {
    if (isTourOperator(searchState.user) || isBookingAgent(searchState.user)) {
      return true
    } else {
      if (searchState.user.credit_card_last_four && useExistingCard) {
        return false
      } else {
        return true
      }
    }
  }

  return false
}

