import React from "react"
import PropTypes from "prop-types"
import Glider from 'react-glider'
import ReactMarkdown from 'react-markdown'
import { isSmallScreen } from '../helpers/general'

const Faqs = ({faqs}) => {

  const _renderMarkdownText = (text) => <ReactMarkdown source={text} />

  const _displayFaqDetails = faqItems => {
    let mappedFaqs = faqItems.map( (faq, i) => {
      return (
        <div className="faq" key={`faq-${i}`}>
          <h3 dangerouslySetInnerHTML={{__html: faq.question}} />
          <aside>
            <p dangerouslySetInnerHTML={{__html: faq.answer}} />
          </aside>
        </div>
      )
    })
    return <div className="faq-column"><div className="faq-inner">{ mappedFaqs }</div></div>
  }

  const _splitFaqs = () => {
    if(faqs.length < 2) {
      return _displayFaqDetails(faqs)
    }
    let size = Math.ceil(faqs.length / 2)
    let left = faqs.slice(0, size)
    let right = faqs.slice(size, faqs.length)

    return (
      <React.Fragment>
        {_displayFaqDetails(left)}
        {_displayFaqDetails(right)}
      </React.Fragment>
    )
  }

  const _renderFaqs = () => {
    if(isSmallScreen()) {
      return (
        <Glider
          slidesToScroll={1}
          slidesToShow={1.3}
          draggable={true}
          scrollLock={true}
          >
          { _splitFaqs() }
        </Glider>
      )
    } else {
      return <div className="faq-split">{ _splitFaqs() }</div>
    }
  }

  return (
    <React.Fragment>
      { faqs && _renderFaqs() }
    </React.Fragment>
  )
}

Faqs.propTypes = {
  faqs: PropTypes.array.isRequired,
}
export default Faqs
