// Update single object value by key
// Return: updated items
// items: array of objects
// id: (string or num) main identifier
// key: (string) key to update
// value: (any)
export const updateChildValue = (items, id, key, value) => {
  return items.map(p =>
    p.id === id
      ? { ...p, [key]: value }
      : p
  )
}
