import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import moment from 'moment'

export const initialSearchState = {
  arrival: moment(),
  nights: 1,
  location: {
    address: null,
    latitude: null,
    longitude: null,
  },
  searchBy: {
    type: null,
    id: null,
    label: null,
    country: null,
  },
  layout: isMobile ? 'list' : 'grid',
  hotels: [],
  availabilityNotices: [],
  pages: 0,
  currentPage: 0,
  numPerPage: 24,
  currentHotels: [],
  visibleHotels: [],
  hotel: null,
  room: null,
  rate: 0,
  booking: null,
  rooms: 1,
  adults: 1,
  children: 0,
  childrenAges: {},
  leadId: "",
  isLoading: false,
  showExtraFields: false,
  user: null,
  mapOpen: isMobile ? false : true,
  popUpOpen: false,
  serverErrors: null,
  hoveredHotel: null,
  qualifyingQuestions: [],
  selectedQualifyingQuestion: null,
  displaySearchMessage: true,
  meta: null,
  zohoLead: null,
  roomsData: [],
  filtersOpen: false,
  filtersForSearch: undefined,
  filters: {
    hotelName: '',
    starRating: 0,
    priceRange: {
      min: 0,
      max: 500
    },
    nonRefundable: true,
  },
  defaultFilterValues: {
    hotelName: '',
    starRating: 0,
    priceRange: {
      min: 0,
      max: 1000
    },
    nonRefundable: true
  },
  lastFilterSearch: {},
  sort: "preferred",
  initiateFilterSearch: {value: false},
  initiatePageChange: false,
  searchedArrival: null,
  searchedNights: null,
  searchedAdults: null,
  searchedChildren: null,
  searchedChildrenAges: {},
  searchedRooms: null
}

export const SEARCH = {
  ALL: 'SEARCH/ALL',
  ARRIVAL: {
    SET: 'SEARCH/ARRIVAL/SET',
  },
  NIGHTS: {
    SET: 'SEARCH/NIGHTS/SET',
  },
  LOCATION: {
    SET: 'SEARCH/LOCATION/SET',
  },
  SEARCH_BY: {
    SET: 'SEARCH/SEARCH_BY/SET',
  },
  ROOMS: {
    SET: 'SEARCH/ROOMS/SET'
  },
  ADULTS: {
    SET: 'SEARCH/ADULTS/SET',
  },
  CHILDREN: {
    SET: 'SEARCH/CHILDREN/SET',
  },
  CHILDREN_AGES: {
    SET: 'SEARCH/CHILDREN_AGES/SET',
  },
  LEAD_ID: {
    SET: 'SEARCH/LEAD_ID/SET',
  },
  ZOHO_LEAD: {
    SET: 'SEARCH/ZOHO_LEAD/SET',
  },
  HOTELS: {
    LOADING: 'SEARCH/HOTELS/LOADING',
    SET: 'SEARCH/HOTELS/SET',
  },
  AVAILABILITY_NOTICES: {
    LOADING: 'SEARCH/AVAILABILITY_NOTICES/LOADING',
    SET: 'SEARCH/AVAILABILITY_NOTICES/SET',
  },
  PAGES: 'SEARCH/PAGES',
  CURRENT_PAGE: 'SEARCH/CURRENT_PAGE',
  NUM_PER_PAGE: 'SEARCH/NUM_PER_PAGE',
  CURRENT_HOTELS: {
    SET: 'SEARCH/CURRENT_HOTELS/SET'
  },
  VISIBLE_HOTELS: {
    SET: 'SEARCH/VISIBLE_HOTELS/SET',
  },
  HOTEL: {
    SET: 'SEARCH/HOTEL/SET',
    UPDATE: 'SEARCH/HOTEL/UPDATE',
  },
  QUALIFYING_QUESTIONS: {
    SET: 'SEARCH/QUALIFYING_QUESTIONS/SET',
  },
  SELECTED_QUALIFYING_QUESTIONS: {
    SET: 'SEARCH/SELECTED_QUALIFYING_QUESTIONS/SET',
  },
  LAYOUT: {
    SET: 'SEARCH/LAYOUT/SET',
  },
  ROOM: {
    SET: 'SEARCH/ROOM/SET',
  },
  RATE: {
    SET: 'SEARCH/RATE/SET',
  },
  BOOKING: {
    SET: 'SEARCH/BOOKING/SET',
  },
  USER: {
    SET: 'SEARCH/USER/SET',
  },
  MAP_OPEN: {
    SET: 'SEARCH/MAP_OPEN/SET',
  },
  POP_UP_OPEN: {
    SET: 'SEARCH/POP_UP_OPEN/SET',
  },
  SERVER_ERRORS: {
    SET: 'SEARCH/SERVER_ERRORS/SET',
  },
  HOVERED_HOTEL: {
    SET: 'SEARCH/HOVERED_HOTEL/SET'
  },
  DISPLAY_SEARCH_MESSAGE: {
    SET: 'SEARCH/DISPLAY_SEARCH_MESSAGE'
  },
  META: 'SEARCH/META',
  ROOMS_DATA: 'SEARCH/ROOMS_DATA',
  FILTERS: {
    SET: 'SEARCH/FILTERS/SET',
    OPEN: 'SEARCH/FILTERS/OPEN',
    LAST: 'SEARCH/FILTERS/LAST',
    SEARCH: 'SEARCH/FILTERS/SEARCH',
    DEFAULT: 'SEARCH/FILTERS/DEFAULT'
  },
  FILTERS_FOR_SEARCH: {
    SET: 'SEARCH/FILTERS_FOR_SEARCH/SET'
  },
  INITIATE_PAGE_CHANGE: 'SEARCH/INITIATE_PAGE_CHANGE',
  SORT: {
    SET: 'SEARCH/SORT/SET'
  },
  SEARCHED_ARRIVAL: {
    SET: 'SEARCH/SEARCHED_ARRIVAL/SET',
  },
  SEARCHED_NIGHTS: {
    SET: 'SEARCH/SEARCHED_NIGHTS/SET',
  },
  SEARCHED_ADULTS: {
    SET: 'SEARCH/SEARCHED_ADULTS/SET',
  },
  SEARCHED_CHILDREN: {
    SET: 'SEARCH/SEARCHED_CHILDREN/SET',
  },
  SEARCHED_CHILDREN_AGES: {
    SET: 'SEARCH/SEARCHED_CHILDREN_AGES/SET',
  },
  SEARCHED_ROOMS: {
    SET: 'SEARCH/SEARCHED_ROOMS/SET',
  },
  CACHE_SEARCH_TERMS: {
    SET: 'SEARCH/CACHE_SEARCH_TERMS/SET',
  },
  RATES_FOR_HOTEL: {
    SET: 'SEARCH/RATES_FOR_HOTEL/SET',
  }
}

let reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return {...initialSearchState}
    case SEARCH.ALL:
      return { ...state, ...action.payload}
    case SEARCH.ARRIVAL.SET:
      return { ...state, arrival: action.payload }
    case SEARCH.NIGHTS.SET:
      return { ...state, nights: action.payload }
    case SEARCH.LOCATION.SET:
      return { ...state, location: action.payload }
    case SEARCH.SEARCH_BY.SET:
      return { ...state, searchBy: action.payload }
    case SEARCH.ROOMS.SET:
      return { ...state, rooms: action.payload }
    case SEARCH.CHILDREN.SET:
      return { ...state, children: action.payload }
    case SEARCH.CHILDREN_AGES.SET:
      return { ...state, childrenAges: action.payload }
    case SEARCH.LEAD_ID.SET:
      return { ...state, leadId: action.payload }
    case SEARCH.ADULTS.SET:
      return { ...state, adults: action.payload }
    case SEARCH.CACHE_SEARCH_TERMS.SET:
      return {
        ...state,
        searchedArrival: state.arrival.clone(),
        searchedNights: state.nights,
        searchedAdults: state.adults,
        searchedChildren: state.children,
        searchedChildrenAges: Object.assign({}, state.childrenAges),
        searchedRooms: state.rooms
      }
    case SEARCH.HOTELS.LOADING:
      return { ...state, isLoading: action.payload }
    case SEARCH.HOTELS.SET:
      return { ...state, hotels: action.payload }
    case SEARCH.AVAILABILITY_NOTICES.SET:
      return { ...state, availabilityNotices: action.payload }
    case SEARCH.PAGES:
      return { ...state, pages: action.payload }
    case SEARCH.CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SEARCH.NUM_PER_PAGE:
      return { ...state, numPerPage: action.payload }
    case SEARCH.CURRENT_HOTELS.SET:
      return { ...state, currentHotels: action.payload }
    case SEARCH.VISIBLE_HOTELS.SET:
      return { ...state, visibleHotels: action.payload }
    case SEARCH.HOTEL.LOADING:
      return { ...state, isLoading: action.payload }
    case SEARCH.HOTEL.SET:
      return { ...state, hotel: action.payload }
    case SEARCH.HOTEL.UPDATE:
      const newState = { ...state }
      const hotel = state.hotels.find(
        (hotel) => hotel.id == action.payload.hotel.id
      )
      if (hotel) {
        hotel[action.payload.updateField] =
          action.payload.hotel[action.payload.updateField]
      }
      return newState
    case SEARCH.QUALIFYING_QUESTIONS.SET:
      return { ...state, qualifyingQuestions: action.payload }
    case SEARCH.SELECTED_QUALIFYING_QUESTIONS.SET:
      return { ...state, selectedQualifyingQuestion: action.payload }
    case SEARCH.LAYOUT.SET:
      return { ...state, layout: action.payload }
    case SEARCH.ROOM.SET:
      return { ...state, room: action.payload }
    case SEARCH.RATE.SET:
      return { ...state, rate: action.payload }
    case SEARCH.BOOKING.SET:
      return { ...state, booking: action.payload }
    case SEARCH.USER.SET:
      return { ...state, user: action.payload }
    case SEARCH.MAP_OPEN.SET:
      return { ...state, mapOpen: action.payload }
    case SEARCH.POP_UP_OPEN.SET:
      return { ...state, popUpOpen: action.payload }
    case SEARCH.SERVER_ERRORS.SET:
      return { ...state, serverErrors: action.payload }
    case SEARCH.HOVERED_HOTEL.SET:
      return { ...state, hoveredHotel: action.payload}
    case SEARCH.DISPLAY_SEARCH_MESSAGE.SET:
      return { ...state, displaySearchMessage: action.payload }
    case SEARCH.META:
      return { ...state, meta: action.payload }
    case SEARCH.ZOHO_LEAD.SET:
      return { ...state, zohoLead: action.payload }
    case SEARCH.ROOMS_DATA:
      return { ...state, roomsData: action.payload }
    case SEARCH.FILTERS.SET:
      return { ...state, filters: action.payload }
    case SEARCH.FILTERS.OPEN:
      return { ...state, filtersOpen: action.payload }
    case SEARCH.FILTERS.LAST:
      return { ...state, lastFilterSearch: action.payload }
    case SEARCH.FILTERS.SEARCH:
      return { ...state, initiateFilterSearch: action.payload }
    case SEARCH.INITIATE_PAGE_CHANGE:
      return { ...state, initiatePageChange: action.payload }
    case SEARCH.FILTERS.DEFAULT:
      return { ...state, defaultFilterValues: action.payload }
    case SEARCH.FILTERS_FOR_SEARCH.SET:
      return { ...state, filtersForSearch: action.payload}
    case SEARCH.SORT.SET:
      return { ...state, sort: action.payload }
    case SEARCH.RATES_FOR_HOTEL.SET:
      return {
        ...state,
        hotel: {
          ...state.hotel,
          rates_fetched: true,
          room_rates: action.payload
        },
        hotels: state.hotels.map((hotel) => {
          if (hotel.id === state.hotel.id) {
            return { ...hotel, rates_fetched: true,  room_rates: action.payload }
          }
          else {
            return hotel
          }
        }),
        visibleHotels: state.visibleHotels.map((hotel) => {
          if (hotel.id === state.hotel.id) {
            return { ...hotel, rates_fetched: true,  room_rates: action.payload }
          }
          else {
            return hotel
          }
        })
      }
    default:
      return state
  }
}

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  let [state, dispatch] = useReducer(reducer, initialSearchState)
  let value = { state, dispatch }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}

SearchProvider.propTypes = {
  children: PropTypes.node,
}

export const SearchConsumer = SearchContext.Consumer
