import React, { useState } from 'react'
import Cards from 'react-credit-cards'
import { InputText } from '../../ui'
import {
  validateName,
  validateCardNumber,
  validatePostCode,
  validateAmexPostCode,
  validateCardExpiration,
  normalizeName,
  normalizeCardExpiration,
  normalizeCardCode,
  normalizeAmexCardCode,
  normalizeCardNumber,
  outputFieldErrorClass,
  cardType,
} from '../../helpers/formHelper'


const PaymentFields = ({
  handler,
  errors,
  values
}) => {

  const [focused, setFocused] = useState("")

  const _displayPaymentFields = () => {
    let cvcProps = {}
    if (cardType(values.cardNumber) == 'amex') {
      cvcProps = { minLength : 4, validate: validateAmexPostCode, placeholder: '1234', normalize: normalizeAmexCardCode }
    } else {
      cvcProps = { minLength : 3, validate: validatePostCode, placeholder: '123', normalize: normalizeCardCode }
    }
    return (
      <React.Fragment>
        <div className="fieldset fields payment-fields">
          <InputText
            className = {`card-name ${outputFieldErrorClass(errors.cardName)}`}
            type      = "text"
            name      = "cardName"
            label     = "Name on card"
            value     = {values.cardName}
            handler   = {handler}
            required  = {true}
            normalize = {normalizeName}
            validate  = {validateName}
            onFocus   = {() => setFocused("cardName")}
            errorText = {errors.cardName}
            maxLength = {40}
            autoComplete = "cc-name"
          />
          <InputText
            className = {`card-number ${outputFieldErrorClass(errors.cardNumber)}`}
            type      = "text"
            inputmode = "numeric"
            pattern   = "[0-9]*"
            name      = "cardNumber"
            label     = "Card Number"
            value     = {values.cardNumber}
            handler   = {handler}
            required  = {true}
            normalize = {normalizeCardNumber}
            validate  = {validateCardNumber}
            onFocus   = {() => setFocused("cardNumber")}
            errorText = {errors.cardNumber}
            autoComplete = "cc-number"
          />
          <div className="field-group small-fields">
            <InputText
              className = {`expiration-date ${outputFieldErrorClass(errors.expiryDate)}`}
              type      = "text"
              inputmode = "numeric"
              pattern   = "[0-9]*"
              name      = "expiryDate"
              label     = "Expiration Date"
              value     = {values.expiryDate}
              handler   = {handler}
              required  = {true}
              normalize = {normalizeCardExpiration}
              validate  = {validateCardExpiration}
              placeholder = "MM/YY"
              onFocus   = {() => setFocused("expiryDate")}
              errorText = {errors.expiryDate}
              autoComplete = "cc-exp"
            />
            <InputText
              className = {`cvc ${outputFieldErrorClass(errors.cvc)}`}
              type      = "text"
              inputmode = "numeric"
              pattern   = "[0-9]*"
              name      = "cvc"
              label     = "Security Code"
              value     = {values.cvc}
              handler   = {handler}
              required  = {true}
              normalize = {normalizeCardCode}
              onFocus   = {() => setFocused("cvc")}
              errorText = {errors.cvc}
              autoComplete = "cc-csc"
              {...cvcProps}
            />
          </div>
          <Cards
            cvc     = {values.cvc}
            expiry  = {values.expiryDate}
            focused = {focused}
            name    = {values.cardName}
            number  = {values.cardNumber}
          />
        </div>
      </React.Fragment>
    )
  }

  return _displayPaymentFields()
}

export default PaymentFields
