import { useEffect, useState } from "react"
import history from 'history/browser'

export default (state, onBack) => {

  // const [currentState, setCurrentState] = useState(state)
  const [unlistenNow, setUnlistenNow] = useState(false)
  const [startBlocking, setStartBlocking] = useState(false)

  // history.replace('/logged-in')

  const handlePopState = () => {

    // Fire the optional callback for `onBack`
    if (onBack) {
      onBack(history.location.state)
    }
  }

  useEffect(
    () => {

      let unlisten = history.listen(({ location, action }) => { //eslint-disable-line
        // console.log("listening for actions ", action)
        // console.log(action, location.pathname, location.state)

        if (action === "POP" && !unlistenNow && !startBlocking) {
          // console.log("POP: ", location.state.stateTitle)
          handlePopState()
        }

        if (unlistenNow) {
          unlisten()
        }

        if (startBlocking) {
          let unblock = history.block(() => { //eslint-disable-line
            // A transition was blocked!
            // console.log("start blocking", action, location, retry)
          })
        }
      })

      return () => {
        // console.log("unlistening")
        unlisten()
      }
    },
    []
  )

  // useEffect(
  //   () => {
  //     setCurrentState(state)
  //   }, [state]
  // )

  return [setUnlistenNow, setStartBlocking]
}