
// A promise to return full height minus passed node height
// subElClass: (string) class or id of element
export const getContentHeightSub = (subElClass) => new Promise((resolve) => {
  if(document.querySelector(subElClass)) {
    resolve(subHeightCalculations(subElClass))
  } else {
    setTimeout( () => resolve(subHeightCalculations(subElClass)), 1000)
  }
})

const subHeightCalculations = (subElClass) => {
  let sub = document.querySelector(subElClass)

  if(sub) {
    let subHeight = sub.clientHeight
    let height = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    )

    let contentHeight = height - subHeight
    return `${contentHeight}px`
  }
}