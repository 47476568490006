import { useEffect, useState } from 'react'
import useResizeObserver from './useResizeObserver'

/*
  Watches window resize and element resize
  Sets height of observeMe dom element to window height
  Params:
  observeMe(string): className of element to be observed
  Returns:
  setInitate(func): when to initiate fullscreen/resize watching
*/
export default (observeMe) => {
  const [initate, setInitate] = useState()
  const observerEl = observeMe
  var resizeId, resizeLongerCheckId

  const _resizerActions = (entries) => {
    if(entries) {
      entries.forEach(() => {
        clearTimeout(resizeId)
        resizeId = setTimeout(_setElHeight, 500)
        clearTimeout(resizeLongerCheckId)
        resizeLongerCheckId = setTimeout(_setElHeight, 3000)
      })
    }
  }
  const [resizer] = useResizeObserver(_resizerActions)

  useEffect(() => {
    window.addEventListener("resize", _windowResizeActions)
    return () => {
      window.removeEventListener("resize", _windowResizeActions)
    }
  },[])

  // observe an element
  useEffect(() => {
    if(initate) {
      let el = document.querySelector(observerEl)
      if(el) {
        _setElHeight(el)
        resizer.observe(el)
      }
    }
  }, [initate])

  const _windowResizeActions = () => _setElHeight()

  const _setElHeight = () => {
    let el = document.querySelector(observerEl)

    if(el) {
      let height = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      )
      el.style.height = `${height}px`
    }
  }

  return [setInitate]
}