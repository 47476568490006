import React, { createContext, useReducer } from 'react'
import PropTypes from 'prop-types'

const initialState = {
  items: [],
  active: false,
  total: 0
}

export const CART = {
  ITEMS: 'CART/ALL',
  ACTIVE: 'CART/ACTIVE',
  TOTAL: 'CART/TOTAL'
}

let reducer = ( state, action ) => {
  switch(action.type) {
    case "reset":
      return {...initialState}
    case CART.ITEMS:
      return { ...state, items: action.payload }
    case CART.ACTIVE:
      return { ...state, active: action.payload }
    case CART.TOTAL:
      return { ...state, total: action.payload }
    default:
      return state
  }
}

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  let [cartState, cartUpdate] = useReducer(reducer, initialState)
  let value = { cartState, cartUpdate }

  return (
    <CartContext.Provider value={ value }>
      { children }
    </CartContext.Provider>
  )
}

CartProvider.propTypes = {
  children: PropTypes.node
}

export const CartConsumer = CartContext.Consumer
