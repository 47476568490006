import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { isIOS } from 'react-device-detect'
import server from '../api/server'
import moment from 'moment'
import { getContentHeightSub } from './contentRenderHelper'

export const fetchPackages = (payload) => {
  return server.get('/packages.json', { params: payload })
}

// If within 40% of top
export const isInViewport = (elem) => {
  var bounding = elem.getBoundingClientRect()
  // Only if 40% from top of viewport
  let twenty = Math.ceil( (window.innerHeight || document.documentElement.clientHeight) * 0.4)

  return (
    (
      bounding.bottom >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    ) || (
      bounding.top >= 0 &&
      bounding.top <= twenty
    )
  )
}


export const setFullScreenElHeight = (el) => {
  if(el)
    el.style.height = `${Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}px`
}

export const setFullScreenClasses = () => {
  let hotelsEl = document.querySelector(".hotels")
  let body = document.querySelector("body")
  let wrapperEl = document.querySelector(".ecommerce-wrapper")
  wrapperEl.classList.add("fullscreen")
  hotelsEl.classList.add("fullscreen")
  body.classList.add("fullscreen")
  setFullScreenHeights()
}

export const removeFullScreenClasses = () => {
  let hotelsEl = document.querySelector(".hotels")
  let body = document.querySelector("body")
  let wrapperEl = document.querySelector(".ecommerce-wrapper")
  wrapperEl.classList.remove("fullscreen")
  hotelsEl.classList.remove("fullscreen")
  body.classList.remove("fullscreen")
  removeFullScreenHeights()
}

export const setFullScreenHeights = () => {
  let hotelsEl = document.querySelector(".hotels")
  if(hotelsEl) {
    setHotelsHeightActions(hotelsEl)
  } else {
    setTimeout(() => {
      let hotelsEl = document.querySelector(".hotels")
      if(hotelsEl) {
        setHotelsHeightActions(hotelsEl)
      }
    }, 300)
  }
}

const setHotelsHeightActions = (el) => {
  el.style.height = `${Math.max(document.documentElement.clientHeight, window.innerHeight || 0)}px`

  getContentHeightSub('.search-bar').
    then( (height) => {
      let search = document.querySelector(".viewport.search")
      if(search) {
        search.style.height = height
        // To let hotels load
        setTimeout(() => {
          let content = search.querySelector(".content")
          content.style.height = height
        }, 2000)
      } else {
        setTimeout(() => {
          let search = document.querySelector(".viewport.search")
          if(search) {
            search.style.height = height
            let content = search.querySelector(".content")
            content.style.height = height
          }
        }, 1000)
      }
    })

}

export const removeFullScreenHeights = () => {
  let hotelsEl = document.querySelector(".hotels")
  let search = document.querySelector(".viewport.search")
  hotelsEl.style.height = null
  search.style.height = null
}

export const calcNights = (start, end) => {
  let date1_ms = start.getTime()
  let date2_ms = end.getTime()
  let difference_ms = date2_ms - date1_ms
  let nights = Math.round(difference_ms / (1000 * 60 * 60 * 24))
  return nights
}

// Expects a Moment object for each date
export const calcNightsMoment = (start, end) => end.diff(start, 'days')

// Calc min departure date by min package nights
export const setMinDepartureDate = (date, nights) => {
  let minDeparture = new Date(date)
  minDeparture.setDate(minDeparture.getDate() + parseInt(nights))
  return minDeparture
}

// Calc min departure date by min package nights
// Expects Momemt object
export const setMinDepartureDateMoment = (date, nights) => moment(date).add(nights, "days")

// Set Hotels Fullscreen
export const setupHotelFullscreen = () => {
  let hotelEl = document.querySelector(".hotels")
  // let searchEl = document.querySelector(".viewport.search")
  if(hotelEl) {
    // let body = document.querySelector("body")
    // body.classList.add("fullscreen")
    // clearAllBodyScrollLocks()
    setFullScreenClasses()
    if(!isIOS) {
      disableBodyScroll(hotelEl)
    }
    // setFullScreenElHeight(hotelEl)
    // let subHeight = getContentHeightSub(".search-bar")
    // let search = document.querySelector(".viewport.search")
    // search.style.height = subHeight
    // disableBodyScroll(hotelEl, { allowTouchMove: el => el.classList.contains("scrollable") })
    // disableBodyScroll(searchEl)
  }
}

export const disableScroll = (element) => {
  if(!isIOS) disableBodyScroll(document.querySelector(element))
  let body = document.querySelector("body")
  body.classList.add("fullscreen")
}

export const enableScroll = (element) => {
  if(!isIOS) clearAllBodyScrollLocks()
  if(!isIOS) enableBodyScroll(document.querySelector(element))
  let body = document.querySelector("body")
  body.classList.remove("fullscreen")
}
