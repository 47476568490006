import React from 'react'
import PropTypes from 'prop-types'
import { InputText } from '../../ui'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import {
  validateName,
  validatePhone,
  validateEmail,
  normalizePhone,
  normalizeName
} from '../../helpers/formHelper'

import AddressAutocomplete from '../AddressAutocomplete'

const ShippingAddressFields = ({ handler, phoneHandler, errors, values }) => {
  return (
    <div className="fieldset shipping-fields shipping-tickets fields">
      <InputText
        className={`first-name ${errors.firstName && 'error'}`}
        type="text"
        name="firstName"
        label="First Name"
        value={values.firstName}
        handler={handler}
        required
        normalize={normalizeName}
        validate={validateName}
        maxLength = {40}
      />

      <InputText
        className={`last-name ${errors.lastName && 'error'}`}
        type="text"
        name="lastName"
        label="Last Name"
        value={values.lastName}
        handler={handler}
        required
        normalize={normalizeName}
        validate={validateName}
        maxLength = {40}
      />

      <InputText
        className={`email ${errors.email && 'error'}`}
        type="email"
        name="email"
        label="Email"
        value={values.email}
        handler={handler}
        required
        validate={validateEmail}
        />

      <div className={`field-group ${values.phone ? (isValidPhoneNumber(values.phone) ? '' : 'error') : ''}`}>
        <label className="phone" htmlFor="phone">Phone</label>
        <PhoneInput
          international
          defaultCountry="US"
          countryCallingCodeEditable={false}
          placeholder="Enter phone number"
          name="phone"
          value={values.phone}
          onChange={(val) => phoneHandler({ value: val ? val : '', name: "phone", error: (val && isValidPhoneNumber(val)) ? undefined : 'Invalid phone number' }) } />
      </div>
    </div>
  )
}

ShippingAddressFields.propTypes = {
  handler: PropTypes.func.isRequired,
  phoneHandler: PropTypes.func.isRequired,
  handlerAddress: PropTypes.func.isRequired,
  handleAddressUpdate: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  values: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired
  }),
}

export default ShippingAddressFields
